/* eslint-disable max-len */
import React from 'react';
import {
    EditableText, Tag, Spinner, ProgressBar, Text, Tooltip
} from '@blueprintjs/core';
/* eslint-disable import/no-unresolved */
import ReactResizeDetector from 'react-resize-detector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { map } from 'rxjs/operators';
import { ToasterBottom } from '../../../lib/toaster';
import { findSource } from '../../../api/sources';
import { getFiwareEntities } from '../../../api/general';

const objectPath = require('object-path');
const mqtt = require('mqtt');

function constructMQTTPayload(recvTopic, message) {
    const topic = recvTopic.replaceAll('/', '.');
    const payload = {
        topic,
        message,
    };

    return payload;
}

function setZeroIfNull(data) {
    if (!data) {
        return 0;
    }

    return data.val;
}

class RobotKPIS extends React.Component {
    constructor(props) {
        super(props);

        this.type = props.type;

        this.state = {
            spinnerOpen: true,
            id: props.id,
            user: props.user,
            owner: props.owner,
            name: props.initialState.name || 'Value',
            counter: 0,
            source: props.initialState.source || 'Select source',

            varAPIKey: props.initialState.varAPIKey || '',
            varEntityType: props.initialState.varEntityType || '',

            statistics: {
                palleteStorageDensity: { val: 0, name: 'Pallete Storage Density', units: 'pieces', updated: false },
                distanceXmassMovedByRobots: { val: 0, name: 'Distance x Mass Moved by Robots', units: 'm*kg', updated: false },
                distanceXmassMovedByOperators: { val: 0, name: 'Distance x Mass Moved by Operators', units: 'm*kg', updated: false },
                palletsMoved: { val: 0, name: 'Total Moved Pallets', units: 'pieces', updated: false },
                parcelsMoved: { val: 0, name: 'Total Moved Parcels', units: 'pieces', updated: false },
                savedTimeshareForOperator: { val: 0, name: 'Saved Time for Operators', units: 'hours', updated: false }
            },

            fontSize: 10,
            width: 50,
            height: 50
        };
        this.rxStomp = null;
        this.mqttClient = null;

        this.setPeriodicTimer = this.setPeriodicTimer.bind(this);
        this.changeSpinner = this.changeSpinner.bind(this);
        this.messageReceived = this.messageReceived.bind(this);
        this.connectStompSource = this.connectStompSource.bind(this);
        this.connectMqttSource = this.connectMqttSource.bind(this);
        this.connectToTopic = this.connectToTopic.bind(this);
        this.resize = this.resize.bind(this);
        this.renderStatistics = this.renderStatistics.bind(this);
        this.initialComponentsState = this.initialComponentsState.bind(this);
    }

    async componentDidMount() {
        const { source, owner, user, varEntityType } = this.state;
        this.connectToTopic();

        let response = await findSource(source, owner, user);

        // custom rest call
        if (response.success) {
            const url = response.source.orionUrl;
            const formattedUrl = (url.startsWith('http://') || url.startsWith('https://')) ? url : `http://${url}`;

            const params = JSON.stringify({
                type: varEntityType,
                options: 'keyValues'
            });

            const headers = JSON.stringify({
                'Fiware-service': 'openiot',
                'Fiware-servicepath': '/'
            });

            const query = JSON.stringify({
                'date.val': (new Date()).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                })
            });

            try {
                response = await getFiwareEntities(formattedUrl, headers, params, query);
                this.initialComponentsState(response.data);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log('Error: ', error);
            }
        }
    }

    componentWillUnmount() {
        if (this.rxStomp !== null) {
            this.rxStomp.deactivate();
        }
        if (this.mqttClient !== null) {
            this.mqttClient.end();
        }
    }

    setPeriodicTimer(key) {
        this.interval = setInterval(() => {
            const { statistics } = this.state;

            statistics[key].updated = false;
            this.setState({ statistics });
        }, 3000);
    }

    changeSpinner(value) {
        this.setState({ spinnerOpen: value });
    }

    initialComponentsState(response) {
        const { statistics } = this.state;

        if (response[0]) {
            statistics.palleteStorageDensity.val = setZeroIfNull(response[0].palleteStorageDensity);
            statistics.distanceXmassMovedByRobots.val = setZeroIfNull(response[0].distanceXmassMovedByRobots);
            statistics.distanceXmassMovedByOperators.val = setZeroIfNull(response[0].distanceXmassMovedByOperators);
            statistics.palletsMoved.val = setZeroIfNull(response[0].palletsMoved);
            statistics.parcelsMoved.val = setZeroIfNull(response[0].parcelsMoved);
            statistics.savedTimeshareForOperator.val = setZeroIfNull(response[0].savedTimeshareForOperator);

            this.setState(statistics);
        }
    }

    messageReceived(payload) {
        const { id, statistics } = this.state;
        const { topic, message } = payload;

        try {
            const { counter } = this.state;
            const newCounter = counter + 1;
            let ts = (new Date() - this.prevTime) / 1000.0;
            if (this.prevTime < 0) {
                ts = '-';
                this.minInterval = 1000000000;
                this.maxInterval = 0;
                this.meanInterval = 0;
            } else {
                if (ts < this.minInterval) {
                    this.minInterval = ts;
                }
                if (ts > this.maxInterval) {
                    this.maxInterval = ts;
                }
                this.meanInterval += ts;
            }
            this.prevTime = new Date();

            const origin = topic.split(/[.]+/)[4];
            const value = objectPath.get(message, 'val');

            if (Object.keys(statistics).includes(origin)) {
                statistics[origin].val = value;
                statistics[origin].updated = true;
                this.setPeriodicTimer(origin);
            }

            this.setState({
                counter: newCounter,
                statistics,
                timeSpan: `Last interval: ${ts} sec`,
                minint: `Minimum interval: ${this.minInterval} sec`,
                meanint: `Mean interval: ${(this.meanInterval / (newCounter - 1)).toFixed(3)} sec`,
                maxint: `Maximum interval: ${this.maxInterval} sec`,
                timeSpanVal: ts,
                minintVal: this.minInterval,
                meanintVal: (this.meanInterval / (newCounter - 1)).toFixed(3),
                maxintVal: this.maxInterval
            }, () => {
                const height = document.getElementById(`valueDiv_${id}`).offsetHeight;
                const width = document.getElementById(`valueDiv_${id}`).offsetWidth;
                this.resize(width, height);
            });
        } catch { }
    }

    connectStompSource(source) {
        const { name } = this.state;

        const topic = '#';

        try {
            const stompConfig = {
                connectHeaders: {
                    login: source.login,
                    passcode: source.passcode,
                    host: source.vhost
                },
                brokerURL: source.url
            };

            // eslint-disable-next-line no-undef
            this.rxStomp = new RxStomp.RxStomp();
            this.rxStomp.configure(stompConfig);
            this.rxStomp.activate();
            const initialReceiptId = `${name}_start`;

            this.prevTime = -1;
            this.minInterval = -1;
            this.maxInterval = -1;
            this.meanInterval = 0;

            this.rxStomp.watch(`/topic/${topic}`, { receipt: initialReceiptId }).pipe(map((message) => JSON.parse(message.body))).subscribe((payload) => {
                this.messageReceived(payload);
            });
            this.rxStomp.watchForReceipt(initialReceiptId, () => {
                this.changeSpinner(false);
            });
        } catch { }
    }

    connectMqttSource(source) {
        const { varAPIKey } = this.state;
        const topic = `/${varAPIKey}/+/attrs/+`;

        try {
            const config = {
                username: source.login,
                password: source.passcode
            };

            this.mqttClient = mqtt.connect(source.url, config);
            this.mqttClient.on('connect', () => {
                this.mqttClient.subscribe(`${topic}`, (err) => {
                    if (!err) {
                        this.changeSpinner(false);
                    }
                });
            });

            this.prevTime = -1;
            this.minInterval = -1;
            this.maxInterval = -1;
            this.meanInterval = 0;

            this.mqttClient.on('message', (recvTopic, message) => {
                this.messageReceived(constructMQTTPayload(recvTopic, JSON.parse(message.toString())));
            });
        } catch { }
    }

    async connectToTopic() {
        const { user, owner, name, source } = this.state;
        const response = await findSource(source, owner, user);
        if (response.success) {
            if (response.source.type === 'stomp') {
                this.connectStompSource(response.source);
            } else {
                this.connectMqttSource(response.source);
            }
        } else {
            ToasterBottom.show({
                intent: 'danger',
                message: response.message || `There was a problem trying to find the source for ${name}`
            });
        }
    }

    resize(width, height) {
        this.setState({
            fontSize: Math.min(height, (width / (10))),
            width,
            height
        });
    }

    renderStatistics() {
        const { statistics } = this.state;

        const renderedStatistics = Object.keys(statistics).map((s) => (
            <li key={s}>
                <div
                    style={{
                        width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '3px', margin: '3px', backgroundColor: 'lightgrey', borderRadius: '5px', border: '1px solid #16335B',
                    }}
                >
                    <div
                        style={{
                            width: '70%',
                            margin: '3px',
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                        }}
                    >
                        {statistics[s].name}
                    </div>

                    <div
                        style={{
                            backgroundColor: (statistics[s].updated ? 'green' : 'white'),
                            borderRadius: '5px',
                            width: '30%',
                            padding: '2px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            animation: (statistics[s].updated ? 'blink 3s linear infinite' : '')
                        }}
                    >
                        <b>{`${statistics[s].val} ${statistics[s].units}`}</b>
                    </div>

                </div>
            </li>
        ));

        return renderedStatistics;
    }

    render() {
        const { spinnerOpen, id, name, counter, fontSize, width, height, timeSpan, minint, maxint, meanint, timeSpanVal, minintVal, meanintVal, maxintVal } = this.state;

        return (
            <div
                style={{
                    width: '100%', height: '100%', background: 'white', padding: '1%', display: 'flex', flexDirection: 'column', borderRadius: '10px', fontSize: '16px'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '25px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        background: '#16335B',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        position: 'relative',
                        fontSize: '13px'
                    }}
                >
                    <EditableText disabled className="name-no-edit" placeholder="Component Name" value={name} />
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            right: '2%',
                            transform: 'translateY(-50%)',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Tooltip
                            popoverClassName="item-info-tooltip"
                            content={(
                                <div>
                                    <div>
                                        <div>
                                            <Text>{timeSpan}</Text>
                                            <ProgressBar
                                                intent="primary"
                                                animate={false}
                                                stripes={false}
                                                value={timeSpanVal / maxintVal}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <Text>{minint}</Text>
                                            <ProgressBar
                                                intent="success"
                                                animate={false}
                                                stripes={false}
                                                value={minintVal / maxintVal}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <Text>{meanint}</Text>
                                            <ProgressBar
                                                intent="warning"
                                                animate={false}
                                                stripes={false}
                                                value={meanintVal / maxintVal}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <Text>{maxint}</Text>
                                            <ProgressBar
                                                intent="danger"
                                                animate={false}
                                                stripes={false}
                                                value={maxintVal / maxintVal}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            interactionKind="hover"
                        >
                            <Tag
                                round
                                intent="primary"
                                style={{
                                    background: '#16335B',
                                    color: '#aaaaaa',
                                    fontSize: '13px'
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faChartBar}
                                    style={{
                                        color: '#aaaaaa',
                                        paddingRight: '4px',
                                        fontSize: '13px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={this.filterMessages}
                                />
                                {counter}
                            </Tag>
                        </Tooltip>
                    </div>
                </div>
                <ReactResizeDetector onResize={this.resize}>
                    {() => (
                        <div
                            id={`valueDiv_${id}`}
                            style={{
                                width: '100%',
                                height: 'calc(100% - 35px)',
                                maxHeight: '100%',
                                marginTop: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#16335B',
                                fontSize: `${fontSize}px`,
                                position: 'relative'
                            }}
                        >
                            {spinnerOpen
                                && (
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            position: 'absolute',
                                            top: '0px',
                                            left: '0px',
                                            zIndex: 1000,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            background: 'rgba(255, 255, 255, 0.6)'
                                        }}
                                    >
                                        <Spinner intent="primary" size={Math.min(width / 10, height / 2)} />
                                    </div>
                                )}
                            <div
                                style={{
                                    width: '100%', height: '100%', fontSize: '16px', overflowY: 'auto', display: 'flex', justifyContent: 'center'
                                }}
                            >
                                <ul style={{ listStyleType: 'none', width: '100%', marginRight: '45px' }}>
                                    {this.renderStatistics()}
                                </ul>
                            </div>
                        </div>
                    )}
                </ReactResizeDetector>
            </div>
        );
    }
}

const createRobotKPIS = ({ id, type, initialState, user, owner }) => (
    <RobotKPIS
        id={id}
        type={type}
        initialState={initialState}
        user={user}
        owner={owner}
    />
);

export default createRobotKPIS;
