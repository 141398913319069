/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import {
    EditableText, Tag, ProgressBar, Text, Tooltip, RadioGroup, Radio, Popover, PopoverInteractionKind, Position, Menu, MenuItem
} from '@blueprintjs/core';
/* eslint-disable import/no-unresolved */
import ReactResizeDetector from 'react-resize-detector';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChartBar} from '@fortawesome/free-solid-svg-icons';
import Hotkeys from 'react-hot-keys';
import {ToasterBottom} from '../../../lib/toaster';
import {findSource} from '../../../api/sources';
import {CustomButton} from '../../../lib/buttons';
import {getFiwareEntities} from '../../../api/general';

const mqtt = require('mqtt');

const GridDiv = styled.div`
    width: 100px;
    heigth: 100px;
    display: grid;
    grid-template-columns: auto auto auto;
`;

const GridItemDiv = styled.div`
    background-color: rgba(255, 255, 255, 0.8);
    margin: 2px 1px 2px 1px;
    font-size: 10px;
    text-align: center;
`;

class RobotTeleop extends React.Component {
    constructor(props) {
        super(props);

        this.type = props.type;

        this.state = {
            id: props.id,
            user: props.user,
            owner: props.owner,
            name: props.initialState.name || 'Value',
            counter: 0,
            source: props.initialState.source || 'Select source',
            selectedRobot: '',
            pressedButton: '',
            varAPIKey: props.initialState.varAPIKey || '',
            varEntityType: props.initialState.varEntityType || '',
            availableRobots: [],
            fontSize: 50
        };
        this.rxStomp = null;
        this.mqttClient = null;

        this.connectStompSource = this.connectStompSource.bind(this);
        this.connectMqttSource = this.connectMqttSource.bind(this);
        this.disconnectFromTopics = this.disconnectFromTopics.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.connectToTopic = this.connectToTopic.bind(this);
        this.renderJoystick = this.renderJoystick.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.initialComponentsState = this.initialComponentsState.bind(this);
    }

    async componentDidMount() {
        const {source, owner, user, varEntityType} = this.state;
        this.connectToTopic();

        let response = await findSource(source, owner, user);

        // custom rest call
        if (response.success) {
            const url = response.source.orionUrl;
            const formattedUrl = (url.startsWith('http://') || url.startsWith('https://')) ? url : `http://${url}`;

            const params = JSON.stringify({
                type: varEntityType,
                options: 'keyValues'
            });

            const headers = JSON.stringify({
                'Fiware-service': 'openiot', 
                'Fiware-servicepath': '/' 
            });

            const query = JSON.stringify({});
            
            try {
                response = await getFiwareEntities(formattedUrl, headers, params, query);
                if (response.status >= 200 && response.status < 300) {
                    this.initialComponentsState(response.data);
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log('Error: ', error);
            }
        } 
    }

    componentWillUnmount() {
        this.disconnectFromTopics();
    }

    handleRadioChange(event) {
        this.setState({selectedRobot: event.target.defaultValue});
    }

    onKeyUp() {
        this.setState({pressedButton: ''});
    }

    onKeyDown(keyName) {
        this.setState({pressedButton: keyName});

        this.sendMessage(keyName);
    }

    initialComponentsState(response) {
        const {availableRobots, varEntityType} = this.state;

        response.forEach((r) => {
            const robotId = r.id.split(/[:]+/)[3];
            const robot = `${varEntityType}${robotId}`;

            if (Object.keys(availableRobots).length === 0) {
                this.setState({selectedRobot: robot});
            }
            
            availableRobots.push(robot);
        });

        this.setState(availableRobots);
    }

    connectStompSource(source) {
        try {
            const stompConfig = {
                connectHeaders: {
                    login: source.login,
                    passcode: source.passcode,
                    host: source.vhost
                },
                // debug: (str) => {
                //     console.log(`STOMP: ${str}`);
                // },
                brokerURL: source.url
            };
            // eslint-disable-next-line no-undef
            this.rxStomp = new RxStomp.RxStomp();
            this.rxStomp.configure(stompConfig);
            this.rxStomp.activate();
        } catch {}
    }

    connectMqttSource(source) {
        try {
            const config = {
                username: source.login,
                password: source.passcode
            };

            this.mqttClient = mqtt.connect(source.url, config);            
        } catch {}
    }

    async connectToTopic() {
        const {user, owner, name, source} = this.state;
        const response = await findSource(source, owner, user);

        if (response.success) {
            if (response.source.type === 'stomp') {
                this.connectStompSource(response.source);
            } else {
                this.connectMqttSource(response.source);
            }
        } else {
            ToasterBottom.show({
                intent: 'danger',
                message: response.message || `There was a problem trying to find the source for ${name}`
            });
        }
    }

    disconnectFromTopics() {
        if (this.rxStomp !== null) {
            this.rxStomp.deactivate();
        }
        if (this.mqttClient !== null) {
            this.mqttClient.end();
        }
    }

    sendMessage(key) {
        const {counter, selectedRobot, varAPIKey, availableRobots} = this.state;

        const keyMapping = {
            up: 'FORWARD',
            down: 'BACKWARD',
            left: 'RIGHT',
            right: 'LEFT',
            enter: 'STOP',
            z: 'LIFT',
            x: 'RELEASE'
        };

        try {
            if (availableRobots.includes(selectedRobot)) {
                const payload = {command: keyMapping[key]};

                const command = JSON.stringify(payload);
                JSON.parse(command);
            
                this.mqttClient.publish(`/${varAPIKey}/${selectedRobot}/attrs/teleop/commands`, command);
                
                this.setState({counter: counter + 1});
            }
        } catch (_error) {
            // eslint-disable-next-line no-console
            console.log('Error', _error);
        }
    }

    renderJoystick() {
        const {pressedButton} = this.state;

        const upBColor = (pressedButton === 'up') ? '#ff7e33' : '#16335B';
        const downBColor = (pressedButton === 'down') ? '#ff7e33' : '#16335B';
        const leftBColor = (pressedButton === 'left') ? '#ff7e33' : '#16335B';
        const rightBColor = (pressedButton === 'right') ? '#ff7e33' : '#16335B';

        const stopBColor = (pressedButton === 'enter') ? '#ff7e33' : '#16335B';

        const lBColor = (pressedButton === 'z') ? '#ff7e33' : '#16335B';
        const rBColor = (pressedButton === 'x') ? '#ff7e33' : '#16335B';

        const joystick = (
            <div
                style={{
                    width: '50%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'
                }}
            >            
                <GridDiv>
                    <GridItemDiv> </GridItemDiv>
                    <GridItemDiv>
                        <CustomButton type="button" width="10px" height="10px" icon="caret-up" background={upBColor} backgroundHover="#ff7e33" intent="None" onClick={() => this.sendMessage('up')} />                                     
                    </GridItemDiv>
                    <GridItemDiv> </GridItemDiv>
                    <GridItemDiv>
                        <CustomButton type="button" width="10px" height="10px" icon="caret-left" background={leftBColor} backgroundHover="#ff7e33" intent="None" onClick={() => this.sendMessage('right')} />
                    </GridItemDiv>
                    <GridItemDiv> 
                        <CustomButton type="button" width="8px" height="8px" icon="delete" background={stopBColor} color="white" backgroundHover="#ff7e33" intent="None" onClick={() => this.sendMessage('enter')} />
                    </GridItemDiv>
                    <GridItemDiv>
                        <CustomButton type="button" width="10px" height="10px" icon="caret-right" background={rightBColor} backgroundHover="#ff7e33" intent="None" onClick={() => this.sendMessage('left')} /> 
                    </GridItemDiv>
                    <GridItemDiv> </GridItemDiv>
                    <GridItemDiv>
                        <CustomButton type="button" width="10px" height="10px" icon="caret-down" background={downBColor} backgroundHover="#ff7e33" intent="None" onClick={() => this.sendMessage('down')} />
                    </GridItemDiv>
                    <GridItemDiv> </GridItemDiv>
                </GridDiv>
                <div
                    style={{
                        width: '50%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly'
                    }}
                >
                    <CustomButton type="button" width="10px" height="45px" intent="None" background={lBColor} onClick={() => this.sendMessage('z')}>
                        L
                    </CustomButton>
                    <CustomButton type="button" width="10px" height="45px" intent="None" background={rBColor} onClick={() => this.sendMessage('x')}>
                        R
                    </CustomButton>
                </div>
            </div>
        );

        return joystick;
    }

    render() {
        const {id, name, selectedRobot, availableRobots, counter, fontSize, timeSpan, minint, maxint, meanint, timeSpanVal, minintVal, meanintVal, maxintVal} = this.state;

        return (
            <div
                style={{
                    width: '100%', height: '100%', background: 'white', padding: '1%', display: 'flex', flexDirection: 'column', borderRadius: '10px', fontSize: '16px'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '25px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        background: '#16335B',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        position: 'relative',
                        fontSize: '13px'
                    }}
                >
                    <EditableText disabled className="name-no-edit" placeholder="Component Name" value={name} />
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            right: '2%',
                            transform: 'translateY(-50%)',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Tooltip
                            popoverClassName="item-info-tooltip"
                            content={(
                                <div>
                                    <div>
                                        <div>
                                            <Text>{timeSpan}</Text>
                                            <ProgressBar
                                                intent="primary"
                                                animate={false}
                                                stripes={false}
                                                value={timeSpanVal / maxintVal}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <Text>{minint}</Text>
                                            <ProgressBar
                                                intent="success"
                                                animate={false}
                                                stripes={false}
                                                value={minintVal / maxintVal}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <Text>{meanint}</Text>
                                            <ProgressBar
                                                intent="warning"
                                                animate={false}
                                                stripes={false}
                                                value={meanintVal / maxintVal}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <Text>{maxint}</Text>
                                            <ProgressBar
                                                intent="danger"
                                                animate={false}
                                                stripes={false}
                                                value={maxintVal / maxintVal}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            interactionKind="hover"
                        >
                            <Tag
                                round
                                intent="primary"
                                style={{
                                    background: '#16335B',
                                    color: '#aaaaaa',
                                    fontSize: '13px'
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faChartBar}
                                    style={{
                                        color: '#aaaaaa',
                                        paddingRight: '4px',
                                        fontSize: '13px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={this.filterMessages}
                                />
                                {counter}
                            </Tag>
                        </Tooltip>
                    </div>
                    
                    <div
                        style={{
                            height: '100%',
                            position: 'absolute',
                            top: '0px',
                            left: '2%',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Popover popoverClassName="custom-popover" interactionKind={PopoverInteractionKind.HOVER} position={Position.RIGHT_TOP}>
                            <CustomButton type="button" width="6px" height="6px" icon="info-sign" background="transparent" backgroundHover="transparent" intent="None" />
                            <Menu>
                                <MenuItem text="DIRECTIONS -> {arrow} keys" />
                                <MenuItem text="STOP -> {enter} key" />
                                <MenuItem text="LIFT -> {z} key" />
                                <MenuItem text="RELEASH -> {x} key" />
                            </Menu>
                        </Popover>
                    </div>
                    
                </div>
                <ReactResizeDetector onResize={this.resize}>
                    {() => (
                        <div
                            id={`valueDiv_${id}`}
                            style={{
                                width: '100%',
                                height: 'calc(100% - 35px)',
                                maxHeight: '100%',
                                marginTop: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#16335B',
                                fontSize: `${fontSize}px`,
                                position: 'relative'
                            }}
                        >
                            <Hotkeys
                                keyName="up, down, left, right, enter, z, x"
                                onKeyDown={this.onKeyDown}
                                onKeyUp={this.onKeyUp}
                            />

                            <div
                                style={{
                                    width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'
                                }}
                            >                              
                                <div
                                    style={{
                                        width: '50%', height: '100%', display: 'flex', overflowY: 'auto', direction: 'rtl' 
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '100%', height: '100%', display: 'flex', direction: 'ltr', fontSize: '20px', margin: '5px 0px 15px 10px'  
                                        }}
                                    >
                                        <RadioGroup
                                            inline
                                            name="group"
                                            onChange={this.handleRadioChange}
                                            selectedValue={selectedRobot}
                                        >
                                            {availableRobots.map((r) => (
                                                <Radio label={r} value={r} />
                                            ))}
                                        </RadioGroup>
                                    </div>
                                </div>
                                {this.renderJoystick()}
                            </div>
                        </div>
                    )}
                </ReactResizeDetector>
            </div>
        );
    }
}

const createRobotTeleop = ({id, type, initialState, user, owner}) => (
    <RobotTeleop
        id={id}
        type={type}
        initialState={initialState}
        user={user}
        owner={owner}
    />
);

export default createRobotTeleop;
