/* eslint-disable max-len */
import React from 'react';
import {
    EditableText, Tag, Spinner, Popover, Position, PopoverInteractionKind, Divider, Menu, MenuItem
} from '@blueprintjs/core';
/* eslint-disable import/no-unresolved */
import ReactResizeDetector from 'react-resize-detector';
import {map} from 'rxjs/operators';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {ToasterBottom} from '../../../lib/toaster';
import {findSource} from '../../../api/sources';
import {PortalOverflowOverlay} from '../../../lib/overlays';
import {BlueBorderButton} from '../../../lib/buttons';
import imagePlaceholder from '../../../assets/imagePlaceholder.png';
import {getFiwareEntities} from '../../../api/general';

const objectPath = require('object-path');
const mqtt = require('mqtt');

function constructMQTTPayload(recvTopic, message) {
    const topic = recvTopic.replaceAll('/', '.');
    const payload = {
        topic,
        message
    };

    return payload;
}

class RobotImages extends React.Component {
    constructor(props) {
        super(props);

        this.type = props.type;

        this.state = {
            spinnerOpen: true,
            id: props.id,
            user: props.user,
            owner: props.owner,
            name: props.initialState.name || 'Image',
            availableRobots: {},
            counter: 0,
            source: props.initialState.source || 'Select source',
            varAPIKey: props.initialState.varAPIKey || '',
            varEntityType: props.initialState.varEntityType || '',
            selectedRobot: 'All Robots',

            width: 50,
            height: 50,
            imagePopupOpen: false
        };
        this.rxStomp = null;
        this.mqttClient = null;

        this.changeSpinner = this.changeSpinner.bind(this);
        this.changeAvailRobots = this.changeAvailRobots.bind(this);
        this.messageReceived = this.messageReceived.bind(this);
        this.connectStompSource = this.connectStompSource.bind(this);
        this.connectMqttSource = this.connectMqttSource.bind(this);
        this.connectToTopic = this.connectToTopic.bind(this);
        this.resize = this.resize.bind(this);
        this.openImage = this.openImage.bind(this);
        this.closeImage = this.closeImage.bind(this);
        this.renderAvailableRobots = this.renderAvailableRobots.bind(this);
        this.initialComponentsState = this.initialComponentsState.bind(this);
    }

    async componentDidMount() {
        const {source, owner, user, varEntityType} = this.state;
        this.connectToTopic();

        let response = await findSource(source, owner, user);

        // custom rest call
        if (response.success) {
            const url = response.source.orionUrl;
            const formattedUrl = (url.startsWith('http://') || url.startsWith('https://')) ? url : `http://${url}`;

            const params = JSON.stringify({
                type: varEntityType,
                options: 'keyValues',
                attrs: 'image'
            });

            const headers = JSON.stringify({
                'Fiware-service': 'openiot', 
                'Fiware-servicepath': '/' 
            });

            const query = JSON.stringify({});
            
            try {
                response = await getFiwareEntities(formattedUrl, headers, params, query);
                this.initialComponentsState(response.data);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log('Error: ', error);
            }
        }
    }

    componentWillUnmount() {
        if (this.rxStomp !== null) {
            this.rxStomp.deactivate();
        }
        if (this.mqttClient !== null) {
            this.mqttClient.end();
        }
    }

    changeSpinner(value) {
        this.setState({spinnerOpen: value});
    }

    initialComponentsState(response) {
        const {availableRobots, varEntityType} = this.state;

        response.forEach((r) => {
            const robotId = r.id.split(/[:]+/)[3];
            const robot = `${varEntityType}${robotId}`;

            if (Object.keys(availableRobots).length === 0) {
                this.setState({selectedRobot: robot});
            }

            if (r.image) {
                if (Object.prototype.hasOwnProperty.call(r.image, 'val')) {
                    availableRobots[robot] = {image: `data:image/jpg;base64,${r.image.val}`};
                }
            } else {
                availableRobots[robot] = {image: imagePlaceholder};
            }
        });

        this.setState(availableRobots);
    }

    changeAvailRobots(value) {
        this.setState({selectedRobot: value});
    }
    
    messageReceived(payload) {
        const {availableRobots} = this.state;
        const {topic, message} = payload;

        try {
            const {counter} = this.state;
            const newCounter = counter + 1;

            const robotId = topic.split(/[.]+/)[2];
            const image = objectPath.get(message, 'val');
            
            if (Object.keys(availableRobots).length === 0) {
                this.setState({selectedRobot: robotId});
            }

            if (!(robotId in availableRobots)) {
                availableRobots[robotId] = {image: `data:image/jpg;base64,${image}`};
            } else {
                availableRobots[robotId].image = `data:image/jpg;base64,${image}`;           
            }

            this.setState({image, counter: newCounter});
        } catch {}
    }

    connectStompSource(source) {
        const {name, varAPIKey} = this.state;
        try {
            const stompConfig = {
                connectHeaders: {
                    login: source.login,
                    passcode: source.passcode,
                    host: source.vhost
                },
                brokerURL: source.url
            };
            // eslint-disable-next-line no-undef
            this.rxStomp = new RxStomp.RxStomp();
            this.rxStomp.configure(stompConfig);
            this.rxStomp.activate();
            const initialReceiptId = `${name}_start`;

            this.rxStomp.watch(`/topic/${varAPIKey}`, {receipt: initialReceiptId}).pipe(map((message) => JSON.parse(message.body))).subscribe((payload) => {
                this.messageReceived(payload);
            });
            this.rxStomp.watchForReceipt(initialReceiptId, () => {
                this.changeSpinner(false);
            });
        } catch {}
    }

    connectMqttSource(source) {
        const {varAPIKey} = this.state;
        const topic = `/${varAPIKey}/+/attrs/image`;

        try {
            const config = {
                username: source.login,
                password: source.passcode
            };

            this.mqttClient = mqtt.connect(source.url, config);
            this.mqttClient.on('connect', () => {
                this.mqttClient.subscribe(`${topic}`, (err) => {
                    if (!err) {
                        this.changeSpinner(false);
                    }
                });
            });

            this.mqttClient.on('message', (recvTopic, message) => {
                this.messageReceived(constructMQTTPayload(recvTopic, JSON.parse(message.toString())));
            });
        } catch {}
    }

    async connectToTopic() {
        const {user, owner, name, source} = this.state;
        const response = await findSource(source, owner, user);
        if (response.success) {
            if (response.source.type === 'stomp') {
                this.connectStompSource(response.source);
            } else {
                this.connectMqttSource(response.source);
            }
        } else {
            ToasterBottom.show({
                intent: 'danger',
                message: response.message || `There was a problem trying to find the source for ${name}`
            });
        }
    }

    resize(width, height) {
        this.setState({
            width,
            height,
        });
    }

    openImage() {
        this.setState({imagePopupOpen: true});
    }

    closeImage() {
        this.setState({imagePopupOpen: false});
    }

    renderAvailableRobots() {
        const {availableRobots} = this.state;
        const keys = Object.keys(availableRobots);
        keys.unshift('All Robots');

        const exampleMenu = (
            <Menu>
                <div
                    style={{
                        display: 'flex', width: '100%', height: '100%', flexDirection: 'column', alignItems: 'center'
                    }}
                >
                    {keys.map((robot) => (
                        <MenuItem icon="feed" text={robot} onClick={() => this.changeAvailRobots(robot)} />
                    ))}
                </div>
            </Menu>
        );

        return exampleMenu;
    }

    render() {
        const {spinnerOpen, selectedRobot, availableRobots, id, name, image, counter, width, height, imagePopupOpen} = this.state;  
        const keys = Object.keys(availableRobots);

        return ([
            <div
                style={{
                    width: '100%', height: '100%', background: 'white', padding: '1%', display: 'flex', flexDirection: 'column', borderRadius: '10px', fontSize: '16px'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '25px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        background: '#16335B',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        position: 'relative',
                        fontSize: '13px'
                    }}
                >
                    <EditableText disabled className="name-no-edit" placeholder="Component Name" value={name} />
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            right: '2%',
                            transform: 'translateY(-50%)',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Tag
                            round
                            intent="primary"
                            style={{
                                background: '#16335b',
                                color: '#888888',
                                fontSize: '13px'
                            }}
                        >
                            {counter}
                        </Tag>
                    </div>
                </div>
                <ReactResizeDetector onResize={this.resize}>
                    {() => (
                        <div
                            id={`imageDiv_${id}`}
                            style={{
                                width: '100%',
                                height: 'calc(100% - 35px)',
                                maxHeight: '100%',
                                marginTop: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative'
                            }}
                        >
                            {spinnerOpen
                            && (
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'absolute',
                                        top: '0px',
                                        left: '0px',
                                        zIndex: 1000,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: 'rgba(255, 255, 255, 0.6)'
                                    }}
                                >
                                    <Spinner intent="primary" size={Math.min(width / 10, height / 2)} />
                                </div>
                            )}
                            <div
                                style={{
                                    width: '100%', height: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center'
                                }}
                            >   
                                <div
                                    style={{marginRight: '20px'}}
                                >
                                    Selected Robot:
                                </div>           
                                <Popover
                                    content={this.renderAvailableRobots()} 
                                    position={Position.BOTTOM}
                                    interactionKind={PopoverInteractionKind.CLICK}
                                    disabled={keys.length === 0}
                                >
                                    <BlueBorderButton type="button" diplay="flex" width="100%" justifyContent="stretch" rightIcon="caret-down" disabled={keys.length === 0}>
                                        {selectedRobot}
                                    </BlueBorderButton>
                                </Popover>
                            </div>
                            <div
                                style={{
                                    width: '100%', height: '90%', margin: '10px', display: 'grid', gridTemplateColumns: 'auto auto auto', padding: '10px', alignItems: 'center', justifyContent: 'center', overflowY: 'auto'
                                }}
                            >   
                                {(selectedRobot === 'All Robots') && keys.map((r) => (
                                    (r !== 'All Robots') && (
                                        <div
                                            style={{
                                                backgroundColor: 'rgba(255, 255, 255, 1.0)',
                                                border: '1px solid rgba(0, 0, 0, 0.8)',
                                                borderRadius: '5px',
                                                padding: '5px',
                                                margin: '5px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                fontSize: '15px'
                                            }}
                                        >
                                            {Object.keys(availableRobots).length !== 0
                                        && <img src={availableRobots[r].image} alt="" style={{width: '100%', height: '100%', objectFit: 'contain'}} />}
                                            <Divider />
                                            <p>{r}</p>
                                        </div>
                                    )))}
                                {(selectedRobot !== 'All Robots' && keys.length !== 0) && <img src={availableRobots[selectedRobot].image} alt="" style={{width: '100%', height: '100%', objectFit: 'contain'}} />}
                                {keys.length === 0 
                                && <p>No Robots currently availalble!</p>}
                            </div>
                        </div>
                    )}
                </ReactResizeDetector>
            </div>,
            <PortalOverflowOverlay key="image" id="image" isOpen={imagePopupOpen} width="533.33px" height="400px" background="none" borderRadius="10px" padding="0px" marginLeft="auto" marginRight="auto" color="black">
                <div style={{width: '100%', height: '100%', borderRadius: '10px', position: 'relative'}}>
                    <img src={image} alt="" style={{width: '100%', height: '100%', objectFit: 'contain', borderRadius: '10px'}} />
                    <div
                        style={{
                            width: '30px', height: '30px', borderRadius: '30px', position: 'absolute', top: '-15px', right: '-15px', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#D0D6DE'
                        }}
                    >
                        <FontAwesomeIcon icon={faTimes} style={{color: '#16335B', fontSize: '24px', cursor: 'pointer'}} onClick={this.closeImage} />
                    </div>
                </div>
            </PortalOverflowOverlay>
        ]);
    }
}

const createRobotImages = ({id, type, initialState, user, owner}) => (
    <RobotImages
        id={id}
        type={type}
        initialState={initialState}
        user={user}
        owner={owner}
    />
);

export default createRobotImages;
