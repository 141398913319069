/* eslint-disable max-len */
import React from 'react';
import {
    EditableText, Tag, Spinner, Tooltip, ProgressBar, Text, Popover, PopoverInteractionKind, Position, Menu, MenuItem
} from '@blueprintjs/core';
/* eslint-disable import/no-unresolved */
import ReactResizeDetector from 'react-resize-detector';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChartBar} from '@fortawesome/free-solid-svg-icons';
/* eslint-disable import/no-unresolved */
import GaugeChart from 'react-gauge-chart';
import {map} from 'rxjs/operators';
import {ToasterBottom} from '../../../lib/toaster';
import {findSource} from '../../../api/sources';
import {BlueBorderButton} from '../../../lib/buttons';
import {getFiwareEntities} from '../../../api/general';

const objectPath = require('object-path');
const mqtt = require('mqtt');

function constructPayload(message) {
    const payload = {
        topic: message.headers.destination,
        message: JSON.parse(message.body)
    };

    return payload;
}

function constructMQTTPayload(recvTopic, message) {
    const topic = recvTopic.replaceAll('/', '.');
    const payload = {
        topic,
        message
    };

    return payload;
}

class RobotVelocities extends React.Component {
    constructor(props) {
        super(props);

        this.type = props.type;

        this.state = {
            spinnerOpen: true,
            id: props.id,
            user: props.user,
            owner: props.owner,
            name: props.initialState.name || 'Gauge',
            counter: 0,
            source: props.initialState.source || 'Select source',
            varAPIKey: props.initialState.varAPIKey || '',
            varEntityType: props.initialState.varEntityType || '',
            
            minValue: props.initialState.minValue || 0,
            maxValue: props.initialState.maxValue || 100,

            selectedRobot: 'None',
            availableRobots: {},

            leftColor: props.initialState.leftColor || '#7ABF43',
            rightColor: props.initialState.rightColor || '#DE162F',
            levels: props.initialState.levels || 20,
            width: 50,
            originalWidth: 50,
            height: 50,
        };
        this.rxStomp = null;
        this.mqttClient = null;
        this.fiware_url = null;

        this.changeSpinner = this.changeSpinner.bind(this);
        this.messageReceived = this.messageReceived.bind(this);
        this.connectStompSource = this.connectStompSource.bind(this);
        this.connectMqttSource = this.connectMqttSource.bind(this);
        this.connectToTopic = this.connectToTopic.bind(this);
        this.resize = this.resize.bind(this);
        this.renderRobots = this.renderRobots.bind(this);
        this.changeSelectedRobot = this.changeSelectedRobot.bind(this);
        this.initialComponentsState = this.initialComponentsState.bind(this);
    }

    async componentDidMount() {
        const {source, owner, user, varEntityType} = this.state;
        this.connectToTopic();

        let response = await findSource(source, owner, user);

        // custom rest call
        if (response.success) {
            const url = response.source.orionUrl;
            const formattedUrl = (url.startsWith('http://') || url.startsWith('https://')) ? url : `http://${url}`;

            const params = JSON.stringify({
                type: varEntityType,
                options: 'keyValues',
                attrs: 'velocities'
            });

            const headers = JSON.stringify({
                'Fiware-service': 'openiot', 
                'Fiware-servicepath': '/' 
            });

            const query = JSON.stringify({});
            
            try {
                response = await getFiwareEntities(formattedUrl, headers, params, query);
                this.initialComponentsState(response.data);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log('Error: ', error);
            }
        } 
    }

    componentWillUnmount() {
        if (this.rxStomp !== null) {
            this.rxStomp.deactivate();
        }
        if (this.mqttClient !== null) {
            this.mqttClient.end();
        }
    }

    changeSpinner(value) {
        this.setState({spinnerOpen: value});
    }

    initialComponentsState(response) {
        const {availableRobots, varEntityType} = this.state;

        response.forEach((r) => {
            const robotId = r.id.split(/[:]+/)[3];
            const robot = `${varEntityType}${robotId}`;

            if (Object.keys(availableRobots).length === 0) {
                this.setState({selectedRobot: robot});
            }
            
            if (r.velocities) {
                if (Object.prototype.hasOwnProperty.call(r.velocities, 'linear')  
                && Object.prototype.hasOwnProperty.call(r.velocities, 'angular')) {
                    availableRobots[robot] = r.velocities;
                }
            } else {
                availableRobots[robot] = {
                    linear: 0,
                    angular: 0
                };
            }
        });

        this.setState(availableRobots);
    }

    messageReceived(payload) {
        const {availableRobots} = this.state;
        const {topic, message} = payload;

        try {
            const {counter} = this.state;
            const newCounter = counter + 1;
            let ts = (new Date() - this.prevTime) / 1000.0;
            if (this.prevTime < 0) {
                ts = '-';
                this.minInterval = 1000000000;
                this.maxInterval = 0;
                this.meanInterval = 0;
            } else {
                if (ts < this.minInterval) {
                    this.minInterval = ts;
                }
                if (ts > this.maxInterval) {
                    this.maxInterval = ts;
                }
                this.meanInterval += ts;
            }
            this.prevTime = new Date();
            
            const robotId = topic.split(/[.]+/)[2];
            const linearSpeed = objectPath.get(message, 'linear');
            const angularSpeed = objectPath.get(message, 'angular');

            if (Object.keys(availableRobots).length === 0) {
                this.setState({selectedRobot: robotId});
            }
            
            if (!(robotId in availableRobots)) {
                availableRobots[robotId] = {
                    linear: linearSpeed,
                    angular: angularSpeed
                };
            } else {
                availableRobots[robotId].linear = linearSpeed;
                availableRobots[robotId].angular = angularSpeed;
            }

            this.setState({
                availableRobots,
                timeSpan: `Last interval: ${ts} sec`,
                minint: `Minimum interval: ${this.minInterval} sec`,
                meanint: `Mean interval: ${(this.meanInterval / (newCounter - 1)).toFixed(3)} sec`,
                maxint: `Maximum interval: ${this.maxInterval} sec`,
                timeSpanVal: ts,
                minintVal: this.minInterval,
                meanintVal: (this.meanInterval / (newCounter - 1)).toFixed(3),
                maxintVal: this.maxInterval,
                counter: newCounter
            });
        } catch {}
    }

    connectStompSource(source) {
        const {name, varAPIKey} = this.state;
        const topic = `.${varAPIKey}.*.attrs.velocities`;

        try {
            const stompConfig = {
                connectHeaders: {
                    login: source.login,
                    passcode: source.passcode,
                    host: source.vhost
                },
                brokerURL: source.url
            };
            
            // eslint-disable-next-line no-undef
            this.rxStomp = new RxStomp.RxStomp();
            this.rxStomp.configure(stompConfig);
            this.rxStomp.activate();
            const initialReceiptId = `${name}_start`;

            this.prevTime = -1;
            this.minInterval = -1;
            this.maxInterval = -1;
            this.meanInterval = 0;

            this.rxStomp.watch(`/topic/${topic}`, {receipt: initialReceiptId}).pipe(map((message) => constructPayload(message))).subscribe((topic2, payload) => {
                this.messageReceived(topic2, payload);
            });
            this.rxStomp.watchForReceipt(initialReceiptId, () => {
                this.changeSpinner(false);
            });
        } catch {}
    }

    connectMqttSource(source) {
        const {varAPIKey} = this.state;
        const topic = `/${varAPIKey}/+/attrs/velocities`;

        try {
            const config = {
                username: source.login,
                password: source.passcode
            };

            this.mqttClient = mqtt.connect(source.url, config);
            this.mqttClient.on('connect', () => {
                this.mqttClient.subscribe(`${topic}`, (err) => {
                    if (!err) {
                        this.changeSpinner(false);
                    }
                });
            });
            
            this.prevTime = -1;
            this.minInterval = -1;
            this.maxInterval = -1;
            this.meanInterval = 0;

            this.mqttClient.on('message', (recvTopic, message) => {
                this.messageReceived(constructMQTTPayload(recvTopic, JSON.parse(message.toString())));
            });
        } catch {}
    }

    async connectToTopic() {
        const {user, owner, name, source} = this.state;
        const response = await findSource(source, owner, user);

        if (response.success) {
            if (response.source.type === 'stomp') {
                this.connectStompSource(response.source);
            } else {
                this.connectMqttSource(response.source);
            } 
        } else {
            ToasterBottom.show({
                intent: 'danger',
                message: response.message || `There was a problem trying to find the source for ${name}`
            });
        }
    }

    changeSelectedRobot(value) {
        this.setState({selectedRobot: value});
    }

    resize(width, height) {
        let newWidth;
        if (width > 2.2225 * height) {
            newWidth = ((2.2225 * height) / width) * 100;
        } else {
            newWidth = 100;
        }
        this.setState({width: newWidth, originalWidth: width, height});
    }

    renderRobots() {
        const {availableRobots} = this.state;
        const keys = Object.keys(availableRobots);

        const exampleMenu = (
            <Menu>
                <div
                    style={{
                        display: 'flex', width: '100%', height: '100%', flexDirection: 'column', alignItems: 'center'
                    }}
                >
                    {keys.map((robot) => (
                        <MenuItem icon="feed" text={robot} onClick={() => this.changeSelectedRobot(robot)} />

                    ))}
                </div>
            </Menu>
        );

        return exampleMenu; 
    }

    render() {
        const {spinnerOpen, id, name, counter, timeSpan, minint, maxint, meanint, timeSpanVal, minintVal, meanintVal, maxintVal, leftColor, rightColor, levels, width, originalWidth, height, availableRobots, selectedRobot, minValue, maxValue} = this.state;

        let linearSpeed = 0.0;
        let angularSpeed = 0.0;
        if (Object.keys(availableRobots).includes(selectedRobot)) {
            linearSpeed = availableRobots[selectedRobot].linear;
            angularSpeed = availableRobots[selectedRobot].angular;
        }

        const linearSpeedPercentage = Math.min(Math.max(((linearSpeed - minValue) / (maxValue - minValue)), 0), 1);
        const angularSpeedPercentage = Math.min(Math.max(((angularSpeed - minValue) / (maxValue - minValue)), 0), 1);

        const noRobots = (Object.keys(availableRobots).length === 0);

        return (
            <div
                style={{
                    width: '100%', height: '100%', background: 'white', padding: '1%', display: 'flex', flexDirection: 'column', borderRadius: '10px', fontSize: '16px'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '25px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        background: '#16335B',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        position: 'relative',
                        fontSize: '13px'
                    }}
                >
                    <EditableText disabled className="name-no-edit" placeholder="Component Name" value={name} />
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            right: '2%',
                            transform: 'translateY(-50%)',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Tooltip
                            popoverClassName="item-info-tooltip"
                            content={(
                                <div>
                                    <div>
                                        <div>
                                            <Text>{timeSpan}</Text>
                                            <ProgressBar
                                                intent="primary"
                                                animate={false}
                                                stripes={false}
                                                value={timeSpanVal / maxintVal}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <Text>{minint}</Text>
                                            <ProgressBar
                                                intent="success"
                                                animate={false}
                                                stripes={false}
                                                value={minintVal / maxintVal}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <Text>{meanint}</Text>
                                            <ProgressBar
                                                intent="warning"
                                                animate={false}
                                                stripes={false}
                                                value={meanintVal / maxintVal}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <Text>{maxint}</Text>
                                            <ProgressBar
                                                intent="danger"
                                                animate={false}
                                                stripes={false}
                                                value={maxintVal / maxintVal}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            interactionKind="hover"
                        >
                            <Tag
                                round
                                intent="primary"
                                style={{
                                    background: '#16335B',
                                    color: '#aaaaaa',
                                    fontSize: '13px'
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faChartBar}
                                    style={{
                                        color: '#aaaaaa',
                                        paddingRight: '4px',
                                        fontSize: '13px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={this.filterMessages}
                                />
                                {counter}
                            </Tag>
                        </Tooltip>
                    </div>
                </div>
                <ReactResizeDetector onResize={this.resize}>
                    {() => (
                        <div
                            id={`gaugeDiv_${id}`}
                            style={{
                                width: '100%',
                                height: 'calc(100% - 35px)',
                                maxHeight: '100%',
                                marginTop: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative'
                            }}
                        >
                            {spinnerOpen
                            && (
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'absolute',
                                        top: '0px',
                                        left: '0px',
                                        zIndex: 1000,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: 'rgba(255, 255, 255, 0.6)'
                                    }}
                                >
                                    <Spinner intent="primary" size={Math.min(originalWidth / 10, height / 2)} />
                                </div>
                            )}
                            <div
                                style={{
                                    width: '100%', height: '20%', marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'
                                }}
                            >
                                <div
                                    style={{marginRight: '20px'}}
                                >
                                    Select Robot:
                                </div>                      
                                <Popover 
                                    content={this.renderRobots()} 
                                    position={Position.BOTTOM}
                                    interactionKind={PopoverInteractionKind.CLICK}
                                    disabled={noRobots}
                                >   
                                    <BlueBorderButton type="button" diplay="flex" width="100%" justifyContent="stretch" rightIcon="caret-down" disabled={noRobots}>
                                        {selectedRobot}
                                    </BlueBorderButton>
                                </Popover>           
                            </div>
                            <div
                                id={`gaugeDiv_${id}`}
                                style={{
                                    width: '100%',
                                    height: '60%',
                                    maxHeight: '100%',
                                    marginTop: '10px',
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center'
                                }}
                            >
                                <GaugeChart
                                    id={`gauge_${id}`}
                                    nrOfLevels={levels}
                                    percent={linearSpeedPercentage}
                                    textColor="#16335B"
                                    colors={[leftColor, rightColor]}
                                    style={{width: `${width}%`}}
                                    hideText
                                />

                                <GaugeChart
                                    id={`gauge_${id}`}
                                    nrOfLevels={levels}
                                    percent={angularSpeedPercentage}
                                    textColor="#16335B"
                                    colors={[leftColor, rightColor]}
                                    style={{width: `${width}%`}}
                                    hideText
                                />
                            </div>

                            <div
                                style={{
                                    width: '100%', height: '20%', display: 'flex', alignItems: 'center', justifyContent: 'space-around'
                                }}
                            >
                                <p>
                                    {'Linear: '}
                                    <b>
                                        {`${linearSpeed}`} 
                                    </b>
                                    {' m/s'}
                                </p>
                                <p>
                                    {'Angular: '}
                                    <b>
                                        {`${angularSpeed}`} 
                                    </b>
                                    {' m/s'}
                                </p>    
                            </div>
                        </div>
                    )}
                </ReactResizeDetector>
            </div>
        );
    }
}

const createRobotVelocities = ({id, type, initialState, user, owner}) => (
    <RobotVelocities
        id={id}
        type={type}
        initialState={initialState}
        user={user}
        owner={owner}
    />
);

export default createRobotVelocities;
