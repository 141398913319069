/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import {
    Alert, EditableText, InputGroup, Menu, MenuItem, Popover, Tooltip, NumericInput, Position, PopoverInteractionKind
} from '@blueprintjs/core';
import {
    faCog, faTrashAlt, faClone
} from '@fortawesome/free-solid-svg-icons';
/* eslint-disable import/no-unresolved */
import ReactResizeDetector from 'react-resize-detector';
import {
    XYPlot, LineSeries, HorizontalGridLines, VerticalGridLines, XAxis, YAxis, Highlight, Borders, VerticalBarSeries
} from 'react-vis';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {PortalOverflowOverlay} from '../../../lib/overlays';
import {
    BlueBorderButton, BlueButton
} from '../../../lib/buttons';

import '../../../../node_modules/react-vis/dist/style.css';

const FormHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: bold;
    color: #16335B;
    position: relative;
`;

const SettingsDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;


const formatDate = (dateM) => {
    const date = new Date(dateM);
    // const day = ((String(date.getDate())).length === 1) ? `0${String(date.getDate())}` : String(date.getDate());
    // const month = ((String(date.getMonth() + 1)).length === 1) ? `0${String(date.getMonth() + 1)}` : String(date.getMonth() + 1);
    // const year = date.getFullYear();
    const hours = ((String(date.getHours())).length === 1) ? `0${String(date.getHours())}` : String(date.getHours());
    const minutes = ((String(date.getMinutes())).length === 1) ? `0${String(date.getMinutes())}` : String(date.getMinutes());
    const seconds = ((String(date.getSeconds())).length === 1) ? `0${String(date.getSeconds())}` : String(date.getSeconds());

    return (`${hours}:${minutes}:${seconds}`);

    // return (`${day}/${month}/${year}`);
};

class RobotBattery extends React.Component {
    constructor(props) {
        super(props);

        this.type = props.type;
        this.updateItem = props.updateItem;
        this.deleteItem = props.deleteItem;
        this.cloneComponent = props.cloneComponent;

        let initialNames; 
        let initialTypes = [];
        let initialTopics = [];
        let initialVariables = [];
        let initialColors = [];
        let initialSmooths = [];
        if ('names' in props.initialState) {
            initialNames = props.initialState.names;
            for (let i = 0; i < initialNames.length; i += 1) {
                initialTypes.push('line');
                initialTopics.push('');
                initialVariables.push('');
                initialColors.push('#FF9D66');
                initialSmooths.push(false);
            }
        } else {
            initialNames = ['Plot 1']; 
            initialTypes = ['line'];
            initialTopics = [''];
            initialVariables = [''];
            initialColors = ['#FF9D66'];
            initialSmooths = [false];
        }

        this.state = {
            id: props.id,
            availableSources: props.availableSources,
            name: props.initialState.name || 'Plot Viewer',
            source: props.initialState.source || 'Select source',
            varAPIKey: props.initialState.varAPIKey || '',
            varEntityType: props.initialState.varEntityType || '',
            maxValues: props.initialState.maxValues || -1,
            popoverOpen: false,
            deletePopupOpen: false,
            tempSource: 'Select source',
            tempMaxValues: -1,
            tempRobot: "None",
            width: 50,
            height: 50,
            lastDrawLocation: null
        };

        this.generateValues = this.generateValues.bind(this);
        this.sendUpdate = this.sendUpdate.bind(this);
        this.delete = this.delete.bind(this);
        this.changeName = this.changeName.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.closeConfirmPopup = this.closeConfirmPopup.bind(this);
        this.openDelete = this.openDelete.bind(this);
        this.closeDelete = this.closeDelete.bind(this);
        this.changeSource = this.changeSource.bind(this);
        this.changeAPIKeyVar = this.changeAPIKeyVar.bind(this);
        this.changeEntityTypeVar = this.changeEntityTypeVar.bind(this);
        this.changeMaxValues = this.changeMaxValues.bind(this);
        this.changeAvailRobots = this.changeAvailRobots.bind(this);
        this.clone = this.clone.bind(this);
        this.resize = this.resize.bind(this);
        this.back = this.back.bind(this);
        this.renderExampleRobot = this.renderExampleRobot.bind(this);

        this.availRobots = ['All Robots', 'Robot1', 'Robot2', 'Robot3', 'Robot4', 'Robot5'];
    }

    static getDerivedStateFromProps(props) {
        return {
            id: props.id,
            availableSources: props.availableSources,
            name: props.initialState.name || 'Plot Viewer',
            source: props.initialState.source || 'Select source',
            varAPIKey: props.initialState.varAPIKey || '',
            varEntityType: props.initialState.varEntityType || '',
            maxValues: props.initialState.maxValues || -1,
            names: props.initialState.names || ['Plot 1'],
            types: props.initialState.types || ['line'],
            topics: props.initialState.topics || [''],
            variables: props.initialState.variables || [''],
            colors: props.initialState.colors || ['#FF9D66'],
            smooths: props.initialState.smooths || [false]
        };
    }

    // eslint-disable-next-line class-methods-use-this
    generateValues() {
        const today = new Date();
        const data = [];
        for (let i = 0; i < Math.floor(Math.random() * (10 - 5) + 5); i += 1) {
            data.push({x: (new Date(today.getTime() + (Math.floor(Math.random() * (10 - 5) + 5) * 60 * 1000))).getTime(), y: Math.random()});
        }
        return data;
    }

    sendUpdate(key, value) {
        const {id} = this.state;
        this.updateItem(id, key, value);
    }

    delete() {
        const {id} = this.state;
        this.setState({deletePopupOpen: false});
        this.deleteItem(id);
    }

    changeName(value) {
        this.sendUpdate('name', value);
    }

    openPopup() {
        const {source, varAPIKey, varEntityType, maxValues} = this.state;
        this.setState({
            popoverOpen: true,
            tempSource: source,
            tempAPIKey: varAPIKey,
            tempEntityType: varEntityType,
            tempMaxValues: maxValues
        });
    }

    closePopup() {
        this.setState({
            popoverOpen: false,
            tempSource: 'Select source',
            tempAPIKey: '',
            tempEntityType: '',
            tempMaxValues: -1
        });
    }

    closeConfirmPopup() {
        const {tempSource, tempAPIKey, tempEntityType, tempMaxValues} = this.state;
        this.sendUpdate('source', tempSource);
        this.sendUpdate('varAPIKey', tempAPIKey);
        this.sendUpdate('varEntityType', tempEntityType);
        this.sendUpdate('maxValues', tempMaxValues);
        this.setState({popoverOpen: false});
    }

    openDelete() {
        this.setState({deletePopupOpen: true});
    }

    closeDelete() {
        this.setState({deletePopupOpen: false});
    }

    changeSource(value) {
        this.setState({tempSource: value});
    }

    changeAPIKeyVar(event) {
        event.stopPropagation();
        this.setState({tempAPIKey: event.target.value});
    }

    changeEntityTypeVar(event) {
        event.stopPropagation();
        this.setState({tempEntityType: event.target.value});
    }

    changeMaxValues(value) {
        this.setState({tempMaxValues: value});
    }

    changeAvailRobots(value) {
        this.setState({tempRobot: value});
    }

    clone() {
        const {id} = this.state;
        this.closePopup();
        this.cloneComponent(id);
    }

    resize(width, height) {
        this.setState({width, height});
    }

    back() {
        this.setState({popoverOpen: true});
    }

    renderExampleRobot() {
        const exampleMenu = (
            <Menu>
                <div
                    style={{
                        display: 'flex', width: '100%', height: '100%', flexDirection: 'column', alignItems: 'center'
                    }}
                >
                    {this.availRobots.map((robot) => (
                        <MenuItem icon="feed" text={robot} onClick={() => this.changeAvailRobots(robot)} />

                    ))}
                </div>
            </Menu>
        );

        return exampleMenu;
    }

    render() {
        const {id, availableSources, name, names, types, colors, smooths, popoverOpen, deletePopupOpen, tempSource, tempAPIKey, tempEntityType, tempMaxValues, tempRobot, width, height, lastDrawLocation} = this.state;

        const legendItems = [];
        names.forEach((n, ind) => {
            legendItems.push({title: n, color: colors[ind]});
        });

        const plotValues = [];
        names.forEach(() => {
            plotValues.push(this.generateValues());
        });

        const xTickValues = [];
        plotValues.forEach((pV) => {
            pV.forEach((v) => {
                xTickValues.push(v.x);
            });
        });

        const plots = [];
        names.forEach((__n, ind) => {
            switch (types[ind]) {
            case 'line':
                plots.push(<LineSeries data={plotValues[ind].sort((a, b) => (a.x - b.x))} color={colors[ind]} animation="gentle" curve={smooths[ind] ? 'curveMonotoneX' : ''} />);
                break;
            case 'bar':
                plots.push(<VerticalBarSeries data={plotValues[ind]} color={colors[ind]} animation="gentle" barWidth={0.2} />);
                break;
            default:
            }
        });

        return ([
            <div
                style={{
                    width: '100%', height: '100%', background: 'white', padding: '1%', display: 'flex', flexDirection: 'column', borderRadius: '10px', fontSize: '16px'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '25px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        background: '#16335B',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        position: 'relative',
                        fontSize: '13px'
                    }}
                >
                    <div onMouseDown={(e) => e.stopPropagation()}>
                        <EditableText className="name-edit" onChange={this.changeName} onMouseDown={(e) => e.stopPropagation()} placeholder="Component Name" value={name} />
                    </div>
                    <div
                        style={{
                            height: '100%',
                            position: 'absolute',
                            top: '0px',
                            right: '2%',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        onMouseDown={(e) => e.stopPropagation()}
                    >
                        <div style={{paddingRight: '5px'}}>
                            <Tooltip content="Clone component" popoverClassName="item-info-tooltip">
                                <FontAwesomeIcon icon={faClone} style={{color: 'white', fontSize: '13px', cursor: 'pointer'}} onClick={this.clone} />
                            </Tooltip>
                        </div>
                        <FontAwesomeIcon icon={faCog} style={{color: 'white', cursor: 'pointer'}} onClick={this.openPopup} />
                    </div>
                    <div
                        style={{
                            height: '100%',
                            position: 'absolute',
                            top: '0px',
                            left: '2%',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        onMouseDown={(e) => e.stopPropagation()}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} style={{color: '#DE162F', cursor: 'pointer'}} onClick={this.openDelete} />
                    </div>
                </div>
                <ReactResizeDetector onResize={this.resize}>
                    {() => (
                        <div
                            style={{
                                width: '100%',
                                height: 'calc(100% - 35px)',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%', height: '20%', marginTop: '5px', display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        marginRight: '20px'
                                    }}
                                >
                                    Selected Robot:
                                </div>
                                <Popover 
                                    content={this.renderExampleRobot()} 
                                    position={Position.BOTTOM}
                                    interactionKind={PopoverInteractionKind.CLICK}
                                >
                                    <BlueBorderButton type="button" diplay="flex" width="100%" justifyContent="stretch" rightIcon="caret-down">
                                        {tempRobot}
                                    </BlueBorderButton>
                                </Popover>   
                            </div>

                            <div
                                id={`plotDiv_${id}`}
                                style={{
                                    width: '100%',
                                    height: '80%',
                                    marginTop: '5px',
                                    overflowY: 'auto'
                                }}
                                onMouseDown={(e) => e.stopPropagation()}
                            >
                                <XYPlot
                                    height={height}
                                    width={width}
                                    margin={{bottom: 60}}
                                    xDomain={
                                        lastDrawLocation && [
                                            lastDrawLocation.left,
                                            lastDrawLocation.right
                                        ]
                                    }
                                    yDomain={
                                        lastDrawLocation && [
                                            lastDrawLocation.bottom,
                                            lastDrawLocation.top
                                        ]
                                    }
                                >
                                    {<VerticalGridLines />}
                                    {<HorizontalGridLines />}
                                    {plots}
                                    <Borders style={{
                                        bottom: {fill: '#fff'},
                                        left: {fill: '#fff'},
                                        right: {fill: '#fff'},
                                        top: {fill: '#fff'}
                                    }}
                                    />
                                    {<XAxis tickFormat={(v) => formatDate(v)} tickLabelAngle={-45} tickValues={xTickValues} />}
                                    {<YAxis />}
                                    
                                    <Highlight
                                        onBrushEnd={(area) => this.setState({lastDrawLocation: area})}
                                        onDrag={(area) => {
                                            this.setState({
                                                lastDrawLocation: {
                                                    bottom: lastDrawLocation.bottom + (area.top - area.bottom),
                                                    left: lastDrawLocation.left - (area.right - area.left),
                                                    right: lastDrawLocation.right - (area.right - area.left),
                                                    top: lastDrawLocation.top + (area.top - area.bottom)
                                                }
                                            });
                                        }}
                                    />
                                </XYPlot>
                            </div>
                        </div>
                    )}
                </ReactResizeDetector>
            </div>,
            <PortalOverflowOverlay key="settings" id="settings" isOpen={popoverOpen} width="450px" height="auto" background="white" borderRadius="10px" padding="20px" marginLeft="auto" marginRight="auto" color="black">
                <FormHeader>
                    {`${name} Settings`}
                </FormHeader>
                <SettingsDiv>
                    <Popover popoverClassName="custom-popover">
                        <BlueBorderButton type="button" width="410px" rightIcon="caret-down">
                            {tempSource}
                        </BlueBorderButton>
                        <Menu>
                            {availableSources.map((s) => (
                                <MenuItem text={s} onClick={() => this.changeSource(s)} />
                            ))}
                        </Menu>
                    </Popover>
                    <div
                        style={{
                            width: '100%', height: '100%', marginTop: '10px', display: 'flex', alignItems: 'center'
                        }}
                    >
                        <InputGroup
                            leftIcon="key"
                            placeholder="API Key"
                            onChange={this.changeAPIKeyVar}
                            value={tempAPIKey}
                            fill
                            large
                        />
                    </div>
                    <div
                        style={{
                            width: '100%', height: '100%', marginTop: '10px', display: 'flex', alignItems: 'center'
                        }}
                    >
                        <InputGroup
                            leftIcon="array"
                            placeholder="Entity Type"
                            onChange={this.changeEntityTypeVar}
                            value={tempEntityType}
                            fill
                            large
                        />
                    </div>
                    
                    <div
                        style={{
                            width: '100%', height: '100%', marginTop: '10px', display: 'flex', alignItems: 'center'
                        }}
                    >
                        <div
                            style={{
                                width: '70%', height: '100%', display: 'flex', alignItems: 'center', color: '#16335B', fontSize: '16px'
                            }}
                        >
                            Max number of values (-1 for no limit):
                        </div>
                        <div
                            style={{
                                width: '30%', height: '100%', marginLeft: 'auto', display: 'flex', alignItems: 'center'
                            }}
                        >
                            <NumericInput
                                className="numeric-input"
                                clampValueOnBlur
                                minorStepSize={1}
                                onValueChange={this.changeMaxValues}
                                placeholder="Max"
                                stepSize={1}
                                majorStepSize={10}
                                min={-1}
                                defaultValue={+tempMaxValues.toFixed(0)}
                                fill
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            width: '300px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', marginTop: '10px'
                        }}
                    >
                        <BlueBorderButton
                            id="cancel"
                            type="button"
                            onClick={this.closePopup}
                        >
                            Cancel
                        </BlueBorderButton>
                        <BlueButton
                            id="save"
                            type="button"
                            onClick={this.closeConfirmPopup}
                        >
                            Save
                        </BlueButton>
                    </div>
                </SettingsDiv>
            </PortalOverflowOverlay>,
            <Alert key="delete-alert" style={{background: 'white', color: 'black'}} usePortal cancelButtonText="Cancel" confirmButtonText="Delete" icon="trash" intent="danger" isOpen={deletePopupOpen} onCancel={this.closeDelete} onConfirm={this.delete}>
                <p>
                    Are you sure you want to delete the component
                    <b style={{marginLeft: '5px'}}>{name}</b>
                    ?
                </p>
            </Alert>
        ]);
    }
}

const createRobotBattery = ({id, type, initialState, updateItem, deleteItem, cloneComponent, sources}) => (
    <RobotBattery
        id={id}
        type={type}
        initialState={initialState}
        updateItem={updateItem}
        deleteItem={deleteItem}
        cloneComponent={cloneComponent}
        availableSources={sources}
    />
);

export default createRobotBattery;
