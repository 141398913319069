/* eslint-disable max-len */
import {api, prefixUrl} from '../lib/api-adapter';

const generalApi = api.extend({prefixUrl: prefixUrl('general')});

export const getStatistics = () => generalApi.get('statistics').json();

export const getRestStatus = (url) => generalApi.get('test-url', {searchParams: {url}}).json();

export const getFiwareEntities = (url, headers, params, query) => generalApi.get('fiware-query', {
    searchParams: {
        url, headers, params, query
    }
}).json();

export const postFiwareEntities = (url, headers, params, body) => generalApi.post('fiware-query', {
    searchParams: {
        url, headers, params, body
    }
}).json();

export const getRestRequestStatus = (url, type, headers, body, params) => generalApi.get('test-url-request', {
    searchParams: {
        url, type, headers, body, params
    }
}).json();
