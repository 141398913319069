/* eslint-disable camelcase */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import {
    Alert,
    EditableText, InputGroup, Spinner, Popover, PopoverInteractionKind, PopoverPosition, Position, Menu, MenuItem, Icon, Tag, Button,
} from '@blueprintjs/core';
/* eslint-disable import/no-unresolved */
import ReactResizeDetector from 'react-resize-detector';
import { map } from 'rxjs/operators';
import { faMapMarkerAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    BlueBorderButton, BlueButton, OrangeButton, RedBorderButton, CustomButton
} from '../../../lib/buttons';
import { ToasterBottom } from '../../../lib/toaster';
import { findSource } from '../../../api/sources';
import { PortalOverflowOverlay } from '../../../lib/overlays';
import robotIconRed from '../../../assets/robot.png';
import robotIconGreen from '../../../assets/robotGreen.png';
import robotIconBlue from '../../../assets/robotBlue.png';
import { WhiteBlueScrollBar } from '../../../lib/scrollbar';
import { getFiwareEntities, postFiwareEntities } from '../../../api/general';

// const TextRow = styled.div`
//     width: 100%;
//     color: #3B3C3E;
//     background-color: #E1E1E1;
//     margin: 5px 5px 5px 5px;
//     border-radius: 5px;
//     padding: 5px;
//     class: column;
//     display: flex;
//     justify-content: space-between;
// `;

// const objectPath = require('object-path');
const mqtt = require('mqtt');

const FormHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: bold;
    color: #16335B;
`;

const FormSubHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    font-size: 18px;
    color: #16335B;
`;

const SettingsDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const GoalItemDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex; 
    justifyContent: center;
    alignItems: center;
    backgroundColor: lightgrey;
    borderRadius: 5px;
    border: 2px solid #16335B;
    :hover& {
        background: #66CC91!important;
    }
`;

const CustomCanvas = styled.canvas`
    z-index: 3;
    background: rgba(255, 255, 255, 0.2);
    cursor: crosshair;
`;

function constructMQTTPayload(recvTopic, message) {
    const topic = recvTopic.replaceAll('/', '.');
    const payload = {
        topic,
        message
    };

    return payload;
}

const ItemTypes = {
    slot: 'slot',
    pallet: 'pallet',
    parcel: 'parcel',
};

class NavigationRoute extends React.Component {
    constructor(props) {
        super(props);

        this.type = props.type;

        this.state = {
            spinnerOpen: false,
            id: props.id,
            user: props.user,
            owner: props.owner,
            name: props.initialState.name || 'Navigation Route',
            image: '',
            pose: { x: 50, y: 50, theta: 0 },
            path: [],
            annotations: [],
            map_width: 10,
            map_height: 10,
            resolution: 0.1,
            mapOrigin: { x: 0, y: 0 },
            source: props.initialState.source || 'Select source',
            varAPIKey: props.initialState.varAPIKey || '',
            varRobotType: props.initialState.varRobotType || '',
            varWarehouseType: props.initialState.varWarehouseType || '',
            varWarehouseId: props.initialState.varWarehouseId || '',
            pathTopic: props.initialState.pathTopic || '',
            width: 50,
            height: 50,
            orientation: 'horizontal',
            smallButtons: false,
            selectedItem: ItemTypes.parcel,
            closedButtons: false,
            imageWidth: 50,
            imageHeight: 50,
            canvasOpen: false,
            gotoCanvasOpen: false,
            selectItem: false,
            annotationNamePopupOpen: false,
            tempAnnotationName: '',
            tempSelectedRobot: null,
            tempSelectedGoal: null,
            deleteGoalPopupOpen: false,
            deleteAnnotationPopupOpen: false,
            selectAnnotationPopupOpen: false,
            selectItemPopopOpen: false,
            imagePopupOpen: false,
            previousImageWidth: 50,
            previousImageHeight: 50,
            mousePos: { x: 0, y: 0 },
            robots: {},
            parcels: [],
            tempSelectedParcel: null,
            pallets: [],
            tempSelectedItem: { slot: null, pallet: null, parcel: null },
            slots: [],
        };
        this.rxStomp = null;
        this.mqttClient = null;
        this.canvas = React.createRef();
        this.ctx = null;
        this.gotoCanvas = React.createRef();
        this.slotCanvas = React.createRef();
        this.gotoCtx = null;
        this.tempPoint = null;
        this.tempDeleteAnnotation = null;
        this.tempSelectedAnnotation = null;
        this.is_annotation = true;

        this.changeSpinner = this.changeSpinner.bind(this);
        this.messageReceivedPose = this.messageReceivedPose.bind(this);
        this.messageReceivedPath = this.messageReceivedPath.bind(this);
        this.connectStompSource = this.connectStompSource.bind(this);
        this.connectMqttSource = this.connectMqttSource.bind(this);
        this.connectToTopic = this.connectToTopic.bind(this);
        this.resize = this.resize.bind(this);
        this.annotate = this.annotate.bind(this);
        this.goToPlace = this.goToPlace.bind(this);
        this.goToPoint = this.goToPoint.bind(this);
        this.openItemSelection = this.openItemSelection.bind(this);
        this.switchItemSelection = this.switchItemSelection.bind(this);
        this.cancelGoal = this.cancelGoal.bind(this);
        this.closeAnnotate = this.closeAnnotate.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUpGoto = this.onMouseUpGoto.bind(this);
        this.addAnnotation = this.addAnnotation.bind(this);
        this.deleteAnnotation = this.deleteAnnotation.bind(this);
        this.sendPointGoal = this.sendPointGoal.bind(this);
        this.closeGotoCanvas = this.closeGotoCanvas.bind(this);
        this.changeAnnotationName = this.changeAnnotationName.bind(this);
        this.cancelAnnotation = this.cancelAnnotation.bind(this);
        this.sendAnnotation = this.sendAnnotation.bind(this);
        this.openCancelGoal = this.openCancelGoal.bind(this);
        this.closeCancelGoal = this.closeCancelGoal.bind(this);
        this.openDeleteAnnotation = this.openDeleteAnnotation.bind(this);
        this.closeDeleteAnnotation = this.closeDeleteAnnotation.bind(this);
        this.selectAnnotation = this.selectAnnotation.bind(this);
        this.cancelSelectAnnotation = this.cancelSelectAnnotation.bind(this);
        this.renderAvailableRobots = this.renderAvailableRobots.bind(this);
        this.renderAvailableRobotsPerItem = this.renderAvailableRobotsPerItem.bind(this);
        this.renderAvailableGoals = this.renderAvailableGoals.bind(this);
        this.renderGoalInfo = this.renderGoalInfo.bind(this);
        this.openImage = this.openImage.bind(this);
        this.closeImage = this.closeImage.bind(this);
        this.onCoordChange = this.onCoordChange.bind(this);
        this.placeAnnotationTag = this.placeAnnotationTag.bind(this);
        this.getEntityAttributes = this.getEntityAttributes.bind(this);
        this.updateEntityAttribute = this.updateEntityAttribute.bind(this);
        this.onRobotSelect = this.onRobotSelect.bind(this);
        this.onMouseMoveGoTo = this.onMouseMoveGoTo.bind(this);
        this.selectGoal = this.selectGoal.bind(this);
        this.selectItemGoal = this.selectItemGoal.bind(this);
        this.aGoalIsFocused = this.aGoalIsFocused.bind(this);
        this.closeItemSelection = this.closeItemSelection.bind(this);
        this.noGoalFocused = this.noGoalFocused.bind(this);
        this.renderItems = this.renderItems.bind(this);
        this.onItemSelection = this.onItemSelection.bind(this);
        this.sendSelectedItem = this.sendSelectedItem.bind(this);
    }

    async componentDidMount() {
        const { id, robots, varRobotType, varWarehouseType, varWarehouseId } = this.state;

        this.connectToTopic();

        this.getEntityAttributes(varWarehouseType, varWarehouseId).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                const [responseData] = response.data;

                if (responseData) {
                    if ('blueprint' in responseData) {
                        this.setState({ image: `data:image/jpg;base64,${responseData.blueprint.value}` }, () => {
                            setTimeout(() => {
                                const imageDiv = document.getElementById(`navigationRouteDiv_${id}`);
                                this.resize(imageDiv.offsetWidth, imageDiv.offsetHeight);

                                // parse annotations, width, height, resolution, origin
                                if ('dimensions' in responseData && 'annotations' in responseData) {
                                    const { width, resolution, height } = responseData.dimensions.value;

                                    const mapOrigin = {
                                        x: 0,
                                        y: (height * resolution),
                                    };

                                    const annotationsData = [];

                                    if (responseData.annotations.value) {
                                        Object.keys(responseData.annotations.value).forEach((p) => {
                                            const pX = responseData.annotations.value[p].pose.x;
                                            const pY = responseData.annotations.value[p].pose.y;
                                            annotationsData.push({ x: pX, y: pY, name: p });
                                        });
                                    }

                                    this.setState({
                                        annotations: annotationsData,
                                        map_width: width,
                                        map_height: height,
                                        mapOrigin,
                                        resolution
                                    });
                                }
                            }, 200);
                        });
                    }
                }
            }
        });

        const warehouseRef = `ref${varWarehouseType}`;
        this.getEntityAttributes(varRobotType, null, { [warehouseRef]: `urn:ngsi-ld:${varWarehouseType}:${varWarehouseId}` }).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                const responseData = response.data;
                if (responseData) {
                    responseData.forEach((r) => {
                        let path = [];
                        if (r.path.value !== null) {
                            path = r.path.value.points;
                        }

                        const target = r.target.value;
                        if (target !== null) {
                            if (Object.keys(r.target.metadata).length !== 0) {
                                target.timestamp = Date.parse(r.target.metadata.TimeInstant.value);
                            }
                        }

                        const robotId = r.id.split(':');
                        robots[`${robotId[2]}${robotId[3]}`] = {
                            type: r.robotClass.value,
                            origin: r.origin.value,
                            pose: r.pose.value,
                            state: r.state.value,
                            path,
                            target,

                        };

                        this.setState({ robots });
                    });
                }
            }
        });

        // Get all warehouse rooms
        let rooms;
        await this.getEntityAttributes('Room', null, { [warehouseRef]: `urn:ngsi-ld:${varWarehouseType}:${varWarehouseId}` }).then((roomResponse) => {
            if (roomResponse.status >= 200 && roomResponse.status < 300) {
                const roomResponseData = roomResponse.data;

                if (roomResponseData) {
                    rooms = roomResponseData;
                }
            }
        });

        // Get all racks of each warehouse room
        let racks = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const room of rooms) {
            // eslint-disable-next-line no-await-in-loop
            const rackResponse = await this.getEntityAttributes('Rack', null, { refRoom: room.id });

            if (rackResponse.status >= 200 && rackResponse.status < 300) {
                const rackResponseData = rackResponse.data;

                if (rackResponseData) {
                    racks = racks.concat(rackResponseData);
                }
            }
        }

        // Get all racks of each warehouse room
        let shelves = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const rack of racks) {
            // eslint-disable-next-line no-await-in-loop
            const shelfResponse = await this.getEntityAttributes('Shelf', null, { refRack: rack.id });

            if (shelfResponse.status >= 200 && shelfResponse.status < 300) {
                const shelfResponseData = shelfResponse.data;

                if (shelfResponseData) {
                    shelves = shelves.concat(shelfResponseData);
                }
            }
        }

        // Get all racks of each warehouse room
        let slots = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const shelf of shelves) {
            // eslint-disable-next-line no-await-in-loop
            const slotResponse = await this.getEntityAttributes('Slot', null, { refShelf: shelf.id });

            if (slotResponse.status >= 200 && slotResponse.status < 300) {
                const slotResponseData = slotResponse.data;

                if (slotResponseData) {
                    slots = slots.concat(slotResponseData);
                }
            }
        }

        this.setState({ slots });

        // Get all racks of each warehouse room
        let pallets = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const slot of slots) {
            // eslint-disable-next-line no-await-in-loop
            const palletResponse = await this.getEntityAttributes('Pallet', null, { refSlot: slot.id });

            if (palletResponse.status >= 200 && palletResponse.status < 300) {
                const palletResponseData = palletResponse.data;

                if (palletResponseData) {
                    pallets = pallets.concat(palletResponseData);
                }
            }
        }

        this.setState({ pallets });

        // Get all racks of each warehouse room
        let parcels = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const slot of slots) {
            // eslint-disable-next-line no-await-in-loop
            const parcelResponse = await this.getEntityAttributes('Parcel', null, { refSlot: slot.id });

            if (parcelResponse.status >= 200 && parcelResponse.status < 300) {
                const parcelResponseData = parcelResponse.data;

                if (parcelResponseData) {
                    parcels = parcels.concat(parcelResponseData);
                }
            }
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const pallet of pallets) {
            // eslint-disable-next-line no-await-in-loop
            const parcelResponse = await this.getEntityAttributes('Parcel', null, { refPallet: pallet.id });

            if (parcelResponse.status >= 200 && parcelResponse.status < 300) {
                const parcelResponseData = parcelResponse.data;
                if (parcelResponseData) {
                    parcels = parcels.concat(parcelResponseData);
                }
            }
        }

        this.setState({ parcels });
    }

    componentWillUnmount() {
        if (this.rxStomp !== null) {
            this.rxStomp.deactivate();
        }
        if (this.mqttClient !== null) {
            this.mqttClient.end();
        }
    }

    onMouseMove(e) {
        const { map_width, map_height, resolution } = this.state;
        if (this.canvas.current) {
            const { left, right, top, bottom } = this.canvas.current.getBoundingClientRect();
            if (!(e.pageX < left || e.pageX > right || e.pageY < top || e.pageY > bottom)) {
                const point = {
                    x: e.offsetX * (map_width / (right - left)) * resolution,
                    y: e.offsetY * (map_height / (bottom - top)) * resolution
                };

                this.setState({ mousePos: point });
            }
        }
    }

    onMouseUp(e) {
        const { map_width, map_height, resolution } = this.state;
        if (this.canvas.current) {
            const { left, right, top, bottom } = this.canvas.current.getBoundingClientRect();
            if (!(e.pageX < left || e.pageX > right || e.pageY < top || e.pageY > bottom)) {
                const point = {
                    x: e.offsetX * (map_width / (right - left)) * resolution,
                    y: e.offsetY * (map_height / (bottom - top)) * resolution
                };
                this.setState({ canvasOpen: false });
                document.removeEventListener('mouseup', this.onMouseUp, false);
                document.removeEventListener('mousemove', this.onMouseMove, false);
                this.addAnnotation(point);
            }
        }
    }

    onMouseUpGoto(e) {
        if (this.gotoCanvas.current) {
            const { left, right, top, bottom } = this.gotoCanvas.current.getBoundingClientRect();
            if (!(e.pageX < left || e.pageX > right || e.pageY < top || e.pageY > bottom)) {
                document.removeEventListener('mouseup', this.onMouseUpGoto, false);
                document.removeEventListener('mousemove', this.onMouseMoveGoTo, false);
                this.selectGoal();
            }
        }
    }

    onMouseMoveGoTo(e) {
        const { map_width, map_height, resolution } = this.state;
        if (this.gotoCanvas.current) {
            const { left, right, top, bottom } = this.gotoCanvas.current.getBoundingClientRect();
            if (!(e.pageX < left || e.pageX > right || e.pageY < top || e.pageY > bottom)) {
                const point = {
                    x: e.offsetX * (map_width / (right - left)) * resolution,
                    y: e.offsetY * (map_height / (bottom - top)) * resolution
                };

                this.setState({ mousePos: point });
            }
        }
    }

    onCoordChange(type, event) {
        const { mousePos, imageWidth, imageHeight } = this.state;

        const x = (type === 'x' ? event.target.value : mousePos.x);
        const y = (type === 'y' ? event.target.value : mousePos.y);

        const newMousePos = {
            x: Math.max(Math.min(x, imageWidth), 0),
            y: Math.max(Math.min(y, imageHeight), 0),
        };

        this.setState({ mousePos: newMousePos });
    }

    onRobotSelect(robotId) {
        this.setState({ tempSelectedRobot: robotId });
    }

    onItemSelection(index) {
        const { selectedItem, tempSelectedItem } = this.state;
        tempSelectedItem[selectedItem] = index;
        this.setState({ tempSelectedItem });
    }

    async getEntityAttributes(entityType, entityId = null, query = {}) {
        const { source, owner, user } = this.state;
        let response = await findSource(source, owner, user);

        if (response.success) {
            const url = response.source.orionUrl;
            const formattedUrl = (url.startsWith('http://') || url.startsWith('https://')) ? url : `http://${url}`;

            const params = { type: entityType };

            if (entityId) {
                params.id = entityId;
            }

            const headers = JSON.stringify({
                'Fiware-service': 'openiot',
                'Fiware-servicepath': '/'
            });

            try {
                response = await getFiwareEntities(formattedUrl, headers, JSON.stringify(params), JSON.stringify(query));
                return response;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log('Error: ', error);
            }
        }

        return {};
    }

    async updateEntityAttribute(entityType, entityId, entityAttr, updatedValue) {
        const { source, owner, user } = this.state;
        let response = await findSource(source, owner, user);

        if (response.success) {
            const url = response.source.orionUrl;
            const formattedUrl = (url.startsWith('http://') || url.startsWith('https://')) ? url : `http://${url}`;

            const headers = JSON.stringify({
                'Fiware-service': 'openiot',
                'Fiware-servicepath': '/',
                'Content-Type': 'application/json'
            });

            const params = JSON.stringify({
                type: entityType,
                id: entityId,
                attrs: entityAttr
            });

            const body = JSON.stringify({ value: updatedValue });

            try {
                response = await postFiwareEntities(formattedUrl, headers, params, body);
                return response;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log('Error: ', error);
            }
        }

        return {};
    }

    closeItemSelection() {
        this.setState({
            tempSelectedItem: { slot: null, pallet: null, parcel: null },
            selectItem: false
        });
    }

    switchItemSelection(value) {
        this.setState({ selectedItem: value });
    }

    placeAnnotationTag() {
        const { mousePos } = this.state;
        if (this.canvas.current) {
            this.setState({ canvasOpen: false });
            document.removeEventListener('mouseup', this.onMouseUp, false);
            document.removeEventListener('mousemove', this.onMouseMove, false);
            this.addAnnotation(mousePos);
        }
    }

    changeSpinner(value) {
        this.setState({ spinnerOpen: value });
    }

    messageReceivedPose(payload) {
        const { robots } = this.state;
        const { topic, message } = payload;

        try {
            const robotId = topic.split(/[.]+/)[2];

            if (robotId in robots) {
                robots[robotId].pose = {
                    x: message.x,
                    y: message.y,
                    th: message.th,
                };
            }

            this.setState({ robots });
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(`Error occured when receiving path: ${err}`);
        }
    }

    messageReceivedPath(payload) {
        const { robots } = this.state;
        const { topic, message } = payload;

        try {
            const robotId = topic.split(/[.]+/)[2];

            if (robotId in robots) {
                robots[robotId].path = message.points;

                // set target as the last path point
                const last_index = message.points.length - 1;
                robots[robotId].target = {
                    x: message.points[last_index][0],
                    y: message.points[last_index][1],
                };
            }

            this.setState({ robots });
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(`Error occured when receiving path: ${err}`);
        }
    }

    connectStompSource(source) {
        const { varAPIKey, name } = this.state;
        const poseTopic = `/${varAPIKey}/+/attrs/pose`;
        const pathTopic = `/${varAPIKey}/+/attrs/path`;

        try {
            const stompConfig = {
                connectHeaders: {
                    login: source.login,
                    passcode: source.passcode,
                    host: source.vhost
                },
                brokerURL: source.url
            };
            // eslint-disable-next-line no-undef
            this.rxStomp = new RxStomp.RxStomp();
            this.rxStomp.configure(stompConfig);
            this.rxStomp.activate();
            const initialReceiptId = `${name}_start`;

            this.rxStomp.watchForReceipt(initialReceiptId, () => {
                this.changeSpinner(false);
            });
            this.rxStomp.watch(`/topic/${poseTopic}`).pipe(map((message) => JSON.parse(message.body))).subscribe((payload) => {
                this.messageReceivedPose(payload);
            });
            this.rxStomp.watch(`/topic/${pathTopic}`).pipe(map((message) => JSON.parse(message.body))).subscribe((payload) => {
                this.messageReceivedPath(payload);
            });
        } catch { }
    }

    connectMqttSource(source) {
        const { varAPIKey } = this.state;
        const poseTopic = `/${varAPIKey}/+/attrs/pose`;
        const pathTopic = `/${varAPIKey}/+/attrs/path`;

        try {
            const config = {
                username: source.login,
                password: source.passcode
            };

            this.mqttClient = mqtt.connect(source.url, config);
            this.mqttClient.on('connect', () => {
                this.mqttClient.subscribe(poseTopic, (err) => {
                    if (!err) {
                        this.changeSpinner(false);
                    }
                });
                this.mqttClient.subscribe(pathTopic, (err) => {
                    if (!err) {
                        this.changeSpinner(false);
                    }
                });
            });

            this.mqttClient.on('message', (recvTopic, message) => {
                const topic = recvTopic.split('/')[4];
                if (topic === 'pose') {
                    this.messageReceivedPose(constructMQTTPayload(recvTopic, JSON.parse(message.toString())));
                } else if (topic === 'path') {
                    this.messageReceivedPath(constructMQTTPayload(recvTopic, JSON.parse(message.toString())));
                }
            });
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(`Caugh error ${err} when trying to connect to MQTT Broker!`);
        }
    }

    async connectToTopic() {
        const { user, owner, name, source } = this.state;
        const response = await findSource(source, owner, user);
        if (response.success) {
            if (response.source.type === 'stomp') {
                this.connectStompSource(response.source);
            } else {
                this.connectMqttSource(response.source);
            }
        } else {
            ToasterBottom.show({
                intent: 'danger',
                message: response.message || `There was a problem trying to find the source for ${name}`
            });
        }
    }

    resize(width, height) {
        const { id } = this.state;
        const closedButtons = ((width > height && height < 80) || (width < height && width < 100));
        const img = document.getElementById(`navigationRouteImage_${id}`);
        const ratio = img.naturalWidth / img.naturalHeight;
        let w = img.height * ratio;
        let h = img.height;
        if (w > img.width) {
            w = img.width;
            h = img.width / ratio;
        }
        this.setState({
            width,
            height,
            orientation: (width > height) ? 'horizontal' : 'vertical',
            smallButtons: (((width < height && width < 300) || (width > height && height < 160)) && !closedButtons),
            closedButtons,
            imageWidth: w,
            imageHeight: h
        });
    }

    annotate() {
        this.setState({ canvasOpen: true }, () => {
            document.addEventListener('mousemove', this.onMouseMove, false);
            document.addEventListener('mouseup', this.onMouseUp, false);
        });
    }

    goToPlace() {
        const { annotations, varAPIKey, tempSelectedRobot, robots, resolution, map_height } = this.state;
        const newX = annotations[this.tempSelectedAnnotation].x * resolution;
        const newY = annotations[this.tempSelectedAnnotation].y * resolution;

        // Publish to Topic the new Robots target
        const body = JSON.stringify({
            x: (newX - robots[tempSelectedRobot].origin.x),
            y: (newY + robots[tempSelectedRobot].origin.y - map_height * resolution),
        });

        try {
            if (this.rxStomp !== null) {
                this.rxStomp.publish({ destination: `/topic//${varAPIKey}/${tempSelectedRobot}/attrs/target`, body });
            } else if (this.mqttClient !== null) {
                // this.mqttClient.publish(`/${varAPIKey}/${tempSelectedRobot}/attrs/state`, JSON.stringify({val: 'INITIALIZING'}));
                this.mqttClient.publish(`/${varAPIKey}/${tempSelectedRobot}/attrs/target`, body);
            }

            robots[tempSelectedRobot].state = 'INITIALIZING';
            robots[tempSelectedRobot].target = {
                x: (newX - robots[tempSelectedRobot].origin.x),
                y: (newY - robots[tempSelectedRobot].origin.y),
                timestamp: Date.now()
            };

            this.setState({ selectAnnotationPopupOpen: false, tempSelectedRobot: null, robots });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Error in goToPlace:', e);
        }
    }

    goToPoint() {
        this.tempSelectedAnnotation = null;
        this.setState({ gotoCanvasOpen: true }, () => {
            document.addEventListener('mousemove', this.onMouseMoveGoTo, false);
            document.addEventListener('mouseup', this.onMouseUpGoto, false);
        });
    }

    openItemSelection() {
        this.setState({ selectItem: true });
    }

    cancelGoal() {
        const { varAPIKey, robots } = this.state;

        const body = JSON.stringify({
            target: null,
            metadata: {}
        });

        try {
            if (this.rxStomp !== null) {
                this.rxStomp.publish({ destination: `/topic//${varAPIKey}/${this.tempGoalToCancel}/attrs`, body });
            } else if (this.mqttClient !== null) {
                this.mqttClient.publish(`/${varAPIKey}/${this.tempGoalToCancel}/attrs`, body);
            }

            robots[this.tempGoalToCancel].target = null;
            this.setState({ deleteGoalPopupOpen: false, robots });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Error in cancelGoal:', e);
        }
    }

    closeAnnotate() {
        this.setState({ canvasOpen: false });
        document.removeEventListener('mouseup', this.onMouseUp, false);
        document.removeEventListener('mousemove', this.onMouseMove, false);
    }

    addAnnotation(point) {
        this.tempPoint = point;
        this.setState({ annotationNamePopupOpen: true });
    }

    selectGoal() {
        this.setState({ gotoCanvasOpen: false });
        this.setState({ selectAnnotationPopupOpen: true });
        document.removeEventListener('mousemove', this.onMouseMoveGoTo, false);
        document.removeEventListener('mouseup', this.onMouseUpGoto, false);
    }

    selectItemGoal() {
        this.setState({ selectItemPopopOpen: true });
    }

    deleteAnnotation() {
        // get All annotations
        const { varWarehouseType, varWarehouseId, annotations } = this.state;

        try {
            this.getEntityAttributes(varWarehouseType, varWarehouseId).then((getResponse) => {
                if (getResponse.status >= 200 && getResponse.status < 300) {
                    const [responseData] = getResponse.data;

                    try {
                        // Delete the copy record
                        delete responseData.annotations.value[annotations[this.tempDeleteAnnotation].name];

                        this.updateEntityAttribute(varWarehouseType, varWarehouseId, 'annotations', responseData.annotations.value).then((postResponse) => {
                            try {
                                if (postResponse.status >= 200 && postResponse.status < 300) {
                                    annotations.splice(this.tempDeleteAnnotation, 1);
                                }
                            } catch (err) {
                                // eslint-disable-next-line no-console
                                console.log(`Caught error ${err} when deleting the gui annotation !`);
                            }

                            this.tempSelectedAnnotation = null;
                            this.tempDeleteAnnotation = null;
                            this.setState({ annotations });
                        });
                    } catch (err) {
                        // eslint-disable-next-line no-console
                        console.log(`Caught error ${err} when deleting the fiware annotation !`);
                        this.tempSelectedAnnotation = null;
                        this.tempDeleteAnnotation = null;
                    }
                }
            });
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(`Caught error ${err} when pulling data for a deletion of an annotation!`);
            this.tempSelectedAnnotation = null;
            this.tempDeleteAnnotation = null;
        }

        this.setState({ deleteAnnotationPopupOpen: false });
    }

    sendPointGoal() {
        const { mousePos, varAPIKey, tempSelectedRobot, robots } = this.state;
        const newX = mousePos.x;
        const newY = mousePos.y;

        // Publish to Topic the new Robots target
        const body = JSON.stringify({
            x: (newX - robots[tempSelectedRobot].origin.x),
            y: (newY - robots[tempSelectedRobot].origin.y),
        });

        try {
            if (this.rxStomp !== null) {
                this.rxStomp.publish({ destination: `/topic//${varAPIKey}/${tempSelectedRobot}/attrs/target`, body });
            } else if (this.mqttClient !== null) {
                this.mqttClient.publish(`/${varAPIKey}/${tempSelectedRobot}/attrs/state`, JSON.stringify({ val: 'INITIALIZING' }));
                this.mqttClient.publish(`/${varAPIKey}/${tempSelectedRobot}/attrs/target`, body);
            }

            robots[tempSelectedRobot].state = 'INITIALIZING';
            robots[tempSelectedRobot].target = {
                x: (newX - robots[tempSelectedRobot].origin.x),
                y: (newY - robots[tempSelectedRobot].origin.y),
                timestamp: Date.now()
            };

            this.setState({ selectAnnotationPopupOpen: false, tempSelectedRobot: null, robots });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Error in sendPointGoal:', e);
        }
    }

    sendSelectedItem(itemId) {
        const { varAPIKey, tempSelectedRobot, robots, selectedItem } = this.state;

        const body = JSON.stringify({
            type: selectedItem,
            id: itemId,
        });

        try {
            if (this.rxStomp !== null) {
                this.rxStomp.publish({ destination: `/topic//${varAPIKey}/${tempSelectedRobot}/attrs/goal/entity`, body });
            } else if (this.mqttClient !== null) {
                this.mqttClient.publish(`/${varAPIKey}/${tempSelectedRobot}/attrs/state`, JSON.stringify({ val: 'INITIALIZING' }));
                this.mqttClient.publish(`/${varAPIKey}/${tempSelectedRobot}/attrs/goal/entity`, body);
            }

            robots[tempSelectedRobot].state = 'INITIALIZING';
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Error in sendSelectedItem:', e);
        }

        this.setState({
            selectAnnotationPopupOpen: false,
            selectItem: false,
            selectItemPopopOpen: false,
            tempSelectedItem: { slot: null, pallet: null, parcel: null },
            tempSelectedRobot: null,
            robots
        });
    }

    closeGotoCanvas() {
        this.setState({ gotoCanvasOpen: false });
        document.removeEventListener('mousemove', this.onMouseMoveGoTo, false);
        document.removeEventListener('mouseup', this.onMouseUpGoto, false);
    }

    changeAnnotationName(event) {
        this.setState({ tempAnnotationName: event.target.value });
    }

    cancelAnnotation() {
        this.tempPoint = null;
        this.setState({ tempAnnotationName: '', annotationNamePopupOpen: false });
    }

    sendAnnotation() {
        const { tempAnnotationName, annotations, resolution, varWarehouseType, varWarehouseId } = this.state;

        // Convert meters to image pixels
        const newX = (this.tempPoint.x / resolution);
        const newY = (this.tempPoint.y / resolution);

        // pull annotations and update them locally and remotly
        try {
            this.getEntityAttributes(varWarehouseType, varWarehouseId).then((getResponse) => {
                if (getResponse.status >= 200 && getResponse.status < 300) {
                    const [responseData] = getResponse.data;

                    try {
                        // This to update the fiware record
                        responseData.annotations.value[tempAnnotationName] = {
                            pose: {
                                x: newX,
                                y: newY
                            }
                        };

                        this.updateEntityAttribute(varWarehouseType, varWarehouseId, 'annotations', responseData.annotations.value).then((postResponse) => {
                            try {
                                if (postResponse.status >= 200 && postResponse.status < 300) {
                                    // Save map pixels                                
                                    annotations.push({ x: newX, y: newY, name: tempAnnotationName });
                                }
                            } catch (err) {
                                // eslint-disable-next-line no-console
                                console.log(`Caught error ${err} when adding the gui annotation !`);
                            }

                            this.cancelAnnotation();
                            this.setState({ annotations });
                        });
                    } catch (err) {
                        // eslint-disable-next-line no-console
                        console.log(`Caught error ${err} when adding the fiware annotation !`);
                        this.cancelAnnotation();
                    }
                }
            });
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(`Caught error ${err} when pulling data for a addition of an annotation!`);
            this.cancelAnnotation();
        }
    }

    aGoalIsFocused(r) {
        this.setState({ tempSelectedGoal: r });
    }

    noGoalFocused() {
        this.setState({ tempSelectedGoal: null });
    }

    openCancelGoal(ind) {
        this.tempGoalToCancel = ind;
        this.setState({ deleteGoalPopupOpen: true });
    }

    closeCancelGoal() {
        this.tempGoalToCancel = null;
        this.setState({ deleteGoalPopupOpen: false });
    }

    openDeleteAnnotation(ind) {
        this.setState({ tempSelectedRobot: null });
        this.tempDeleteAnnotation = ind;
        this.setState({ deleteAnnotationPopupOpen: true, selectAnnotationPopupOpen: false });
    }

    closeDeleteAnnotation() {
        this.tempDeleteAnnotation = null;
        this.setState({ deleteAnnotationPopupOpen: false });
    }

    selectAnnotation(ind) {
        this.tempSelectedAnnotation = ind;
        this.setState({ selectAnnotationPopupOpen: true });
    }

    cancelSelectAnnotation() {
        this.setState({ tempSelectedRobot: null });
        this.tempSelectedAnnotation = null;
        this.setState({ selectAnnotationPopupOpen: false });
        this.setState({ selectItemPopopOpen: false });
    }

    openImage() {
        this.setState({ imagePopupOpen: true });
    }

    closeImage() {
        this.setState({ imagePopupOpen: false });
    }

    renderItems() {
        const { pallets, slots, parcels, selectedItem, tempSelectedItem } = this.state;

        let data;
        switch (selectedItem) {
            case ItemTypes.slot:
                data = slots;
                break;
            case ItemTypes.pallet:
                data = pallets;
                break;
            case ItemTypes.parcel:
                data = parcels;
                break;
            default:
                data = [];
                break;
        }

        const listItems = data.map((key, index) => (
            <div
                style={{
                    width: '100%',
                    height: '40px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Button
                    intent={tempSelectedItem[selectedItem] === index ? 'success' : 'none'}
                    style={{ width: '100%', marginLeft: '3%' }}
                    text={key.id}
                    onClick={() => this.onItemSelection(index)}
                />
            </div>
        ));

        return listItems;
    }

    renderGoalInfo(r) {
        const { robots } = this.state;

        // calculate targets
        const targetX = robots[r].target.x;
        const targetY = robots[r].target.y;

        // caluclate datetime
        const date = (new Date(robots[r].target.timestamp)).toLocaleString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
        const time = (new Date(robots[r].target.timestamp)).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric' });

        // calculate robot's distance from target
        let distance = 0;
        if (robots[r].pose !== null) {
            const a = (robots[r].target.x - robots[r].pose.x);
            const b = (robots[r].target.y - robots[r].pose.y);
            distance = Math.sqrt(a * a + b * b);
        }

        // calculate path's total length
        let lendth = 0;
        const tempPose = {
            x: robots[r].pose.x,
            y: robots[r].pose.y,
        };

        robots[r].path.forEach((p) => {
            const x = (p[0] - tempPose.x);
            const y = (p[1] - tempPose.y);
            lendth += Math.sqrt(x * x + y * y);

            [tempPose.x, tempPose.y] = p;
        });

        const goalInfo = (
            <div
                style={{
                    width: '230px', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px'
                }}
            >
                <FormHeader
                    style={{ fontSize: '20px' }}
                >
                    {`${r}'s info`}
                </FormHeader>
                <div
                    style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                    <b>Date:</b>
                    <b>{`${date}`}</b>
                </div>
                <div
                    style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                    <b>Time:</b>
                    <b>{`${time}`}</b>
                </div>
                <div
                    style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                    <b>Target X:</b>
                    <b>{`${targetX.toFixed(2)} m`}</b>
                </div>
                <div
                    style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                    <b>Target Y:</b>
                    <b>{`${targetY.toFixed(2)} m`}</b>
                </div>
                <div
                    style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                    <b>Target Distance:</b>
                    <b>{`${distance.toFixed(2)} m`}</b>
                </div>
                <div
                    style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                    <b>Path Length:</b>
                    <b>{`${lendth.toFixed(2)} m`}</b>
                </div>
            </div>
        );

        return goalInfo;
    }

    renderAvailableRobots() {
        const { robots } = this.state;

        const availableRobots = (
            <Menu>
                {Object.keys(robots).map((robot) => (
                    <MenuItem icon="feed" text={`${robot}`} fill onClick={() => this.onRobotSelect(robot)} />
                ))}
            </Menu>
        );

        return availableRobots;
    }

    renderAvailableRobotsPerItem() {
        const { robots, selectedItem } = this.state;

        const tempRobots = { ...robots };
        if (selectedItem === 'pallet') {
            Object.keys(tempRobots).forEach((r) => {
                if (tempRobots[r].type.val === 'm-bot') {
                    delete tempRobots[r];
                }
            });
        }

        const availableRobots = (
            <Menu>
                {Object.keys(tempRobots).map((robot) => (
                    <MenuItem icon="feed" text={`${robot}`} fill onClick={() => this.onRobotSelect(robot)} />
                ))}
            </Menu>
        );

        return availableRobots;
    }

    renderAvailableGoals() {
        const { robots } = this.state;

        const sorted_robots = Object.keys(robots).filter((r) => robots[r].target !== null).sort((r1, r2) => (robots[r2].target.timestamp - robots[r1].target.timestamp));

        const goals = sorted_robots.map((r, i) => (
            <Popover
                fill
                content={this.renderGoalInfo(r)}
                position={PopoverPosition.RIGHT}
                interactionKind={PopoverInteractionKind.HOVER}
                onOpening={() => this.aGoalIsFocused(r)}
                onClose={() => this.noGoalFocused()}
            >
                <GoalItemDiv
                    style={{
                        width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px', marginRight: '3px', backgroundColor: 'lightgrey', borderRadius: '5px', border: '2px solid #16335B'
                    }}
                >
                    <div
                        style={{
                            width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1px', marginRight: '2px'
                        }}
                    >
                        <div
                            style={{
                                height: '100%',
                                width: '40%',
                                padding: '4px',
                                backgroundColor: 'white',
                                borderRadius: '5px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <b>{`${r}`}</b>
                        </div>

                        <div
                            style={{
                                height: '100%',
                                width: '8%',
                                padding: '4px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Icon icon="arrow-right" />
                        </div>

                        <div
                            style={{
                                height: '100%',
                                width: '40%',
                                padding: '4px',
                                backgroundColor: 'white',
                                borderRadius: '5px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <b>{`Goal <${i}>`}</b>
                        </div>
                    </div>
                    <CustomButton type="button" height="8px" width="8px" icon="small-cross" onClick={() => (this.openCancelGoal(r))} />
                </GoalItemDiv>
            </Popover>
        ));

        return goals;
    }

    render() {
        const { spinnerOpen, id, name, image, robots, slots, parcels, pallets, mapOrigin, annotations, width, height, orientation, smallButtons, closedButtons, selectedItem, imageWidth, imageHeight, map_width, map_height, resolution, canvasOpen, gotoCanvasOpen, selectItem, annotationNamePopupOpen, tempAnnotationName, deleteGoalPopupOpen, deleteAnnotationPopupOpen, tempSelectedRobot, tempSelectedGoal, tempSelectedItem, selectAnnotationPopupOpen, selectItemPopopOpen, imagePopupOpen, mousePos } = this.state;

        let allowGoals = true;
        let distance = 0;
        // eslint-disable-next-line no-restricted-syntax
        Object.keys(robots).forEach((r) => {
            const a = (robots[r].target.x - robots[r].pose.x);
            const b = (robots[r].target.y - robots[r].pose.y);

            distance = Math.sqrt(a * a + b * b);

            if (distance < 0.35) {
                allowGoals = false;
            }

            if (robots[r].target.x === 0 && robots[r].target.y === 0) {
                allowGoals = false;
            }
        });

        // Position of robots
        const robotPoses = Object.keys(robots).map((k) => ({
            x: (((mapOrigin.x + robots[k].origin.x + robots[k].pose.x) * (imageWidth / map_width)) / resolution),
            y: (((mapOrigin.y - robots[k].origin.y - robots[k].pose.y) * (imageHeight / map_height)) / resolution),
            theta: (-robots[k].pose.th) % (2 * Math.PI),
            sizeX: Math.min(imageWidth, imageHeight) / ((tempSelectedGoal === k) ? 12 : 15),
            sizeY: Math.min(imageWidth, imageHeight) / ((tempSelectedGoal === k) ? 12 : 15),
            color: tempSelectedGoal === k ? robotIconGreen : (robots[k].type.val === 'm-bot' ? robotIconRed : robotIconBlue),
        }));

        // Position of selected robot's goal
        const selectedRobotTarget = {
            x: tempSelectedGoal ? (((mapOrigin.x + robots[tempSelectedGoal].origin.x + robots[tempSelectedGoal].target.x) * (imageWidth / map_width)) / resolution) : 0,
            y: tempSelectedGoal ? (((mapOrigin.y - robots[tempSelectedGoal].origin.y + robots[tempSelectedGoal].target.y) * (imageHeight / map_height)) / resolution) : 0,
            sizeX: Math.min(imageWidth, imageHeight) / 30,
            sizeY: Math.min(imageWidth, imageHeight) / 30,
        };

        let selectedItemId = -1;
        switch (selectedItem) {
            case ItemTypes.slot:
                selectedItemId = slots[tempSelectedItem.slot];
                break;
            case ItemTypes.pallet:
                selectedItemId = pallets[tempSelectedItem.pallet];
                break;
            case ItemTypes.parcel:
                selectedItemId = parcels[tempSelectedItem.parcel];
                break;
            default:
                break;
        }

        if (selectedItemId) {
            selectedItemId = selectedItemId.id;
        }


        const robotsPerItem = { ...robots };
        if (selectedItem === 'pallet') {
            Object.keys(robotsPerItem).forEach((r) => {
                if (robotsPerItem[r].type.val === 'm-bot') {
                    delete robotsPerItem[r];
                }
            });
        }

        console.log(robotsPerItem)

        const availableRobotsPerItem = (
            <Menu>
                {Object.keys(robotsPerItem).map((robot) => (
                    <MenuItem icon="feed" text={`${robot}`} fill onClick={() => this.onRobotSelect(robot)} />
                ))}
            </Menu>
        );

        return ([
            <div
                style={{
                    width: '100%', height: '100%', background: 'white', padding: '1%', display: 'flex', flexDirection: 'column', borderRadius: '10px', fontSize: '16px'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '25px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        background: '#16335B',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        position: 'relative',
                        fontSize: '13px'
                    }}
                >
                    <EditableText disabled className="name-no-edit" placeholder="Component Name" value={name} />
                </div>
                <div
                    style={{
                        width: '100%',
                        height: 'calc(100% - 35px)',
                        maxHeight: '100%',
                        marginTop: '10px',
                        position: 'relative'
                    }}
                >
                    {spinnerOpen
                        && (
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    position: 'absolute',
                                    top: '0px',
                                    left: '0px',
                                    zIndex: 1000,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: 'rgba(255, 255, 255, 0.6)'
                                }}
                            >
                                <Spinner intent="primary" size={Math.min(width / 10, height / 2)} />
                            </div>
                        )}
                    <ReactResizeDetector onResize={this.resize}>
                        {() => (
                            <div
                                id={`navigationRouteDiv_${id}`}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: (orientation === 'horizontal') ? 'row' : 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '65%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', borderStyle: 'ridge'
                                        }}
                                    >
                                        <img id={`navigationRouteImage_${id}`} src={image} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain', zIndex: 1 }} />
                                    </div>
                                    <div
                                        style={{
                                            width: '34%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'end', padding: '5px'
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '100%', height: '35px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly'
                                            }}
                                        >
                                            <b>Actions</b>
                                            <hr style={{ width: '100%', border: '1px solid #16335B' }} />
                                        </div>
                                        <OrangeButton
                                            disabled={image === ''}
                                            width="100%"
                                            textAlign="center"
                                            fontSize="14px"
                                            onClick={this.annotate}
                                            margin="0% 0% 3% 0%"
                                        >
                                            Place Annotatation
                                        </OrangeButton>
                                        <OrangeButton
                                            disabled={image === ''}
                                            width="100%"
                                            textAlign="center"
                                            fontSize="14px"
                                            onClick={this.goToPoint}
                                            margin="0% 0% 3% 0%"
                                        >
                                            Select Target
                                        </OrangeButton>
                                        <OrangeButton
                                            disabled={image === ''}
                                            width="100%"
                                            textAlign="center"
                                            fontSize="14px"
                                            onClick={this.openItemSelection}
                                            margin="0% 0% 3% 0%"
                                        >
                                            Select Item
                                        </OrangeButton>
                                        <div
                                            style={{
                                                width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', marginTop: '10%',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '100%', height: '35px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly'
                                                }}
                                            >
                                                <b>Current Goals</b>
                                                <hr style={{ width: '100%', border: '1px solid #16335B' }} />
                                            </div>
                                            {allowGoals && (
                                                <WhiteBlueScrollBar>
                                                    {this.renderAvailableGoals()}
                                                </WhiteBlueScrollBar>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </ReactResizeDetector>

                    <div
                        id={`navigationRouteComponents_${id}`}
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'absolute',
                            top: '0px',
                            left: '0px',
                            pointerEvents: 'none'
                        }}
                    >
                        <div
                            style={{
                                width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'
                            }}
                        >
                            <div
                                style={{
                                    width: '65%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <div style={{ width: `${imageWidth}px`, height: `${imageHeight}px`, zIndex: 2 }}>
                                        {image !== ''
                                            && robotPoses.map((p) => (
                                                <div
                                                    style={{
                                                        width: `${p.sizeX}px`,
                                                        height: `${p.sizeY}px`,
                                                        transform: `translateX(${p.x - p.sizeX / 2}px) translateY(${p.y - p.sizeY / 2}px) rotate(${p.theta}rad)`,
                                                        transformOrigin: 'center',
                                                        position: 'absolute',
                                                        zIndex: 5,
                                                    }}
                                                >
                                                    <img src={p.color} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                                </div>
                                            ))}
                                        {Object.keys(robots).map((r) => (
                                            robots[r].pose !== null
                                            && robots[r].path.map((p) => (
                                                <div
                                                    style={{
                                                        width: `${Math.min(imageWidth, imageHeight) / ((tempSelectedGoal === r) ? 50 : 60)}px`,
                                                        height: `${Math.min(imageWidth, imageHeight) / ((tempSelectedGoal === r) ? 50 : 60)}px`,
                                                        borderRadius: `${Math.min(imageWidth, imageHeight) / 60}px`,
                                                        transform: `translate(${(((mapOrigin.x + robots[r].origin.x + p[0]) * (imageWidth / map_width)) / resolution) - ((Math.min(imageWidth, imageHeight) / 60) / 2)}px, ${(((mapOrigin.y - robots[r].origin.y - p[1]) * (imageHeight / map_height)) / resolution) - ((Math.min(imageWidth, imageHeight) / 60) / 2)}px`,
                                                        background: `${(tempSelectedGoal === r) ? 'green' : 'red'}`,
                                                        position: 'absolute'
                                                    }}
                                                />
                                            ))
                                        ))}
                                        {annotations.map((a, ind) => (
                                            <>
                                                <div
                                                    style={{
                                                        width: `${Math.min(imageWidth, imageHeight) / 20}px`,
                                                        height: `${Math.min(imageWidth, imageHeight) / 20}px`,
                                                        transform: `translate(${((a.x * (imageWidth / map_width))) - ((Math.min(imageWidth, imageHeight) / 20) / 2)}px, ${((a.y * (imageHeight / map_height))) - ((Math.min(imageWidth, imageHeight) / 20))}px)`,
                                                        position: 'absolute',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        pointerEvents: 'auto'
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: '#DE162F', width: '100%', height: '100%', cursor: 'pointer' }} onClick={() => this.selectAnnotation(ind)} />
                                                </div>
                                                <div
                                                    style={{
                                                        width: '150px',
                                                        height: '20px',
                                                        position: 'absolute',
                                                        transform: `translate(${((a.x * (imageWidth / map_width))) - (150 / 2)}px, ${((a.y * (imageHeight / map_height))) - ((Math.min(imageWidth, imageHeight) / 20)) - 20}px)`,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        color: '#FF9D66',
                                                        fontSize: `${Math.min(150 / a.name.length, 20)}px`
                                                    }}
                                                >
                                                    {a.name}
                                                </div>
                                            </>
                                        ))}
                                        {tempSelectedGoal !== null
                                            && (
                                                <div
                                                    style={{
                                                        width: `${selectedRobotTarget.sizeX}px`,
                                                        height: `${selectedRobotTarget.sizeY}px`,
                                                        borderRadius: `${Math.min(imageWidth, imageHeight) / 60}px`,
                                                        transform: `translate(${selectedRobotTarget.x - selectedRobotTarget.sizeX / 2}px, ${selectedRobotTarget.y - selectedRobotTarget.sizeY / 2}px`,
                                                        background: 'green',
                                                        position: 'absolute',
                                                        animation: 'blink 2s linear infinite'
                                                    }}
                                                />
                                            )}
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    width: '35%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly'
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%', height: '15%', display: 'flex', alignItems: 'start', marginTop: '3%'
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div style={{ display: (canvasOpen) ? 'block' : 'none' }}>
                        <div
                            id={`navigationRouteCanvas_${id}`}
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'absolute',
                                top: '0px',
                                left: '0px',
                                background: 'rgba(255, 255, 255, 1)'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'
                                }}
                            >
                                <div
                                    style={{
                                        width: '65%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', borderStyle: 'ridge'
                                    }}
                                >
                                    <div
                                        style={{
                                            width: 'calc(100% - 120px)', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'
                                        }}
                                    >
                                        <CustomCanvas id={`canvas_${id}`} ref={this.canvas} style={{ width: `${imageWidth}px`, height: `${imageHeight}px` }} />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: '35%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '95%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: '1%',
                                        }}
                                    >
                                        <b>New Annotation&apos;s Coordinates</b>
                                        <hr style={{ width: '100%', border: '1px solid #16335B' }} />
                                    </div>
                                    <div
                                        style={{
                                            width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '1%'
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '1%'
                                            }}
                                        >
                                            <InputGroup
                                                value={mousePos.x}
                                                onChange={(event) => this.onCoordChange('x', event)}
                                                style={{
                                                    width: '95%',
                                                    marginLeft: '6%'
                                                }}
                                            />
                                            <Tag>X</Tag>
                                        </div>
                                        <div
                                            style={{
                                                width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '1%'
                                            }}
                                        >
                                            <InputGroup
                                                value={mousePos.y}
                                                onChange={(event) => this.onCoordChange('y', event)}
                                                style={{
                                                    width: '95%',
                                                    marginLeft: '5%'
                                                }}
                                            />
                                            <Tag>Y</Tag>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            padding: '5% 0% 0% 3%'
                                        }}
                                    >
                                        <BlueButton
                                            width="100%"
                                            textAlign="center"
                                            fontSize="14px"
                                            onClick={this.placeAnnotationTag}
                                            fill
                                        >
                                            Place Tag
                                        </BlueButton>
                                    </div>
                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            padding: '5% 0% 0% 3%'
                                        }}
                                    >
                                        <OrangeButton
                                            width="100%"
                                            textAlign="center"
                                            fontSize="14px"
                                            onClick={this.closeAnnotate}
                                            fill
                                        >
                                            Cancel
                                        </OrangeButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ display: (gotoCanvasOpen) ? 'block' : 'none' }}>
                        <div
                            id={`navigationRouteCanvas_${id}`}
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'absolute',
                                top: '0px',
                                left: '0px',
                                background: 'rgba(255, 255, 255, 1)'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'
                                }}
                            >
                                <div
                                    style={{
                                        width: '65%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', borderStyle: 'ridge'
                                    }}
                                >
                                    <div
                                        style={{
                                            width: 'calc(100% - 120px)', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'
                                        }}
                                    >
                                        <CustomCanvas id={`canvas_${id}`} ref={this.gotoCanvas} style={{ width: `${imageWidth}px`, height: `${imageHeight}px` }} />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: '35%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '95%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: '1%',
                                        }}
                                    >
                                        <b>New Target&apos;s Coordinates</b>
                                        <hr style={{ width: '100%', border: '1px solid #16335B' }} />
                                    </div>
                                    <div
                                        style={{
                                            width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '1%'
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '1%'
                                            }}
                                        >
                                            <InputGroup
                                                value={mousePos.x}
                                                onChange={(event) => this.onCoordChange('x', event)}
                                                style={{
                                                    width: '95%',
                                                    marginLeft: '6%'
                                                }}
                                            />
                                            <Tag>X</Tag>
                                        </div>
                                        <div
                                            style={{
                                                width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '1%'
                                            }}
                                        >
                                            <InputGroup
                                                value={mousePos.y}
                                                onChange={(event) => this.onCoordChange('y', event)}
                                                style={{
                                                    width: '95%',
                                                    marginLeft: '5%'
                                                }}
                                            />
                                            <Tag>Y</Tag>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            padding: '5% 0% 0% 3%'
                                        }}
                                    >
                                        <BlueButton
                                            width="100%"
                                            textAlign="center"
                                            fontSize="14px"
                                            onClick={this.selectGoal}
                                            fill
                                        >
                                            Place Tag
                                        </BlueButton>
                                    </div>
                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            padding: '5% 0% 0% 3%'
                                        }}
                                    >
                                        <OrangeButton
                                            width="100%"
                                            textAlign="center"
                                            fontSize="14px"
                                            onClick={this.closeGotoCanvas}
                                            fill
                                        >
                                            Cancel
                                        </OrangeButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ display: (selectItem) ? 'block' : 'none' }}>
                        <div
                            id={`navigationRouteGotoCanvas_${id}`}
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'absolute',
                                top: '0px',
                                left: '0px',
                                background: 'rgba(255, 255, 255, 1)'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'
                                }}
                            >
                                <div
                                    style={{
                                        width: '65%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', borderStyle: 'ridge'
                                    }}
                                >
                                    {(!closedButtons && !smallButtons)
                                        && (
                                            <div
                                                style={{
                                                    width: 'calc(100% - 120px)', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'
                                                }}
                                            >
                                                <CustomCanvas id={`canvas_${id}`} ref={this.slotCanvas} style={{ width: `${imageWidth}px`, height: `${imageHeight}px` }} />
                                            </div>
                                        )}
                                </div>
                                <div
                                    style={{
                                        width: '35%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '95%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: '1%',
                                        }}
                                    >
                                        <b>Select Item&apos;s Type</b>
                                        <hr style={{ width: '100%', border: '1px solid #16335B' }} />
                                    </div>

                                    {selectedItem === ItemTypes.parcel
                                        && (
                                            <div
                                                style={{
                                                    width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: '1%', marginLeft: '3%'
                                                }}
                                            >
                                                <BlueButton
                                                    width="48.5%"
                                                    textAlign="center"
                                                    fontSize="14px"
                                                    borderRadius="5px 0px 0px 5px"
                                                    onClick={() => this.switchItemSelection(ItemTypes.parcel)}
                                                    fill
                                                >
                                                    Parcel
                                                </BlueButton>
                                                <BlueBorderButton
                                                    width="48.5%"
                                                    textAlign="center"
                                                    fontSize="14px"
                                                    borderRadius="0px 0px 0px 0px"
                                                    onClick={() => this.switchItemSelection(ItemTypes.pallet)}
                                                    fill
                                                >
                                                    Pallet
                                                </BlueBorderButton>
                                                <BlueBorderButton
                                                    width="48.5%"
                                                    textAlign="center"
                                                    fontSize="14px"
                                                    borderRadius="0px 5px 5px 0px"
                                                    onClick={() => this.switchItemSelection(ItemTypes.slot)}
                                                    fill
                                                >
                                                    Slot
                                                </BlueBorderButton>
                                            </div>
                                        )}
                                    {selectedItem === ItemTypes.pallet
                                        && (
                                            <div
                                                style={{
                                                    width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: '1%', marginLeft: '3%'
                                                }}
                                            >
                                                <BlueBorderButton
                                                    width="48.5%"
                                                    textAlign="center"
                                                    fontSize="14px"
                                                    borderRadius="5px 0px 0px 5px"
                                                    onClick={() => this.switchItemSelection(ItemTypes.parcel)}
                                                    fill
                                                >
                                                    Parcel
                                                </BlueBorderButton>
                                                <BlueButton
                                                    width="48.5%"
                                                    textAlign="center"
                                                    fontSize="14px"
                                                    borderRadius="0px 0px 0px 0px"
                                                    onClick={() => this.switchItemSelection(ItemTypes.pallet)}
                                                    fill
                                                >
                                                    Pallet
                                                </BlueButton>
                                                <BlueBorderButton
                                                    width="48.5%"
                                                    textAlign="center"
                                                    fontSize="14px"
                                                    borderRadius="0px 5px 5px 0px"
                                                    onClick={() => this.switchItemSelection(ItemTypes.slot)}
                                                    fill
                                                >
                                                    Slot
                                                </BlueBorderButton>
                                            </div>
                                        )}
                                    {selectedItem === ItemTypes.slot
                                        && (
                                            <div
                                                style={{
                                                    width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: '1%', marginLeft: '3%'
                                                }}
                                            >
                                                <BlueBorderButton
                                                    width="48.5%"
                                                    textAlign="center"
                                                    fontSize="14px"
                                                    borderRadius="5px 0px 0px 5px"
                                                    onClick={() => this.switchItemSelection(ItemTypes.parcel)}
                                                    fill
                                                >
                                                    Parcel
                                                </BlueBorderButton>
                                                <BlueBorderButton
                                                    width="48.5%"
                                                    textAlign="center"
                                                    fontSize="14px"
                                                    borderRadius="0px 0px 0px 0px"
                                                    onClick={() => this.switchItemSelection(ItemTypes.pallet)}
                                                    fill
                                                >
                                                    Pallet
                                                </BlueBorderButton>
                                                <BlueButton
                                                    width="48.5%"
                                                    textAlign="center"
                                                    fontSize="14px"
                                                    borderRadius="0px 5px 5px 0px"
                                                    onClick={() => this.switchItemSelection(ItemTypes.slot)}
                                                    fill
                                                >
                                                    Slot
                                                </BlueButton>
                                            </div>
                                        )}

                                    <WhiteBlueScrollBar>
                                        {this.renderItems()}
                                    </WhiteBlueScrollBar>

                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            padding: '5% 0% 0% 3%'
                                        }}
                                    >
                                        <BlueButton
                                            width="100%"
                                            textAlign="center"
                                            fontSize="14px"
                                            onClick={this.selectItemGoal}
                                            disabled={tempSelectedItem[selectedItem] === null}
                                            fill
                                        >
                                            {`${selectedItem === ItemTypes.slot ? 'Go to' : 'Pick'} ${selectedItem}`}
                                        </BlueButton>
                                    </div>
                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            padding: '5% 0% 0% 3%'
                                        }}
                                    >
                                        <OrangeButton
                                            width="100%"
                                            textAlign="center"
                                            fontSize="14px"
                                            onClick={this.closeItemSelection}
                                            fill
                                        >
                                            Cancel
                                        </OrangeButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>,

            <PortalOverflowOverlay key="annotation-name" id="annotation-name" isOpen={annotationNamePopupOpen} width="450px" height="auto" background="white" borderRadius="10px" padding="20px" marginLeft="auto" marginRight="auto" color="black">
                <FormHeader>
                    {`${name} Settings`}
                </FormHeader>
                <FormSubHeader>
                    Annotation Name
                </FormSubHeader>
                <SettingsDiv>
                    <div
                        style={{
                            width: '100%', height: '100%', marginTop: '10px', display: 'flex', alignItems: 'center'
                        }}
                    >
                        <InputGroup
                            leftIcon="tag"
                            placeholder="Annotation Name"
                            onChange={this.changeAnnotationName}
                            value={tempAnnotationName}
                            fill
                            large
                        />
                    </div>
                    <div
                        style={{
                            width: '300px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', marginTop: '10px'
                        }}
                    >
                        <BlueBorderButton
                            id="cancel"
                            type="button"
                            onClick={this.cancelAnnotation}
                        >
                            Cancel
                        </BlueBorderButton>
                        <BlueButton
                            id="save"
                            type="button"
                            onClick={this.sendAnnotation}
                        >
                            Annotate
                        </BlueButton>
                    </div>
                </SettingsDiv>
            </PortalOverflowOverlay>,

            <PortalOverflowOverlay key="select-annotation" id="select-annotation" isOpen={selectAnnotationPopupOpen} width="450px" height="auto" background="white" borderRadius="10px" padding="20px" marginLeft="auto" marginRight="auto" color="black">
                <FormHeader>
                    {`${name} Settings`}
                </FormHeader>
                <FormSubHeader>
                    {this.tempSelectedAnnotation !== null
                        && (
                            <b>{`Selected Annotation: ${annotations[this.tempSelectedAnnotation].name}`}</b>
                        )}
                    {this.tempSelectedAnnotation === null
                        && (
                            <b>{`Selected Goal Point: (${Math.round(mousePos.x)}, ${Math.round(mousePos.y)}) m`}</b>
                        )}
                </FormSubHeader>
                <SettingsDiv>
                    <div
                        style={{
                            width: '300px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', marginTop: '10px'
                        }}
                    >
                        <Popover
                            content={this.renderAvailableRobots()}
                            position={Position.BOTTOM}
                            interactionKind={PopoverInteractionKind.CLICK}
                        >
                            <BlueButton
                                id="select"
                                type="button"
                                rightIcon="caret-down"
                            >
                                {(tempSelectedRobot === null) && ('Select')}
                                {(tempSelectedRobot !== null) && (`${tempSelectedRobot}`)}
                            </BlueButton>
                        </Popover>
                    </div>
                    {this.tempSelectedAnnotation !== null
                        && (
                            <div
                                style={{
                                    width: '300px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', marginTop: '10px'
                                }}
                            >
                                <RedBorderButton
                                    id="cancel"
                                    type="button"
                                    onClick={() => this.openDeleteAnnotation(this.tempSelectedAnnotation)}
                                >
                                    Delete
                                </RedBorderButton>
                            </div>
                        )}
                    <div
                        style={{
                            width: '300px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', marginTop: '10px'
                        }}
                    >
                        <BlueBorderButton
                            id="cancel"
                            type="button"
                            onClick={this.cancelSelectAnnotation}
                        >
                            Cancel
                        </BlueBorderButton>
                        {(tempSelectedRobot !== null) && (
                            <BlueButton
                                id="save"
                                type="button"
                                onClick={this.tempSelectedAnnotation === null ? this.sendPointGoal : this.goToPlace}
                            >
                                Go To Point
                            </BlueButton>
                        )}
                    </div>
                </SettingsDiv>
            </PortalOverflowOverlay>,

            <PortalOverflowOverlay key="select-annotation" id="select-annotation" isOpen={selectAnnotationPopupOpen} width="450px" height="auto" background="white" borderRadius="10px" padding="20px" marginLeft="auto" marginRight="auto" color="black">
                <FormHeader>
                    {`${name} Settings`}
                </FormHeader>
                <FormSubHeader>
                    {this.tempSelectedAnnotation !== null
                        && (
                            <b>{`Selected Annotation: ${annotations[this.tempSelectedAnnotation].name}`}</b>
                        )}
                    {this.tempSelectedAnnotation === null
                        && (
                            <b>{`Selected Goal Point: (${Math.round(mousePos.x)}, ${Math.round(mousePos.y)}) m`}</b>
                        )}
                </FormSubHeader>
                <SettingsDiv>
                    <div
                        style={{
                            width: '300px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', marginTop: '10px'
                        }}
                    >
                        <Popover
                            content={this.renderAvailableRobots()}
                            position={Position.BOTTOM}
                            interactionKind={PopoverInteractionKind.CLICK}
                        >
                            <BlueButton
                                id="select"
                                type="button"
                                rightIcon="caret-down"
                            >
                                {(tempSelectedRobot === null) && ('Select')}
                                {(tempSelectedRobot !== null) && (`${tempSelectedRobot}`)}
                            </BlueButton>
                        </Popover>
                    </div>
                    {this.tempSelectedAnnotation !== null
                        && (
                            <div
                                style={{
                                    width: '300px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', marginTop: '10px'
                                }}
                            >
                                <RedBorderButton
                                    id="cancel"
                                    type="button"
                                    onClick={() => this.openDeleteAnnotation(this.tempSelectedAnnotation)}
                                >
                                    Delete
                                </RedBorderButton>
                            </div>
                        )}
                    <div
                        style={{
                            width: '300px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', marginTop: '10px'
                        }}
                    >
                        <BlueBorderButton
                            id="cancel"
                            type="button"
                            onClick={this.cancelSelectAnnotation}
                        >
                            Cancel
                        </BlueBorderButton>
                        {(tempSelectedRobot !== null) && (
                            <BlueButton
                                id="save"
                                type="button"
                                onClick={this.tempSelectedAnnotation === null ? this.sendPointGoal : this.goToPlace}
                            >
                                Go To Point
                            </BlueButton>
                        )}
                    </div>
                </SettingsDiv>
            </PortalOverflowOverlay>,

            <PortalOverflowOverlay key="select-annotation" id="select-annotation" isOpen={selectItemPopopOpen} width="450px" height="auto" background="white" borderRadius="10px" padding="20px" marginLeft="auto" marginRight="auto" color="black">
                <FormHeader>
                    {`${name} Settings`}
                </FormHeader>
                <FormSubHeader>
                    <b>{`Selected ${selectedItem}: ${selectedItemId}`}</b>
                </FormSubHeader>
                <SettingsDiv>
                    <div
                        style={{
                            width: '300px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', marginTop: '10px'
                        }}
                    >
                        {Object.keys(robotsPerItem).length === 0
                            && (
                                <div
                                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontSize: '17px' }}
                                >
                                    <b>{selectedItem === 'pallet' ? 'No available <bi-bots>.' : 'No available <robots>.'}</b>
                                    {selectedItem === 'pallet' && (<b>{'Try combining two <m-bots>.'}</b>)}
                                    &nbsp;
                                </div>
                            )}
                        {Object.keys(robotsPerItem).length !== 0
                            && (
                                <Popover
                                    content={availableRobotsPerItem}
                                    position={Position.BOTTOM}
                                    interactionKind={PopoverInteractionKind.CLICK}
                                >
                                    <BlueButton
                                        id="select"
                                        type="button"
                                        rightIcon="caret-down"
                                    >
                                        {(tempSelectedRobot === null) && ('Select')}
                                        {(tempSelectedRobot !== null) && (`${tempSelectedRobot}`)}
                                    </BlueButton>
                                </Popover>
                            )}
                    </div>
                    <div
                        style={{
                            width: '300px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', marginTop: '10px'
                        }}
                    >
                        <BlueBorderButton
                            id="cancel"
                            type="button"
                            onClick={this.cancelSelectAnnotation}
                        >
                            Cancel
                        </BlueBorderButton>
                        {(tempSelectedRobot !== null) && (
                            <BlueButton
                                id="save"
                                type="button"
                                onClick={() => this.sendSelectedItem(selectedItemId)}
                            >
                                {`Go To ${selectedItem}`}
                            </BlueButton>
                        )}
                    </div>
                </SettingsDiv>
            </PortalOverflowOverlay>,

            <Alert key="delete-annotation-alert" style={{ background: 'white', color: 'black' }} usePortal cancelButtonText="Cancel" confirmButtonText="Delete" icon="trash" intent="danger" isOpen={deleteAnnotationPopupOpen} onCancel={this.closeDeleteAnnotation} onConfirm={this.deleteAnnotation}>
                <p>
                    Are you sure you want to delete the annotation
                    {this.tempDeleteAnnotation !== null && (<b style={{ marginLeft: '5px' }}>{annotations[this.tempDeleteAnnotation].name}</b>)}
                    ?
                </p>
            </Alert>,
            <Alert key="delete-goal-alert" style={{ background: 'white', color: 'black' }} usePortal cancelButtonText="Cancel" confirmButtonText="Delete" icon="trash" intent="danger" isOpen={deleteGoalPopupOpen} onCancel={this.closeCancelGoal} onConfirm={this.cancelGoal}>
                <p>{`Are you sure you want to cancel ${this.tempGoalToCancel}'s goal?`}</p>
            </Alert>,
            <PortalOverflowOverlay key="image" id="image" isOpen={imagePopupOpen} width="533.33px" height="400px" background="none" borderRadius="10px" padding="0px" marginLeft="auto" marginRight="auto" color="black">
                <div style={{ width: '100%', height: '100%', borderRadius: '10px', position: 'relative' }}>
                    <img src={image} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: '10px' }} />
                    <div
                        style={{
                            width: '30px', height: '30px', borderRadius: '30px', position: 'absolute', top: '-15px', right: '-15px', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#D0D6DE'
                        }}
                    >
                        <FontAwesomeIcon icon={faTimes} style={{ color: '#16335B', fontSize: '24px', cursor: 'pointer' }} onClick={this.closeImage} />
                    </div>
                </div>
            </PortalOverflowOverlay>
        ]);
    }
}

const createNavigationRoute = ({ id, type, initialState, user, owner }) => (
    <NavigationRoute
        id={id}
        type={type}
        initialState={initialState}
        user={user}
        owner={owner}
    />
);

export default createNavigationRoute;
