/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import {
    EditableText, Tag, Spinner, ProgressBar, Text, Tooltip
} from '@blueprintjs/core';
/* eslint-disable import/no-unresolved */
import ReactResizeDetector from 'react-resize-detector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { map } from 'rxjs/operators';
import { ToasterBottom } from '../../../lib/toaster';
import { findSource } from '../../../api/sources';
import { WhiteBlueScrollBar } from '../../../lib/scrollbar';
import { TextBox } from '../../../lib/text-box';
import { getFiwareEntities } from '../../../api/general';

const objectPath = require('object-path');
const mqtt = require('mqtt');

const TextRow = styled.div`
    width: 100%;
    color: #3B3C3E;
    background-color: #E1E1E1;
    margin: 5px 5px 0px 0px;
    border-radius: 5px;
    padding: 5px;
    class: column;
    display: flex;
    justify-content: space-between;
`;

function constructStompPayload(message) {
    const payload = {
        topic: message.headers.destination,
        message: JSON.parse(message.body)
    };

    return payload;
}

function constructMQTTPayload(recvTopic, message) {
    const topic = recvTopic.replaceAll('/', '.');
    const payload = {
        topic,
        message
    };

    return payload;
}

class RobotState extends React.Component {
    constructor(props) {
        super(props);

        this.type = props.type;

        this.state = {
            spinnerOpen: true,
            id: props.id,
            user: props.user,
            owner: props.owner,
            name: props.initialState.name || 'Robot State',
            counter: 0,

            availableRobots: {},

            source: props.initialState.source || 'Select source',
            varAPIKey: props.initialState.varAPIKey || '',
            varEntityType: props.initialState.varEntityType || '',
            varTimeout: props.initialState.varTimeout || 1000,

            fontSize: 50,
            width: 50,
            height: 50
        };
        this.rxStomp = null;
        this.mqttClient = null;

        this.setPeriodicTimer = this.setPeriodicTimer.bind(this);
        this.changeSpinner = this.changeSpinner.bind(this);
        this.stateMessageReceived = this.stateMessageReceived.bind(this);
        this.statusMessageReceived = this.statusMessageReceived.bind(this);
        this.connectStompSource = this.connectStompSource.bind(this);
        this.connectMqttSource = this.connectMqttSource.bind(this);
        this.connectToTopic = this.connectToTopic.bind(this);
        this.renderItems = this.renderItems.bind(this);
        this.resize = this.resize.bind(this);
        this.initialComponentsState = this.initialComponentsState.bind(this);
    }

    async componentDidMount() {
        const { source, owner, user, varEntityType } = this.state;
        this.connectToTopic();

        let response = await findSource(source, owner, user);

        // custom rest call
        if (response.success) {
            const url = response.source.orionUrl;
            const formattedUrl = (url.startsWith('http://') || url.startsWith('https://')) ? url : `http://${url}`;

            const params = JSON.stringify({
                type: varEntityType,
                options: 'keyValues',
                // attrs: 'state',
            });

            const headers = JSON.stringify({
                'Fiware-service': 'openiot',
                'Fiware-servicepath': '/'
            });

            const query = JSON.stringify({});

            try {
                response = await getFiwareEntities(formattedUrl, headers, params, query);
                this.initialComponentsState(response.data);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log('Error: ', error);
            }
        }
    }

    componentWillUnmount() {
        if (this.rxStomp !== null) {
            this.rxStomp.deactivate();
        }
        if (this.mqttClient !== null) {
            this.mqttClient.end();
        }
    }

    setPeriodicTimer(key) {
        this.interval = setInterval(() => {
            const { varTimeout, availableRobots } = this.state;
            const dateNow = new Date();

            if (availableRobots[key].lastSend === null) {
                availableRobots[key].active = false;

                this.setState({ availableRobots });
            } else {
                availableRobots[key].active = (dateNow.getTime() - availableRobots[key].lastSend.getTime() <= varTimeout);

                this.setState({ availableRobots });
            }
        }, 200);
    }

    changeSpinner(value) {
        this.setState({ spinnerOpen: value });
    }

    initialComponentsState(response) {
        const { availableRobots, varEntityType } = this.state;

        response.forEach((r) => {
            const robotId = r.id.split(/[:]+/)[3];
            const robot = `${varEntityType}${robotId}`;

            if (r.state) {
                if (Object.prototype.hasOwnProperty.call(r.state, 'val')) {
                    availableRobots[robot] = {
                        type: r.robotClass.val,
                        state: r.state.val,
                        lastSend: new Date(),
                        active: false
                    };

                    this.setPeriodicTimer(robot);
                }
            }
        });

        this.setState(availableRobots);
    }

    stateMessageReceived(payload) {
        const { id, availableRobots } = this.state;
        const { topic, message } = payload;

        try {
            const { counter } = this.state;
            const newCounter = counter + 1;
            let ts = (new Date() - this.prevTime) / 1000.0;

            const key = 'val';
            const robotId = topic.split(/[.]+/)[2];
            const value = objectPath.get(message, key);

            if (value === undefined) {
                return;
            }

            if (this.prevTime < 0) {
                ts = '-';
                this.minInterval = 1000000000;
                this.maxInterval = 0;
                this.meanInterval = 0;
            } else {
                if (ts < this.minInterval) {
                    this.minInterval = ts;
                }
                if (ts > this.maxInterval) {
                    this.maxInterval = ts;
                }
                this.meanInterval += ts;
            }

            this.prevTime = new Date();

            let newRecord = false;
            if (!(robotId in availableRobots)) {
                newRecord = true;

                availableRobots[robotId] = {
                    type: 'm-bot',
                    state: value,
                    lastSend: new Date(),
                    active: false
                };
            } else {
                availableRobots[robotId].state = value;
            }

            this.setState({
                counter: newCounter,
                timeSpan: `Last interval: ${ts} sec`,
                minint: `Minimum interval: ${this.minInterval} sec`,
                meanint: `Mean interval: ${(this.meanInterval / (newCounter - 1)).toFixed(3)} sec`,
                maxint: `Maximum interval: ${this.maxInterval} sec`,
                timeSpanVal: ts,
                minintVal: this.minInterval,
                meanintVal: (this.meanInterval / (newCounter - 1)).toFixed(3),
                maxintVal: this.maxInterval
            }, () => {
                const height = document.getElementById(`valueDiv_${id}`).offsetHeight;
                const width = document.getElementById(`valueDiv_${id}`).offsetWidth;
                this.resize(width, height);
            });

            if (newRecord) {
                this.setPeriodicTimer(robotId);
            }
        } catch { }
    }

    statusMessageReceived(payload) {
        const { id, availableRobots } = this.state;
        const { topic, message } = payload;

        try {
            const { counter } = this.state;
            const newCounter = counter + 1;
            let ts = (new Date() - this.prevTime) / 1000.0;

            const key = 'status';
            const robotId = topic.split(/[.]+/)[2];
            const value = objectPath.get(message, key);

            if (value !== 'Active') {
                return;
            }

            if (this.prevTime < 0) {
                ts = '-';
                this.minInterval = 1000000000;
                this.maxInterval = 0;
                this.meanInterval = 0;
            } else {
                if (ts < this.minInterval) {
                    this.minInterval = ts;
                }
                if (ts > this.maxInterval) {
                    this.maxInterval = ts;
                }
                this.meanInterval += ts;
            }

            this.prevTime = new Date();

            if (robotId in availableRobots) {
                availableRobots[robotId].lastSend = new Date();
            }

            this.setState({
                counter: newCounter,
                timeSpan: `Last interval: ${ts} sec`,
                minint: `Minimum interval: ${this.minInterval} sec`,
                meanint: `Mean interval: ${(this.meanInterval / (newCounter - 1)).toFixed(3)} sec`,
                maxint: `Maximum interval: ${this.maxInterval} sec`,
                timeSpanVal: ts,
                minintVal: this.minInterval,
                meanintVal: (this.meanInterval / (newCounter - 1)).toFixed(3),
                maxintVal: this.maxInterval
            }, () => {
                const height = document.getElementById(`valueDiv_${id}`).offsetHeight;
                const width = document.getElementById(`valueDiv_${id}`).offsetWidth;
                this.resize(width, height);
            });
        } catch { }
    }

    connectStompSource(source) {
        const { name, varAPIKey } = this.state;
        const stateTopic = `.${varAPIKey}.*.attrs.state`;
        const statusTopic = `.${varAPIKey}.*.attrs.status`;
        try {
            const stompConfig = {
                connectHeaders: {
                    login: source.login,
                    passcode: source.passcode,
                    host: source.vhost
                },
                brokerURL: source.url
            };

            // eslint-disable-next-line no-undef
            this.rxStomp = new RxStomp.RxStomp();
            this.rxStomp.configure(stompConfig);
            this.rxStomp.activate();
            const initialReceiptId = `${name}_start`;

            this.prevTime = -1;
            this.minInterval = -1;
            this.maxInterval = -1;
            this.meanInterval = 0;

            this.rxStomp.watch(`/topic/${stateTopic}`, { receipt: initialReceiptId }).pipe(map((message) => constructStompPayload(message))).subscribe((newTopic, payload) => {
                this.stateMessageReceived(newTopic, payload);
            });

            this.rxStomp.watch(`/topic/${statusTopic}`, { receipt: initialReceiptId }).pipe(map((message) => JSON.parse(message.body))).subscribe((payload) => {
                this.messageReceivedPath(payload);
            });

            this.rxStomp.watchForReceipt(initialReceiptId, () => {
                this.changeSpinner(false);
            });
        } catch { }
    }

    connectMqttSource(source) {
        const { varAPIKey } = this.state;
        const stateTopic = `/${varAPIKey}/+/attrs/state`;
        const statusTopic = `/${varAPIKey}/+/attrs/status`;

        try {
            const config = {
                username: source.login,
                password: source.passcode
            };

            this.mqttClient = mqtt.connect(source.url, config);
            this.mqttClient.on('connect', () => {
                this.mqttClient.subscribe(`${stateTopic}`, (err) => {
                    if (!err) {
                        this.changeSpinner(false);
                    }
                });

                this.mqttClient.subscribe(`${statusTopic}`, (err) => {
                    if (!err) {
                        this.changeSpinner(false);
                    }
                });
            });

            this.prevTime = -1;
            this.minInterval = -1;
            this.maxInterval = -1;
            this.meanInterval = 0;

            this.mqttClient.on('message', (recvTopic, message) => {
                const topic = recvTopic.split('/')[4];

                if (topic === 'state') {
                    this.stateMessageReceived(constructMQTTPayload(recvTopic, JSON.parse(message.toString())));
                } else if (topic === 'status') {
                    this.statusMessageReceived(constructMQTTPayload(recvTopic, JSON.parse(message.toString())));
                }
            });
        } catch { }
    }

    async connectToTopic() {
        const { user, owner, name, source } = this.state;
        const response = await findSource(source, owner, user);
        if (response.success) {
            if (response.source.type === 'stomp') {
                this.connectStompSource(response.source);
            } else {
                this.connectMqttSource(response.source);
            }
        } else {
            ToasterBottom.show({
                intent: 'danger',
                message: response.message || `There was a problem trying to find the source for ${name}`
            });
        }
    }

    resize(width, height) {
        this.setState({
            fontSize: Math.min(height, (width / 5)),
            width,
            height
        });
    }

    renderItems() {
        const { availableRobots } = this.state;
        const keys = Object.keys(availableRobots);

        const listItems = keys.map((key) => (
            <div
                style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}
            >
                <div
                    style={{
                        width: '30px',
                        background: (availableRobots[key].active) ? '#7ABF43' : '#DE162F',
                        margin: '5px 5px 0px 0px',
                        borderRadius: '5px',
                        filter: 'blur(2px)',
                        animation: (availableRobots[key].active) ? 'blink 3s linear infinite' : ''
                    }}
                />
                <TextRow>
                    <div
                        style={{
                            width: 'calc(100% - 30px)',
                            display: 'grid',
                            gridTemplateColumns: 'auto auto auto',
                            justifyContent: 'space-between'
                        }}
                    >
                        <TextBox>{`- ${key}`}</TextBox>
                        <TextBox>{`<${availableRobots[key].type}>`}</TextBox>
                        <TextBox>{`${availableRobots[key].state}`}</TextBox>
                    </div>
                </TextRow>
            </div>
        ));

        return listItems;
    }

    render() {
        const { spinnerOpen, id, name, counter, fontSize, width, height, timeSpan, minint, maxint, meanint, timeSpanVal, minintVal, meanintVal, maxintVal } = this.state;

        return (
            <div
                style={{
                    width: '100%', height: '100%', background: 'white', padding: '1%', display: 'flex', flexDirection: 'column', borderRadius: '10px', fontSize: '16px'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '25px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        background: '#16335B',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        position: 'relative',
                        fontSize: '13px'
                    }}
                >
                    <EditableText disabled className="name-no-edit" placeholder="Component Name" value={name} />
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            right: '2%',
                            transform: 'translateY(-50%)',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Tooltip
                            popoverClassName="item-info-tooltip"
                            content={(
                                <div>
                                    <div>
                                        <div>
                                            <Text>{timeSpan}</Text>
                                            <ProgressBar
                                                intent="primary"
                                                animate={false}
                                                stripes={false}
                                                value={timeSpanVal / maxintVal}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <Text>{minint}</Text>
                                            <ProgressBar
                                                intent="success"
                                                animate={false}
                                                stripes={false}
                                                value={minintVal / maxintVal}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <Text>{meanint}</Text>
                                            <ProgressBar
                                                intent="warning"
                                                animate={false}
                                                stripes={false}
                                                value={meanintVal / maxintVal}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <Text>{maxint}</Text>
                                            <ProgressBar
                                                intent="danger"
                                                animate={false}
                                                stripes={false}
                                                value={maxintVal / maxintVal}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            interactionKind="hover"
                        >
                            <Tag
                                round
                                intent="primary"
                                style={{
                                    background: '#16335B',
                                    color: '#aaaaaa',
                                    fontSize: '13px'
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faChartBar}
                                    style={{
                                        color: '#aaaaaa',
                                        paddingRight: '4px',
                                        fontSize: '13px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={this.filterMessages}
                                />
                                {counter}
                            </Tag>
                        </Tooltip>
                    </div>
                </div>
                <ReactResizeDetector onResize={this.resize}>
                    {() => (
                        <div
                            id={`valueDiv_${id}`}
                            style={{
                                width: '100%',
                                height: 'calc(100% - 25px)',
                                maxHeight: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                color: '#16335B',
                                fontSize: `${fontSize}px`,
                            }}
                        >
                            {spinnerOpen
                                && (
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            position: 'absolute',
                                            top: '0px',
                                            left: '0px',
                                            zIndex: 1000,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            background: 'rgba(255, 255, 255, 0.6)'
                                        }}
                                    >
                                        <Spinner intent="primary" size={Math.min(width / 10, height / 2)} />
                                    </div>
                                )}
                            <WhiteBlueScrollBar>
                                {this.renderItems()}
                            </WhiteBlueScrollBar>
                        </div>
                    )}
                </ReactResizeDetector>
            </div>
        );
    }
}

const createRobotState = ({ id, type, initialState, user, owner }) => (
    <RobotState
        id={id}
        type={type}
        initialState={initialState}
        user={user}
        owner={owner}
    />
);

export default createRobotState;
