/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import {
    Alert, EditableText, InputGroup, Menu, MenuItem, Popover, Tooltip, Icon
} from '@blueprintjs/core';
/* eslint-disable import/no-unresolved */
import ReactResizeDetector from 'react-resize-detector';
import {
    faCog, faTrashAlt, faClone
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {PortalOverflowOverlay} from '../../../lib/overlays';
import {
    BlueBorderButton, BlueButton, OrangeButton, CustomButton
} from '../../../lib/buttons';
import imagePlaceholder from '../../../assets/imagePlaceholder.png';
import {WhiteBlueScrollBar} from '../../../lib/scrollbar';

const FormHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: bold;
    color: #16335B;
    position: relative;
`;

const SettingsDiv = styled.div`
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const GoalItemDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex; 
    justifyContent: space-between;
    alignItems: center;
    padding: 1px;
    margin: 3px;
    backgroundColor: lightgrey;
    borderRadius: 5px;
    border: 2px solid #16335B;
    :hover& {
        background: #66CC91!important;
    }
`;


class RobotNagigation extends React.Component {
    constructor(props) {
        super(props);

        this.type = props.type;
        this.updateItem = props.updateItem;
        this.deleteItem = props.deleteItem;
        this.cloneComponent = props.cloneComponent;
        
        this.state = {
            id: props.id,
            availableSources: props.availableSources,
            name: props.initialState.name || 'Navigation Route',
            source: props.initialState.source || 'Select source',
            varAPIKey: props.initialState.varAPIKey || '',
            varWarehouseType: props.initialState.varWarehouseType || '',
            varRobotType: props.initialState.varRobotType || '',
            varWarehouseId: props.initialState.varWarehouseId || '',
            popoverOpen: false,
            deletePopupOpen: false,
            tempAPIKey: '',
            tempWarehouseType: '',
            tempRobotType: '',
            tempWarehouseId: ''
        };

        this.sendUpdate = this.sendUpdate.bind(this);
        this.delete = this.delete.bind(this);
        this.changeName = this.changeName.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.closeConfirmPopup = this.closeConfirmPopup.bind(this);
        this.changeAPIKeyVar = this.changeAPIKeyVar.bind(this);
        this.changeWarehouseTypeVar = this.changeWarehouseTypeVar.bind(this);
        this.changeRobotTypeVar = this.changeRobotTypeVar.bind(this);
        this.changeWarehouseIdVar = this.changeWarehouseIdVar.bind(this);
        this.renderExampleGoals = this.renderExampleGoals.bind(this);
        this.openDelete = this.openDelete.bind(this);
        this.closeDelete = this.closeDelete.bind(this);
        this.changeSource = this.changeSource.bind(this);
        this.clone = this.clone.bind(this);

        this.exampleRobots = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    }

    componentDidMount() {
    }

    static getDerivedStateFromProps(props) {
        return {
            id: props.id,
            availableSources: props.availableSources,
            name: props.initialState.name || 'Navigation Route',
            source: props.initialState.source || 'Select source',
            varAPIKey: props.initialState.varAPIKey || '',
            varWarehouseType: props.initialState.varWarehouseType || '',
            varRobotType: props.initialState.varRobotType || '',
            varWarehouseId: props.initialState.varWarehouseId || ''
        };
    }

    sendUpdate(key, value) {
        const {id} = this.state;
        this.updateItem(id, key, value);
    }

    delete() {
        const {id} = this.state;
        this.setState({deletePopupOpen: false});
        this.deleteItem(id);
    }

    changeName(value) {
        this.sendUpdate('name', value);
    }

    openPopup() {
        const {source, varAPIKey, varWarehouseType, varRobotType, varWarehouseId} = this.state;
        this.setState({
            popoverOpen: true,
            tempSource: source,
            tempAPIKey: varAPIKey,
            tempWarehouseType: varWarehouseType,
            tempRobotType: varRobotType,
            tempWarehouseId: varWarehouseId
        });
    }

    closePopup() {
        this.setState({
            popoverOpen: false,
            tempSource: 'Select source',
            tempAPIKey: '',
            tempWarehouseType: '',
            tempWarehouseId: ''
        });
    }

    closeConfirmPopup() {
        const {tempSource, tempAPIKey, tempWarehouseType, tempRobotType, tempWarehouseId} = this.state;
        this.sendUpdate('source', tempSource);
        this.sendUpdate('varAPIKey', tempAPIKey);
        this.sendUpdate('varWarehouseType', tempWarehouseType);
        this.sendUpdate('varRobotType', tempRobotType);
        this.sendUpdate('varWarehouseId', tempWarehouseId);
        this.setState({popoverOpen: false});
    }

    openDelete() {
        this.setState({deletePopupOpen: true});
    }

    closeDelete() {
        this.setState({deletePopupOpen: false});
    }

    changeSource(value) {
        this.setState({tempSource: value});
    }

    changeAPIKeyVar(event) {
        event.stopPropagation()
        this.setState({tempAPIKey: event.target.value})
    }

    changeWarehouseTypeVar(event) {
        event.stopPropagation()
        this.setState({tempWarehouseType: event.target.value})
    }

    changeRobotTypeVar(event) {
        event.stopPropagation()
        this.setState({tempRobotType: event.target.value})
    }

    changeWarehouseIdVar(event) {
        event.stopPropagation()
        this.setState({tempWarehouseId: event.target.value})
    }

    clone() {
        const {id} = this.state;
        this.closePopup();
        this.cloneComponent(id);
    }

    renderExampleGoals() {
        const goals = this.exampleRobots.map((r, i) => (
            <GoalItemDiv
                style={{
                    width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1px', margin: '3px', backgroundColor: 'lightgrey',borderRadius: '5px', border: '2px solid #16335B'
                }}
            >
                <div
                    style={{
                        width: 'calc(93% - 8px)', height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1px', marginRight: '2px'
                    }}
                >
                    <div
                        style={{
                            height: '100%',
                            width: '40%',
                            padding: '4px',
                            backgroundColor: 'white', 
                            borderRadius: '5px',
                            display: 'flex',  
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <b>{`${r}`}</b>
                    </div>

                    <div
                        style={{
                            height: '100%',
                            width: '8%',
                            padding: '4px',
                            display: 'flex',  
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Icon icon="arrow-right" />
                    </div>

                    <div
                        style={{
                            height: '100%',
                            width: '40%',
                            padding: '4px',
                            backgroundColor: 'white', 
                            borderRadius: '5px',
                            display: 'flex',                                                              
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <b>{`Goal <${i}>`}</b>        
                    </div>
                </div>
                <CustomButton type="button" height="8px" width="8px" icon="small-cross" />
            </GoalItemDiv>
        ));

        return goals;
    }

    render() {
        const {id, availableSources, name, popoverOpen, deletePopupOpen, tempSource, tempAPIKey, tempWarehouseType, tempRobotType, tempWarehouseId} = this.state;

        return ([
            <div 
                style={{
                    width: '100%', height: '100%', background: 'white', padding: '1%', display: 'flex', flexDirection: 'column', borderRadius: '10px', fontSize: '16px'
                }}
            >
                <div 
                    style={{
                        width: '100%', 
                        height: '25px', 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center', 
                        color: 'white', 
                        background: '#16335B',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px', 
                        position: 'relative',
                        fontSize: '13px'
                    }}
                >
                    <div onMouseDown={(e) => e.stopPropagation()}>
                        <EditableText className="name-edit" onChange={this.changeName} onMouseDown={(e) => e.stopPropagation()} placeholder="Component Name" value={name} />
                    </div>
                    <div 
                        style={{
                            height: '100%', 
                            position: 'absolute', 
                            top: '0px', 
                            right: '2%', 
                            display: 'flex', 
                            alignItems: 'center'
                        }}
                        onMouseDown={(e) => e.stopPropagation()}
                    >
                        <FontAwesomeIcon icon={faCog} style={{color: 'white', cursor: 'pointer'}} onClick={this.openPopup} />
                    </div>
                    <div 
                        style={{
                            height: '100%', 
                            position: 'absolute', 
                            top: '0px', 
                            left: '2%', 
                            display: 'flex', 
                            alignItems: 'center'
                        }}
                        onMouseDown={(e) => e.stopPropagation()}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} style={{color: '#DE162F', cursor: 'pointer'}} onClick={this.openDelete} />
                    </div>
                </div>
                <ReactResizeDetector>
                    {() => (
                        <div
                            id={`navigationRouteDiv_${id}`}
                            style={{
                                width: '100%',
                                height: 'calc(100% - 35px)',
                                maxHeight: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >   
                            <div
                                style={{
                                    width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'
                                }}
                            >
                                <div
                                    style={{
                                        width: '65%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'
                                    }}
                                >
                                    <img id={`navigationRouteImage_${id}`} src={imagePlaceholder} alt="" style={{width: '100%', height: '100%', marginTop: '3%'}} />
                                </div>

                                <div
                                    style={{
                                        width: '35%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly'
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '100%', height: '15%', display: 'flex', alignItems: 'start', marginTop: '3%'
                                        }}
                                    >
                                        <OrangeButton 
                                            width="100%"
                                            textAlign="center"
                                            fontSize="14px"
                                            margin="0px 3px 0px 6px"
                                        >
                                            Annotate
                                        </OrangeButton>
                                        <OrangeButton 
                                            width="100%"
                                            textAlign="center"
                                            fontSize="14px"
                                            margin="0px 0px 0px 3px"
                                        >
                                            Go to Point
                                        </OrangeButton>
                                    </div>
                                    <WhiteBlueScrollBar>
                                        {this.renderExampleGoals()}
                                    </WhiteBlueScrollBar>                                   
                                </div>
                            </div>
                        </div>
                    )}
                </ReactResizeDetector>
            </div>,
            <PortalOverflowOverlay key="settings" id="settings" isOpen={popoverOpen} width="450px" height="auto" background="white" borderRadius="10px" padding="20px" marginLeft="auto" marginRight="auto" color="black">
                <FormHeader>
                    {`${name} Settings`}
                    <div style={{position: 'absolute', right: '0px', top: '0px'}}>
                        <Tooltip content="Clone component" popoverClassName="item-info-tooltip">
                            <FontAwesomeIcon icon={faClone} style={{color: '#FF9D66', fontSize: '20px', cursor: 'pointer'}} onClick={this.clone} />
                        </Tooltip>
                    </div>
                </FormHeader>
                <SettingsDiv>
                    <div 
                        style={{
                            width: '400px', height: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'
                        }}
                    >
                        <Popover popoverClassName="custom-popover">
                            <BlueBorderButton type="button" width="400px" rightIcon="caret-down">
                                {tempSource}
                            </BlueBorderButton> 
                            <Menu>
                                {availableSources.map((s) => (
                                    <MenuItem text={s} onClick={() => this.changeSource(s)} />
                                ))}
                            </Menu>
                        </Popover>
                        <div
                            style={{
                                width: '100%', height: '100%', marginTop: '10px', display: 'flex', alignItems: 'center'
                            }}
                        >
                            <InputGroup
                                leftIcon="key"
                                placeholder="API Key"
                                onChange={this.changeAPIKeyVar}
                                value={tempAPIKey}
                                fill
                                large
                            />
                        </div>
                        <div
                            style={{
                                width: '100%', height: '100%', marginTop: '10px', display: 'flex', alignItems: 'center'
                            }}
                        >
                            <InputGroup
                                leftIcon="array"
                                placeholder="Robot Entity Type"
                                onChange={this.changeRobotTypeVar}
                                value={tempRobotType}
                                fill
                                large
                            />
                        </div>
                        <div
                            style={{
                                width: '100%', height: '100%', marginTop: '10px', display: 'flex', alignItems: 'center'
                            }}
                        >
                            <InputGroup
                                leftIcon="array"
                                placeholder="Warehouse Entity Type"
                                onChange={this.changeWarehouseTypeVar}
                                value={tempWarehouseType}
                                fill
                                large
                            />
                        </div>
                        <div 
                            style={{
                                width: '100%', height: '100%', marginTop: '10px', display: 'flex', alignItems: 'center'
                            }}
                        >
                            <InputGroup
                                leftIcon="tag"
                                placeholder="Warehouse Entity Id"
                                onChange={this.changeWarehouseIdVar}
                                value={tempWarehouseId}
                                fill
                                large
                            />
                        </div>
                        <div 
                            style={{
                                width: '300px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', marginTop: '10px'
                            }}
                        >
                            <BlueBorderButton
                                id="cancel"
                                type="button"
                                onClick={this.closePopup}
                            >
                                Cancel
                            </BlueBorderButton>
                            <BlueButton
                                id="save"
                                type="button"
                                onClick={this.closeConfirmPopup}
                            >
                                Save
                            </BlueButton>
                        </div>
                    </div>
                </SettingsDiv>
            </PortalOverflowOverlay>,
            <Alert key="delete-alert" style={{background: 'white', color: 'black'}} usePortal cancelButtonText="Cancel" confirmButtonText="Delete" icon="trash" intent="danger" isOpen={deletePopupOpen} onCancel={this.closeDelete} onConfirm={this.delete}>
                <p>
                    Are you sure you want to delete the component
                    <b style={{marginLeft: '5px'}}>{name}</b>
                    ?
                </p>
            </Alert>
        ]);
    }
}

const createRobotNagigation = ({id, type, initialState, updateItem, deleteItem, cloneComponent, sources}) => (
    <RobotNagigation 
        id={id}
        type={type}
        initialState={initialState}
        updateItem={updateItem}
        deleteItem={deleteItem}
        cloneComponent={cloneComponent}
        availableSources={sources}
    />
);

export default createRobotNagigation;
