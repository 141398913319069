import React from 'react';
import {Box} from 'rebass';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {
    Alert, Button, Tooltip
} from '@blueprintjs/core';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClipboard} from '@fortawesome/free-solid-svg-icons';
import {ToasterBottom} from '../../lib/toaster';
import actions from '../../actions';
import {
    getToken, createToken, deleteToken
} from '../../api/users';
import {CustomSpinner} from '../../lib/overlays';
import TextInput from '../../lib/text-input';
import {BlueButton, BlueBorderButton} from '../../lib/buttons';

const StyledBox = styled(Box)`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
`;

const StyledArea = styled(Box)`
    width: 750px;
    min-height: 500px;
    display: block;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin: auto!important;
`;

const StyledHeader = styled.h2`
    text-align: center;
    color: white;
    margin: 0px;
    font-size: 65px;
    font-weight: 300;
    letter-spacing: 5px;
`;

const StyledSubHeader = styled.h2`
    width: 100%;
    text-align: center;
    color: #FF9D66;
    margin: 0px;
    margin-bottom: 20px;
    font-size: 35px;
    font-weight: normal;
    letter-spacing: 2px;
`;

const TokenArea = styled.div`
    width: 100%;
    max-width: 500px;
    background: white;
    border-radius: 10px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
`;

const TokenHeader = styled.div`
    width: 100%;
    color: #16335B;
    margin: 0px;
    margin-bottom: 10px;
    font-size: 20px;
`;

export class ProfilePage extends React.Component {
    constructor(props) {
        super(props);

        this.user = props.user;
        this.token = props.token;
        this.clearAuth = props.clearAuth;
        this.pushHistory = props.history.push;

        this.state = {
            spinnerOpen: false,
            deletePopupOpen: false,
            token: '',
            copyVisible: false
        };

        this.fetchToken = this.fetchToken.bind(this);
        this.generateToken = this.generateToken.bind(this);
        this.changeSpinner = this.changeSpinner.bind(this);
        this.openDeletePopup = this.openDeletePopup.bind(this);
        this.closeDeletePopup = this.closeDeletePopup.bind(this);
        this.deleteToken = this.deleteToken.bind(this);
        this.copyClipboard = this.copyClipboard.bind(this);
    }

    componentDidMount() {
        if (jwt_decode(this.token).exp < Date.now() / 1000) {
            this.clearAuth();
            ToasterBottom.show({
                intent: 'danger',
                message: 'Connection session has expired. Please login again.'
            });
        } else {
            this.fetchToken();
        }
    }

    async fetchToken() {
        this.changeSpinner(true);
        const response = await getToken();
        if (response.success) {
            this.setState({token: response.token, copyVisible: false});
        } else {
            ToasterBottom.show({
                intent: 'danger',
                message: response.message || 'There was a problem trying to fetch the user token'
            });
        }

        this.changeSpinner(false);
    }

    changeSpinner(value) {
        this.setState({spinnerOpen: value});
    }

    async generateToken() {
        this.changeSpinner(true);
        const response = await createToken();
        if (response.success) {
            this.setState({token: response.token, copyVisible: true});
            ToasterBottom.show({
                intent: 'success',
                message: response.message || 'Token generated successfully'
            });
        } else {
            ToasterBottom.show({
                intent: 'danger',
                message: response.message || 'There was a problem trying to create the user token'
            });
        }

        this.changeSpinner(false);
    }

    openDeletePopup() {
        this.setState({deletePopupOpen: true});
    }

    closeDeletePopup() {
        this.setState({deletePopupOpen: false});
    }

    async deleteToken() {
        this.changeSpinner(true);
        const response = await deleteToken();
        if (response.success) {
            this.setState({token: '', copyVisible: false});
            ToasterBottom.show({
                intent: 'success',
                message: response.message || 'Token deleted successfully'
            });
        } else {
            ToasterBottom.show({
                intent: 'danger',
                message: response.message || 'There was a problem trying to delete the user token'
            });
        }

        this.changeSpinner(false);
        this.setState({deletePopupOpen: false});
    }

    copyClipboard() {
        const {token} = this.state;
        const el = document.createElement('textarea');
        el.value = token;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        ToasterBottom.show({
            intent: 'success',
            message: 'Copied to clipboard'
        });
    }

    render() {    
        const {spinnerOpen, token, deletePopupOpen, copyVisible} = this.state;

        return ([
            <StyledBox>
                <StyledArea>
                    <StyledHeader>
                        PROFILE
                    </StyledHeader>
                    <StyledSubHeader>
                        This is your profile area
                    </StyledSubHeader>
                    <TokenArea>
                        <TokenHeader>
                            User Token
                        </TokenHeader>
                        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <TextInput
                                name="token"
                                type="text"
                                leftIcon="tag"
                                placeholder="No user token exists"
                                fill
                                formikProps={{
                                    values: {token},
                                    handleChange: () => {},
                                    handleBlur: () => {},
                                    errors: {},
                                    touched: {}
                                }}
                                disabled
                                width={copyVisible ? 'calc(100% - 40px)' : '100%'}
                            />
                            {copyVisible
                            && (
                                <Tooltip content="Copy to clipboard">
                                    <Button minimal style={{width: '40px', height: '40px', background: '#16335B', marginBottom: '15px'}} onClick={this.copyClipboard}>
                                        <FontAwesomeIcon icon={faClipboard} style={{color: 'white', fontSize: '16px'}} />
                                    </Button>
                                </Tooltip>
                            )}
                        </div>
                        {copyVisible
                        && (
                            <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <p style={{color: '#c23030', fontSize: '14px', margin: '0px'}}>
                                    Make sure to copy this token now. You won&apos;t be able to see it again!
                                </p>
                            </div>
                        )}
                        <div
                            style={{
                                width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', marginTop: '10px'
                            }}
                        >
                            <BlueBorderButton
                                id="delete"
                                type="button"
                                disabled={!token}
                                onClick={this.openDeletePopup}
                            >
                                Delete
                            </BlueBorderButton>
                            <BlueButton
                                id="create"
                                type="button"
                                disabled={token}
                                onClick={this.generateToken}
                            >
                                Create
                            </BlueButton>
                        </div>
                    </TokenArea>
                </StyledArea>
            </StyledBox>,
            <Alert
                key="delete-token-alert"
                style={{background: 'white', color: 'black'}}
                usePortal={false}
                cancelButtonText="Cancel"
                confirmButtonText="Delete"
                icon="trash"
                intent="danger"
                isOpen={deletePopupOpen}
                onCancel={this.closeDeletePopup}
                onConfirm={this.deleteToken}
            >
                <p>
                    Are you sure you want to delete the token?
                </p>
            </Alert>,
            <CustomSpinner key="spinner" isOpen={spinnerOpen} />
        ]);
    }
}

export const mapState = (state) => ({user: state.auth.user, token: state.auth.token});

export const mapDispatch = (dispatch) => ({
    clearAuth: () => {
        dispatch(actions.auth.clear());
    }
});

export default connect(
    mapState,
    mapDispatch
)(ProfilePage);
