/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';

const WhiteBlueScrollBarStyle = styled.div`
    border-radius: 10px;
    background-color: #ffffff;

    overflow-y: auto;
    overflow-x: hidden;

    margin: 10px 0px 1px 0px;

    padding: 0px 5px 0px 5px;

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between safe;

    ::-webkit-scrollbar-track {
        background: #16335B;
    }

    ::-webkit-scrollbar {
        width: 15px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ffffff;
        border-radius: 15px;
        border: 2px solid #16335B;
    }
`;

export const WhiteBlueScrollBar = ({
    children,
    ...props
}) => (
    <WhiteBlueScrollBarStyle
        style={{
            ...props
        }}
    >
        {children}
    </WhiteBlueScrollBarStyle>
);
