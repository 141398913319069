/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import {
    Alert, EditableText, InputGroup, Menu, MenuItem, Popover, Tooltip
} from '@blueprintjs/core';
import {
    faCog, faTrashAlt, faClone
} from '@fortawesome/free-solid-svg-icons';
/* eslint-disable import/no-unresolved */
import ReactResizeDetector from 'react-resize-detector';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {PortalOverflowOverlay} from '../../../lib/overlays';
import {BlueBorderButton, BlueButton} from '../../../lib/buttons';

const FormHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: bold;
    color: #16335B;
    position: relative;
`;

const SettingsDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

class WarehouseKPIS extends React.Component {
    constructor(props) {
        super(props);

        this.type = props.type;
        this.updateItem = props.updateItem;
        this.deleteItem = props.deleteItem;
        this.cloneComponent = props.cloneComponent;

        this.state = {
            id: props.id,
            availableSources: props.availableSources,
            name: props.initialState.name || 'Value',
            source: props.initialState.source || 'Select source',

            varAPIKey: props.initialState.varAPIKey || '',
            varEntityType: props.initialState.varEntityType || '',
            
            unit: props.initialState.unit || '%',

            popoverOpen: false,
            deletePopupOpen: false,
            tempSource: 'Select source',
            tempAPIKey: '',
            tempEntityType: '',
            tempUnit: '%',
            fontSize: 10
        };
        this.value = 50;

        this.sendUpdate = this.sendUpdate.bind(this);
        this.delete = this.delete.bind(this);
        this.changeName = this.changeName.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.closeConfirmPopup = this.closeConfirmPopup.bind(this);
        this.openDelete = this.openDelete.bind(this);
        this.closeDelete = this.closeDelete.bind(this);
        this.resize = this.resize.bind(this);
        this.changeSource = this.changeSource.bind(this);
        this.changeAPIKey = this.changeAPIKey.bind(this);
        this.changeEntityType = this.changeEntityType.bind(this);
        this.changeUnit = this.changeUnit.bind(this);
        this.clone = this.clone.bind(this);
        this.renderStatistics = this.renderStatistics.bind(this);

        this.statisticNames = [
            ['Pallete Storage Density', 325, "pieces"],
            ['Distance x Mass Moved by Robots', 4556, "m*kg"],
            ['Distance x Mass Moved by Operators', 1245, "m*kg"],
            ['Total Moved Pallets', 2456, "pieces"],
            ['Total Moved Parcels', 7688, "pieces"],
            ['Saved Time for Operators', 2.56, "hours"]
        ]
    }

    static getDerivedStateFromProps(props) {
        return {
            id: props.id,
            availableSources: props.availableSources,
            name: props.initialState.name || 'Value',
            source: props.initialState.source || 'Select source',
            varAPIKey: props.initialState.varAPIKey || '',
            varEntityType: props.initialState.varEntityType || '',
            unit: props.initialState.unit || '%'
        };
    }

    componentDidUpdate(__, prevState) {
        const {id, unit} = this.state;
        if (unit !== prevState.unit) {
            const height = document.getElementById(`valueDiv_${id}`).offsetHeight;
            const width = document.getElementById(`valueDiv_${id}`).offsetWidth;
            this.resize(width, height);
        }
    }

    sendUpdate(key, value) {
        const {id} = this.state;
        this.updateItem(id, key, value);
    }

    delete() {
        const {id} = this.state;
        this.setState({deletePopupOpen: false});
        this.deleteItem(id);
    }

    changeName(value) {
        this.sendUpdate('name', value);
    }

    openPopup() {
        const {source, varAPIKey, varEntityType, unit} = this.state;
        this.setState({
            popoverOpen: true,
            tempSource: source,
            tempAPIKey: varAPIKey,
            tempEntityType: varEntityType,
            tempUnit: unit
        });
    }

    closePopup() {
        this.setState({
            popoverOpen: false,
            tempSource: 'Select source',
            tempAPIKey: '',
            tempEntityType: '',
            tempUnit: '%'
        });
    }

    closeConfirmPopup() {
        const {tempSource, tempAPIKey, tempEntityType, tempUnit} = this.state;
        this.sendUpdate('source', tempSource);
        this.sendUpdate('varAPIKey', tempAPIKey);
        this.sendUpdate('varEntityType', tempEntityType);
        this.sendUpdate('unit', tempUnit);
        this.setState({popoverOpen: false});
    }

    openDelete() {
        this.setState({deletePopupOpen: true});
    }

    closeDelete() {
        this.setState({deletePopupOpen: false});
    }

    resize(width, height) {
        this.setState({fontSize: Math.min(height / 12, (width / (String(this.value).length) / 16))});
    }

    changeSource(value) {
        this.setState({tempSource: value});
    }

    changeAPIKey(event) {
        event.stopPropagation();
        this.setState({tempAPIKey: event.target.value});
    }

    changeEntityType(event) {
        event.stopPropagation();
        this.setState({tempEntityType: event.target.value});
    }

    changeUnit(event) {
        event.stopPropagation();
        this.setState({tempUnit: event.target.value});
    }

    clone() {
        const {id} = this.state;
        this.closePopup();
        this.cloneComponent(id);
    }

    renderStatistics() {
        const renderedStatistics = this.statisticNames.map((name) => (
            <li>
                <div
                    style={{
                        width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '3px', margin: '3px', backgroundColor: 'lightgrey', borderRadius: '5px', border: '1px solid #16335B'
                    }}
                >
                    <div
                        style={{
                            width: '70%',
                            margin: '3px',
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center'
                        }}
                    >
                        {name[0]}            
                    </div>

                    <div
                        style={{
                            backgroundColor: 'white', 
                            borderRadius: '5px',
                            width: '30%',
                            padding: '2px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <b>{`${name[1]} ${name[2]}`}</b>        
                    </div>

                </div>    
            </li>
        ));

        return renderedStatistics;
    }

    render() {
        const {id, availableSources, name, popoverOpen, deletePopupOpen, fontSize, tempSource, tempAPIKey, tempEntityType} = this.state;

        return ([
            <div
                style={{
                    width: '100%', height: '100%', background: 'white', padding: '1%', display: 'flex', flexDirection: 'column', borderRadius: '10px', fontSize: '16px'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '25px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        background: '#16335B',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        position: 'relative',
                        fontSize: '13px'
                    }}
                >
                    <div onMouseDown={(e) => e.stopPropagation()}>
                        <EditableText className="name-edit" onChange={this.changeName} onMouseDown={(e) => e.stopPropagation()} placeholder="Component Name" value={name} />
                    </div>
                    <div
                        style={{
                            height: '100%',
                            position: 'absolute',
                            top: '0px',
                            right: '2%',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        onMouseDown={(e) => e.stopPropagation()}
                    >
                        <div style={{paddingRight: '5px'}}>
                            <Tooltip content="Clone component" popoverClassName="item-info-tooltip">
                                <FontAwesomeIcon icon={faClone} style={{color: 'white', fontSize: '13px', cursor: 'pointer'}} onClick={this.clone} />
                            </Tooltip>
                        </div>
                        <FontAwesomeIcon icon={faCog} style={{color: 'white', cursor: 'pointer'}} onClick={this.openPopup} />
                    </div>
                    <div
                        style={{
                            height: '100%',
                            position: 'absolute',
                            top: '0px',
                            left: '2%',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        onMouseDown={(e) => e.stopPropagation()}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} style={{color: '#DE162F', cursor: 'pointer'}} onClick={this.openDelete} />
                    </div>
                </div>
                <ReactResizeDetector onResize={this.resize}>
                    {() => (
                        <div
                            id={`valueDiv_${id}`}
                            style={{
                                width: '100%',
                                height: 'calc(100% - 35px)',
                                maxHeight: '100%',
                                marginTop: '3px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#16335B',
                                fontSize: `${fontSize}px`
                            }}
                        >
                            <ul
                                style={{
                                    listStyleType: 'none', width: '100%', height:'100%', marginRight: '50px'
                                }}
                            >
                                {this.renderStatistics()}
                            </ul>
                            
                        </div>
                    )}
                </ReactResizeDetector>
            </div>,
            <PortalOverflowOverlay key="settings" id="settings" isOpen={popoverOpen} width="450px" height="auto" background="white" borderRadius="10px" padding="20px" marginLeft="auto" marginRight="auto" color="black">
                <FormHeader>
                    {`${name} Settings`}
                </FormHeader>
                <SettingsDiv>
                    <Popover popoverClassName="custom-popover">
                        <BlueBorderButton type="button" width="410px" rightIcon="caret-down">
                            {tempSource}
                        </BlueBorderButton>
                        <Menu>
                            {availableSources.map((s) => (
                                <MenuItem text={s} onClick={() => this.changeSource(s)} />
                            ))}
                        </Menu>
                    </Popover>
                    <div
                        style={{
                            width: '100%', height: '100%', marginTop: '10px', display: 'flex', alignItems: 'center'
                        }}
                    >
                        <InputGroup
                            leftIcon="key"
                            placeholder="API Key"
                            onChange={this.changeAPIKey}
                            value={tempAPIKey}
                            fill
                            large
                        />
                    </div>
                    <div
                        style={{
                            width: '100%', height: '100%', marginTop: '10px', display: 'flex', alignItems: 'center'
                        }}
                    >
                        <InputGroup
                            leftIcon="array"
                            placeholder="Entity Type"
                            onChange={this.changeEntityType}
                            value={tempEntityType}
                            fill
                            large
                        />
                    </div>
                    <div
                        style={{
                            width: '300px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', marginTop: '10px'
                        }}
                    >
                        <BlueBorderButton
                            id="cancel"
                            type="button"
                            onClick={this.closePopup}
                        >
                            Cancel
                        </BlueBorderButton>
                        <BlueButton
                            id="save"
                            type="button"
                            onClick={this.closeConfirmPopup}
                        >
                            Save
                        </BlueButton>
                    </div>
                </SettingsDiv>
            </PortalOverflowOverlay>,
            <Alert key="delete-alert" style={{background: 'white', color: 'black'}} usePortal cancelButtonText="Cancel" confirmButtonText="Delete" icon="trash" intent="danger" isOpen={deletePopupOpen} onCancel={this.closeDelete} onConfirm={this.delete}>
                <p>
                    Are you sure you want to delete the component
                    <b style={{marginLeft: '5px'}}>{name}</b>
                    ?
                </p>
            </Alert>
        ]);
    }
}

const createWarehouseKPIS = ({id, type, initialState, updateItem, deleteItem, cloneComponent, sources}) => (
    <WarehouseKPIS
        id={id}
        type={type}
        initialState={initialState}
        updateItem={updateItem}
        deleteItem={deleteItem}
        cloneComponent={cloneComponent}
        availableSources={sources}
    />
);

export default createWarehouseKPIS;
