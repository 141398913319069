/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React from 'react';
import {
    EditableText, Spinner, Tag, PopoverInteractionKind, Position, Popover, Menu, MenuItem
} from '@blueprintjs/core';
/* eslint-disable import/no-unresolved */
import ReactResizeDetector from 'react-resize-detector';
import {map} from 'rxjs/operators';
import {
    XYPlot, LineSeries, HorizontalGridLines, VerticalGridLines, XAxis, YAxis, Highlight, Borders
} from 'react-vis';
import {ToasterBottom} from '../../../lib/toaster';
import {findSource} from '../../../api/sources';

import {BlueBorderButton} from '../../../lib/buttons';

import '../../../../node_modules/react-vis/dist/style.css';
import {getFiwareEntities} from '../../../api/general';

const objectPath = require('object-path');
const mqtt = require('mqtt');

function constructPayload(message) {
    const payload = {
        topic: message.headers.destination,
        message: JSON.parse(message.body)
    };

    return payload;
}

function constructMQTTPayload(recvTopic, message) {
    const topic = recvTopic.replaceAll('/', '.');
    const payload = {
        topic,
        message
    };

    return payload;
}

const formatDate = (dateM) => {
    const date = new Date(dateM);
    const hours = ((String(date.getHours())).length === 1) ? `0${String(date.getHours())}` : String(date.getHours());
    const minutes = ((String(date.getMinutes())).length === 1) ? `0${String(date.getMinutes())}` : String(date.getMinutes());
    const seconds = ((String(date.getSeconds())).length === 1) ? `0${String(date.getSeconds())}` : String(date.getSeconds());
    return (`${hours}:${minutes}:${seconds}`);
};

class RobotBattery extends React.Component {
    constructor(props) {
        super(props);

        this.type = props.type;

        let initialNames; 
        let initialTypes = [];
        let initialTopics = [];
        let initialVariables = [];
        let initialColors = [];
        let initialSmooths = [];

        if ('names' in props.initialState) {
            initialNames = props.initialState.names;
            for (let i = 0; i < initialNames.length; i += 1) {
                initialTypes.push('line');
                initialTopics.push('');
                initialVariables.push('');
                initialColors.push('#FF9D66');
                initialSmooths.push(false);
            }
        } else {
            initialNames = []; 
            initialTypes = [];
            initialTopics = [];
            initialVariables = [];
            initialColors = [];
            initialSmooths = [];
        }

        this.state = {
            spinnerOpen: true,
            id: props.id,
            user: props.user,
            owner: props.owner,
            name: props.initialState.name || 'Plot Viewer',
            counter: 0,
            source: props.initialState.source || 'Select source',
            
            varAPIKey: props.initialState.varAPIKey || '',
            varEntityType: props.initialState.varEntityType || '',
            availableRobots: {},
            selectedRobot: 'None',

            maxValues: props.initialState.maxValues || -1,
            colors: props.initialState.colors || initialColors,
            smooths: props.initialState.smooths || initialSmooths,
            width: 50,
            height: 50,
            lastDrawLocation: null
        };
        this.rxStomp = null;
        this.mqttClient = null;

        this.changeSpinner = this.changeSpinner.bind(this);
        this.changeSelectedRobot = this.changeSelectedRobot.bind(this);
        this.messageReceived = this.messageReceived.bind(this);
        this.connectStompSource = this.connectStompSource.bind(this);
        this.connectMqttSource = this.connectMqttSource.bind(this);
        this.connectToTopic = this.connectToTopic.bind(this);
        this.renderRobots = this.renderRobots.bind(this);
        this.resize = this.resize.bind(this);
        this.initialComponentsState = this.initialComponentsState.bind(this);
    }

    async componentDidMount() {
        const {source, owner, user, varEntityType} = this.state;
        this.connectToTopic();

        let response = await findSource(source, owner, user);

        // custom rest call
        if (response.success) {
            const url = response.source.orionUrl;
            const formattedUrl = (url.startsWith('http://') || url.startsWith('https://')) ? url : `http://${url}`;

            const params = JSON.stringify({
                type: varEntityType,
                options: 'keyValues',
                attrs: 'power',
            });

            const headers = JSON.stringify({
                'Fiware-service': 'openiot', 
                'Fiware-servicepath': '/' 
            });

            const query = JSON.stringify({});
            
            try {
                response = await getFiwareEntities(formattedUrl, headers, params, query);
                this.initialComponentsState(response.data);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log('Error: ', error);
            }
        }
    }

    componentWillUnmount() {
        if (this.rxStomp !== null) {
            this.rxStomp.deactivate();
        }
        if (this.mqttClient !== null) {
            this.mqttClient.end();
        }
    }

    changeSpinner(value) {
        this.setState({spinnerOpen: value});
    }

    initialComponentsState(response) {
        const {availableRobots, varEntityType} = this.state;

        response.forEach((r) => {
            const robotId = r.id.split(/[:]+/)[3];
            const robot = `${varEntityType}${robotId}`;

            if (Object.keys(availableRobots).length === 0) {
                this.setState({selectedRobot: robot});
            }

            if (r.power) {
                if (Object.prototype.hasOwnProperty.call(r.power, 'percentage')) {
                    const date = new Date();
                    availableRobots[robot] = [{x: date.getTime(), y: r.power.percentage}];
                    date.setSeconds(date.getSeconds() + 1);
                    availableRobots[robot].push({x: date.getTime(), y: r.power.percentage});
                }
            } else {
                availableRobots[robot] = [];
            }
        });

        this.setState(availableRobots);
    }

    changeSelectedRobot(value) {
        this.setState({selectedRobot: value});
    }

    messageReceived(payload) {
        const {availableRobots} = this.state;
        const {topic, message} = payload;
        const {maxValues} = this.state;
        try {
            const {counter} = this.state;
            const newCounter = counter + 1;
            
            const robotId = topic.split(/[.]+/)[2];
            let value = objectPath.get(message, 'percentage');

            if (value === undefined) {
                return;
            }

            value = (value >= 0) ? value : 0;
            
            if (Object.keys(availableRobots).length === 0) {
                this.setState({selectedRobot: robotId});
            }

            if (!(robotId in availableRobots)) {
                availableRobots[robotId] = [{x: (new Date()).getTime(), y: value}];
            } else {
                availableRobots[robotId].push({x: (new Date()).getTime(), y: value});
            }

            if (availableRobots[robotId].length > maxValues && maxValues !== -1) {
                availableRobots[robotId].shift();       
            }

            this.setState({availableRobots, counter: newCounter});
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    connectStompSource(source) {
        const {name, varAPIKey} = this.state;
        const topic = `.${varAPIKey}.*.attrs.battery`;

        try {
            const stompConfig = {
                connectHeaders: {
                    login: source.login,
                    passcode: source.passcode,
                    host: source.vhost
                },
                brokerURL: source.url
            };
            // eslint-disable-next-line no-undef
            this.rxStomp = new RxStomp.RxStomp();
            this.rxStomp.configure(stompConfig);
            this.rxStomp.activate();
            const initialReceiptId = `${name}_start`;

            this.rxStomp.watch(`/topic/${topic}`, {receipt: initialReceiptId}).pipe(map((message) => constructPayload(message))).subscribe((topic2, payload) => {
                this.messageReceived(topic2, payload);
            });

            this.rxStomp.watchForReceipt(initialReceiptId, () => {
                this.changeSpinner(false);
            });
        } catch {}
    }

    connectMqttSource(source) {
        const {varAPIKey} = this.state;
        const topic = `/${varAPIKey}/+/attrs/battery`;

        try {
            const config = {
                username: source.login,
                password: source.passcode
            };
            
            this.mqttClient = mqtt.connect(source.url, config);
            this.mqttClient.on('connect', () => {
                this.mqttClient.subscribe(`${topic}`, (err) => {
                    if (!err) {
                        this.changeSpinner(false);
                    }
                });
            });

            this.mqttClient.on('message', (recvTopic, message) => {
                this.messageReceived(constructMQTTPayload(recvTopic, JSON.parse(message.toString())));
            });
        } catch {}
    }

    async connectToTopic() {
        const {user, owner, name, source} = this.state;
        const response = await findSource(source, owner, user);
        if (response.success) {
            if (response.source.type === 'stomp') {
                this.connectStompSource(response.source);
            } else {
                this.connectMqttSource(response.source);
            }
        } else {
            ToasterBottom.show({
                intent: 'danger',
                message: response.message || `There was a problem trying to find the source for ${name}`
            });
        }
    }

    resize(width, height) {
        this.setState({width, height});
    }

    renderRobots() {
        const {availableRobots} = this.state;
        const keys = Object.keys(availableRobots);

        const robotItems = (
            <Menu>
                <div
                    style={{
                        display: 'flex', width: '100%', height: '100%', flexDirection: 'column', alignItems: 'center'
                    }}
                >
                    {keys.map((robot) => (
                        <MenuItem icon="feed" text={robot} onClick={() => this.changeSelectedRobot(robot)} />

                    ))}
                </div>
            </Menu>
        );

        return robotItems; 
    }

    render() {
        const {spinnerOpen, id, name, availableRobots, selectedRobot, counter, colors, smooths, width, height, lastDrawLocation} = this.state;

        // create plot for the selected robot
        // const legendItems = [{title: selectedRobot, color: colors[0]}];

        const xTickValues = [];
        if (selectedRobot in availableRobots) {
            availableRobots[selectedRobot].forEach((v) => {
                xTickValues.push(v.x);
            });
        }

        const plots = [];
        if (selectedRobot in availableRobots) {
            const data = availableRobots[selectedRobot];
            if (data.length !== 0) {
                data.sort((a, b) => (a.x - b.x));
            }
            plots.push(<LineSeries data={data} color={colors[0]} animation="gentle" curve={smooths[0] ? 'curveMonotoneX' : ''} />);
        }

        const noRobots = (Object.keys(availableRobots).length === 0);

        return (
            <div
                style={{
                    width: '100%', height: '100%', background: 'white', padding: '1%', display: 'flex', flexDirection: 'column', borderRadius: '10px', fontSize: '16px'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '25px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        background: '#16335B',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        position: 'relative',
                        fontSize: '13px'
                    }}
                >
                    <EditableText disabled className="name-no-edit" placeholder="Component Name" value={name} />
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            right: '2%',
                            transform: 'translateY(-50%)',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Tag
                            round
                            intent="primary"
                            style={{
                                background: '#16335b',
                                color: '#888888',
                                fontSize: '13px'
                            }}
                        >
                            {counter}
                        </Tag>
                    </div>
                </div>
                <ReactResizeDetector onResize={this.resize}>
                    {() => (
                        <div
                            style={{
                                width: '100%',
                                height: 'calc(100% - 25px)',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            {spinnerOpen
                            && (
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'absolute',
                                        top: '0px',
                                        left: '0px',
                                        zIndex: 1000,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: 'rgba(255, 255, 255, 0.6)'
                                    }}
                                >
                                    <Spinner intent="primary" size={Math.min(width / 10, height / 2)} />
                                </div>
                            )}

                            <div
                                style={{
                                    width: '100%', height: '20%', marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'
                                }}
                            >            
                                <div
                                    style={{marginRight: '20px'}}
                                >
                                    Selected Robot:
                                </div>          
                                <Popover 
                                    content={this.renderRobots()} 
                                    position={Position.BOTTOM}
                                    interactionKind={PopoverInteractionKind.CLICK}
                                    disabled={noRobots}
                                >
                                    <BlueBorderButton type="button" diplay="flex" width="100%" justifyContent="stretch" rightIcon="caret-down" disabled={noRobots}>
                                        {selectedRobot}
                                    </BlueBorderButton>
                                </Popover>
                               
                            </div>                            
                            <div
                                id={`plotDiv_${id}`}
                                style={{
                                    width: '100%',
                                    height: '80%',
                                    marginTop: '10px',
                                    overflowY: 'auto',
                                    position: 'relative'
                                }}
                                onMouseDown={(e) => e.stopPropagation()}
                            >
                                {noRobots
                                    && (
                                        <div
                                            style={{
                                                width: '100%', height: '20%', marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'
                                            }}
                                        >      
                                            <p>No Robots Available!</p>
                                        </div>
                                    )}
                                <XYPlot
                                    height={190}
                                    width={width}
                                    margin={{bottom: 60}}
                                    xDomain={
                                        lastDrawLocation && [
                                            lastDrawLocation.left,
                                            lastDrawLocation.right
                                        ]
                                    }
                                    yDomain={
                                        lastDrawLocation && [
                                            lastDrawLocation.bottom,
                                            lastDrawLocation.top
                                        ]
                                    }
                                >
                                    {<VerticalGridLines />}
                                    {<HorizontalGridLines />}
                                    {(!noRobots) && plots}
                                    <Borders style={{
                                        bottom: {fill: '#fff'},
                                        left: {fill: '#fff'},
                                        right: {fill: '#fff'},
                                        top: {fill: '#fff'}
                                    }}
                                    />
                                    {<XAxis tickFormat={(v) => formatDate(v)} tickLabelAngle={-45} tickValues={xTickValues} />}
                                    {<YAxis />}
                                    
                                    <Highlight
                                        onBrushEnd={(area) => this.setState({lastDrawLocation: area})}
                                        onDrag={(area) => {
                                            this.setState({
                                                lastDrawLocation: {
                                                    bottom: lastDrawLocation.bottom + (area.top - area.bottom),
                                                    left: lastDrawLocation.left - (area.right - area.left),
                                                    right: lastDrawLocation.right - (area.right - area.left),
                                                    top: lastDrawLocation.top + (area.top - area.bottom)
                                                }
                                            });
                                        }}
                                    />
                                </XYPlot>
                            </div>
                        </div>
                    )}
                </ReactResizeDetector>
            </div>
        );
    }
}

const createRobotBattery = ({id, type, initialState, user, owner}) => (
    <RobotBattery
        id={id}
        type={type}
        initialState={initialState}
        user={user}
        owner={owner}
    />
);

export default createRobotBattery;
