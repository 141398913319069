/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import {
    Alert, EditableText, InputGroup, Menu, MenuItem, Popover, Tooltip, Position, PopoverInteractionKind, Divider
} from '@blueprintjs/core';
import {
    faCog, faTrashAlt, faClone
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {PortalOverflowOverlay} from '../../../lib/overlays';
import {BlueBorderButton, BlueButton} from '../../../lib/buttons';
import imagePlaceholder from '../../../assets/imagePlaceholder.png';

const FormHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: bold;
    color: #16335B;
    position: relative;
`;

const SettingsDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

class RobotImages extends React.Component {
    constructor(props) {
        super(props);

        this.type = props.type;
        this.updateItem = props.updateItem;
        this.deleteItem = props.deleteItem;
        this.cloneComponent = props.cloneComponent;

        this.state = {
            id: props.id,
            availableSources: props.availableSources,
            name: props.initialState.name || 'Image',
            source: props.initialState.source || 'Select source',
            varAPIKey: props.initialState.varAPIKey || '',
            varEntityType: props.initialState.varEntityType || '',

            selectedRobot: 'All Robots',

            popoverOpen: false,
            deletePopupOpen: false,
            tempSource: '',
            tempAPIKey: '',
            tempEntityType: ''
        };

        this.sendUpdate = this.sendUpdate.bind(this);
        this.delete = this.delete.bind(this);
        this.changeName = this.changeName.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.closeConfirmPopup = this.closeConfirmPopup.bind(this);
        this.openDelete = this.openDelete.bind(this);
        this.closeDelete = this.closeDelete.bind(this);
        this.changeSource = this.changeSource.bind(this);
        this.changeAPIKey = this.changeAPIKey.bind(this);
        this.changeEntityType = this.changeEntityType.bind(this);
        this.clone = this.clone.bind(this);

        this.availableRobots = ["All Robots", "Robot1", "Robot2", "Robot3", "Robot4", "Robot5", "Robot6", "Robot7"];
    }

    static getDerivedStateFromProps(props) {
        return {
            id: props.id,
            availableSources: props.availableSources,
            name: props.initialState.name || 'Image',
            source: props.initialState.source || 'Select source',
            varAPIKey: props.initialState.varAPIKey || '',
            varEntityType: props.initialState.varEntityType || ''
        };
    }

    sendUpdate(key, value) {
        const {id} = this.state;
        this.updateItem(id, key, value);
    }

    delete() {
        const {id} = this.state;
        this.setState({deletePopupOpen: false});
        this.deleteItem(id);
    }

    changeName(value) {
        this.sendUpdate('name', value);
    }

    openPopup() {
        const {source, varAPIKey, varEntityType} = this.state;
        this.setState({
            popoverOpen: true,
            tempSource: source,
            tempAPIKey: varAPIKey,
            tempEntityType: varEntityType
        });
    }

    closePopup() {
        this.setState({
            popoverOpen: false,
            tempSource: 'Select source',
            tempAPIKey: '',
            tempEntityType: ''
        });
    }

    closeConfirmPopup() {
        const {tempSource, tempAPIKey, tempEntityType} = this.state;
        this.sendUpdate('source', tempSource);
        this.sendUpdate('varAPIKey', tempAPIKey);
        this.sendUpdate('varEntityType', tempEntityType);
        this.setState({popoverOpen: false});
    }

    openDelete() {
        this.setState({deletePopupOpen: true});
    }

    closeDelete() {
        this.setState({deletePopupOpen: false});
    }

    changeSource(value) {
        this.setState({tempSource: value});
    }

    changeAPIKey(event) {
        event.stopPropagation();
        this.setState({tempAPIKey: event.target.value});
    }

    changeEntityType(event) {
        event.stopPropagation();
        this.setState({tempEntityType: event.target.value});
    }

    changeAvailRobots(value) {
        this.setState({selectedRobot: value});
    }

    clone() {
        const {id} = this.state;
        this.closePopup();
        this.cloneComponent(id);
    }

    renderExampleRobot() {
        const exampleMenu = (
            <Menu>
                <div
                    style={{
                        display: 'flex', width: '100%', height: '100%', flexDirection: 'column', alignItems: 'center'
                    }}
                >
                    {this.availableRobots.map((robot) => (
                        <MenuItem icon="feed" text={robot} onClick={() => this.changeAvailRobots(robot)} />
                    ))}
                </div>
            </Menu>
        );

        return exampleMenu;
    }

    render() {
        const {id, availableSources, selectedRobot, name, popoverOpen, deletePopupOpen, tempSource, tempAPIKey, tempEntityType} = this.state;

        return ([
            <div
                style={{
                    width: '100%', height: '100%', background: 'white', padding: '1%', display: 'flex', flexDirection: 'column', borderRadius: '10px', fontSize: '16px'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '25px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        background: '#16335B',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        position: 'relative',
                        fontSize: '13px'
                    }}
                >
                    <div onMouseDown={(e) => e.stopPropagation()}>
                        <EditableText className="name-edit" onChange={this.changeName} onMouseDown={(e) => e.stopPropagation()} placeholder="Component Name" value={name} />
                    </div>
                    <div
                        style={{
                            height: '100%',
                            position: 'absolute',
                            top: '0px',
                            right: '2%',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        onMouseDown={(e) => e.stopPropagation()}
                    >
                        <div style={{paddingRight: '5px'}}>
                            <Tooltip content="Clone component" popoverClassName="item-info-tooltip">
                                <FontAwesomeIcon icon={faClone} style={{color: 'white', fontSize: '13px', cursor: 'pointer'}} onClick={this.clone} />
                            </Tooltip>
                        </div>
                        <FontAwesomeIcon icon={faCog} style={{color: 'white', cursor: 'pointer'}} onClick={this.openPopup} />
                    </div>
                    <div
                        style={{
                            height: '100%',
                            position: 'absolute',
                            top: '0px',
                            left: '2%',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        onMouseDown={(e) => e.stopPropagation()}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} style={{color: '#DE162F', cursor: 'pointer'}} onClick={this.openDelete} />
                    </div>
                </div>
                <div
                    id={`imageDiv_${id}`}
                    style={{
                        width: '100%',
                        height: 'calc(100% - 35px)',
                        maxHeight: '100%',
                        marginTop: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >   
                    <div
                        style={{
                            width: '100%', height: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}
                    >                      
                        <Popover
                            content={this.renderExampleRobot()} 
                            position={Position.BOTTOM}
                            interactionKind={PopoverInteractionKind.CLICK}
                        >
                            <BlueBorderButton type="button" diplay="flex" width="100%" justifyContent="stretch" rightIcon="caret-down">
                                {selectedRobot}
                            </BlueBorderButton>
                        </Popover>
                    </div>
                    <div
                        style={{
                            width: '100%', height: '90%', margin: '10px', display: 'grid', gridTemplateColumns: 'auto auto auto', padding: '10px', alignItems: 'center', justifyContent: 'center', overflowY: 'auto'
                        }}
                    >   
                        {(selectedRobot === "All Robots") && this.availableRobots.map((r) => (
                            (r !== "All Robots") && (
                            <div
                                style={{
                                    backgroundColor: 'rgba(255, 255, 255, 1.0)',
                                    border: '1px solid rgba(0, 0, 0, 0.8)',
                                    borderRadius: '5px',
                                    padding: '5px',
                                    margin: '5px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    fontSize: '15px'
                                }}
                            >
                                <img src={imagePlaceholder} alt="" style={{width: '100%', height: '100%', objectFit: 'contain'}} />
                                <Divider />
                                <p>{r}</p>
                            </div>
                        )))}
                        {(selectedRobot !== "All Robots") && <img src={imagePlaceholder} alt="" style={{width: '100%', height: '100%', objectFit: 'contain'}} />}
                    </div>
                </div>
            </div>,
            <PortalOverflowOverlay key="settings" id="settings" isOpen={popoverOpen} width="450px" height="auto" background="white" borderRadius="10px" padding="20px" marginLeft="auto" marginRight="auto" color="black">
                <FormHeader>
                    {`${name} Settings`}
                </FormHeader>
                <SettingsDiv>
                    <Popover popoverClassName="custom-popover">
                        <BlueBorderButton type="button" width="410px" rightIcon="caret-down">
                            {tempSource}
                        </BlueBorderButton>
                        <Menu>
                            {availableSources.map((s) => (
                                <MenuItem text={s} onClick={() => this.changeSource(s)} />
                            ))}
                        </Menu>
                    </Popover>
                    <div
                        style={{
                            width: '100%', height: '100%', marginTop: '10px', display: 'flex', alignItems: 'center'
                        }}
                    >
                        <InputGroup
                            leftIcon="key"
                            placeholder="API Key"
                            onChange={this.changeAPIKey}
                            value={tempAPIKey}
                            fill
                            large
                        />
                    </div>
                    <div
                        style={{
                            width: '100%', height: '100%', marginTop: '10px', display: 'flex', alignItems: 'center'
                        }}
                    >
                        <InputGroup
                            leftIcon="array"
                            placeholder="Entity Type"
                            onChange={this.changeEntityType}
                            value={tempEntityType}
                            fill
                            large
                        />
                    </div>
                    <div
                        style={{
                            width: '300px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', marginTop: '10px'
                        }}
                    >
                        <BlueBorderButton
                            id="cancel"
                            type="button"
                            onClick={this.closePopup}
                        >
                            Cancel
                        </BlueBorderButton>
                        <BlueButton
                            id="save"
                            type="button"
                            onClick={this.closeConfirmPopup}
                        >
                            Save
                        </BlueButton>
                    </div>
                </SettingsDiv>
            </PortalOverflowOverlay>,
            <Alert key="delete-alert" style={{background: 'white', color: 'black'}} usePortal cancelButtonText="Cancel" confirmButtonText="Delete" icon="trash" intent="danger" isOpen={deletePopupOpen} onCancel={this.closeDelete} onConfirm={this.delete}>
                <p>
                    Are you sure you want to delete the component
                    <b style={{marginLeft: '5px'}}>{name}</b>
                    ?
                </p>
            </Alert>
        ]);
    }
}

const createRobotImages = ({id, type, initialState, updateItem, deleteItem, cloneComponent, sources}) => (
    <RobotImages
        id={id}
        type={type}
        initialState={initialState}
        updateItem={updateItem}
        deleteItem={deleteItem}
        cloneComponent={cloneComponent}
        availableSources={sources}
    />
);

export default createRobotImages;
