import React from 'react';
import styled from 'styled-components';
import {Text} from '@blueprintjs/core';


const TextBoxStyle = styled(Text)`
    color: #16335B;
    font-weight: bold;
    padding: 5px 5px 5px 5px;    
    font-size: 16px;
    text-align: left;
`;

export const TextBox = ({
    children,
    ellipsize,
    tagName,
    title
}) => (
    <TextBoxStyle
        ellipsize={ellipsize}
        tagName={tagName}
        title={title}
    >
        {children}
    </TextBoxStyle>
);
